module.exports = {
  "generic.account": `Compte`,
  "generic.accounts": `Comptes`,
  "generic.accounts.filterBy": `Par compte`,
  "generic.accountsLocation.filterBy": `Par compte & emplacement`,
  "generic.action": `Action`,
  "generic.action.filterBy": `Par action`,
  "generic.action.CREATE": `Création du profil`,
  "generic.action.DISABLE": `Désactivation du profil`,
  "generic.action.UPDATE": `Mise à jour du profil`,

  "generic.actions": `Actions`,
  "generic.actions.filterBy": `Par actions`,
  "generic.actions.ADD_TO_INVENTORY": `Ajouter à l'inventaire...`,
  "generic.actions.ADD_TO_INVENTORY.disabled.reason": `Veuillez sélectionner uniquement des équipements ayant le statut "nouveau", "retourné" ou "défectueux".`,
  "generic.actions.ADD_TO_PROJECT": `Ajouté au projet`,
  "generic.actions.ALLOW": `Autorise`,
  "generic.actions.BACKUP": `Sauvegarde`,
  "generic.actions.BLOCK": `Bloque`,
  "generic.actions.CANCEL": `Annuler`,
  get "generic.actions.CLAIM"() {return this["generic.claim"]},
  "generic.actions.CLEAR": `Retirer`,
  "generic.actions.CLOSE": `Fermer`,
  "generic.actions.CLOSE.warningAlready": `Tous les items sont déjà fermés`,
  "generic.actions.DELETE": `Supprimer`,
  "generic.actions.DISABLE": `Désactiver`,
  "generic.actions.DISCARD": `Révoquer...`,
  "generic.actions.DISCARD.disabled.reason": `Veuillez sélectionner uniquement des équipements sans emplacement.`,
  "generic.actions.DISCARD_DEFINITIVELY": `Révoquer...`,
  "generic.actions.ENABLE": `Activer`,
  "generic.actions.ERASE": `Effacer`,
  "generic.actions.ERASE.desc": `Les disques sélectionnés vont être effacés, toutes les données seront perdues.{linebreak}Êtes-vous sûr de vouloir faire cette opération?`,
  "generic.actions.FACTORY_RESET": `Remise à l'état d'usine...`,
  get "generic.actions.FIRMWARE_UPDATE"() {return this["generic.type.FIRMWARE_UPDATE"]},
  "generic.actions.FORMAT": `Formatage`,
  "generic.actions.FORMAT.FULL": `Formatage complet`,
  "generic.actions.FORMAT.QUICK": `Formatage rapide`,
  "generic.actions.FORMAT_FOR_SERVICES": `Formater pour les services`,
  "generic.actions.FORMAT_FOR_SERVICES.desc": `Les disques sélectionnés vont être formatés pour les services.{linebreak}Êtes-vous sûr de vouloir faire cette opération?`,
  "generic.actions.IMMEDIATE_UPDATE": `Mise à jour immédiate`,
  "generic.actions.INIT_FOR_SERVICES": `Init. pour les services`,
  "generic.actions.INVENTORY_TRANSFER_TO": `Modifier l'emplacement...`,
  "generic.actions.INVENTORY_TRANSFER_TO.disabled.reason": `Veuillez sélectionner uniquement des équipements "En stock"`,
  "generic.actions.LOG": `Journalise`,
  "generic.actions.LOG_AND_ALLOW": `Journalise et autorise`,
  "generic.actions.LOG_AND_BLOCK": `Journalise et bloque`,
  "generic.actions.TRANSFER_TOinventory.disabled.reason": `Veuillez sélectionner uniquement des équipements "En stock"`,
  get "generic.actions.NEW"() {return this["generic.inventoryStatus.NEW"]},
  "generic.actions.OPEN": `Réouvrir`,
  "generic.actions.OPEN.warningAlready": `Tous les items sont déjà ouverts`,
  "generic.actions.PORT_SCAN_QUICK": `Scan des ports rapide`,
  "generic.actions.PORT_SCAN_FULL": `Scan des ports complet`,
  "generic.actions.REBOOT": `Redémarrer`,
  "generic.actions.REINIT": `Réinitialiser`,
  "generic.actions.REMOVE_FROM_PROJECT": `Retiré du projet`,
  "generic.actions.REMOVE_FROM_NETWORK": `Retiré de l'emplacement`,
  "generic.actions.RESCAN_PORTS": `Rescanner les ports`,
  "generic.actions.RESOLVE": `Résoudre`,
  "generic.actions.RESOLVE.warningAlready": `Tous les items sont déjà résolus`,
  "generic.actions.RETURN_TO_OPERATOR": `Retour au fournisseur...`,
  "generic.actions.SCHEDULE_UPDATE": `Planifier la mise à jour`,
  "generic.actions.START": `Démarrer`,
  "generic.actions.STOP": `Arrêter`,
  "generic.actions.SWITCH_ENVIRONMENT": `Changement de l'url d'API`,
  "generic.actions.SWITCH_ENVIRONMENT.disabled.reason": `Veuillez sélectionner uniquement des équipements "En stock"`,
  "generic.actions.TRANSFER_TO": `Transférer sur...`,
  "generic.actions.TRANSFER_TO.disabled.reason": `Veuillez sélectionner uniquement des équipements sans emplacement.`,
  "generic.actions.UNINSTALL": `Désinstaller`,
  "generic.actions.UNMOUNT": `Démonter`,
  "generic.actions.WHITELIST_ADD": `Marquer comme sûr`,
  "generic.actions.WHITELIST_REMOVE": `Marquer comme malveillant`,
  "generic.actions.WIFI_SURVEY": `Démarrer une surveillance Wi-Fi`,

  get "generic.actionsProfile.ACCESSES"() {return this["generic.action.UPDATE"]},
  get "generic.actionsProfile.CREATE"() {return this["generic.action.CREATE"]},
  get "generic.actionsProfile.DISABLE"() {return this["generic.action.DISABLE"]},
  get "generic.actionsProfile.INFO"() {return this["generic.action.UPDATE"]},
  get "generic.actionsProfile.INFO_AND_ACCESSES"() {return this["generic.action.UPDATE"]},
  "generic.activated": `Activé`,
  "generic.active": `Active`,
  "generic.inactive": `Inactive`,
  "generic.deactivated": `Désactivé`,
  "generic.activity": `Activité`,
  "generic.added": `Ajouté`,
  "generic.add": `Ajouter`,
  "generic.addDescription": `Ajouter une description`,
  "generic.all": `Tous`,
  "generic.all.fem": `Toutes`,
  "generic.apiEndpoint": `Adresse API`,
  "generic.appliance": `Équipement`,
  "generic.appliances": `Équipements`,
  "generic.appliances.count": `{count, plural, =0 {Aucun équipement} one {1 équipement} other {{count} équipements}}`,
  "generic.appliances.count.error": `{count, plural, =0 {Aucun équipement concerné} one {1 équipement concerné} other{{count} équipements concernés}}`,
  "generic.appliances.count.success": `{count, plural, =0 {Aucun équipement impliqué} one {S'applique à un équipement} other{S'applique à {count} équipements}}`,
  "generic.appliances.connected.count": `{count, plural, =0 {Aucun équipement connecté} one {1 équipement connecté} other {{count} équipements connectés}}`,
  "generic.appliances.offline": `Équipement(s) hors-ligne`,
  "generic.devices.connected.count": `{count, plural, =0 {Aucun appareil connecté} one {1 appareil connecté} other {{count} appareils connectés}}`,
  "generic.architecture": `Architecture`,
  "generic.archived": `Archivées`,
  "generic.areYouSure": `Êtes-vous sûr ?`,
  "generic.assignOn": `Assigné le`,
  "generic.assignTo": `Assigné à`,
  "generic.noAssignee": `Aucun assignement`,
  "generic.attention": `Attention`,
  "generic.authentication": `Authentification`,
  "generic.authentication.AUTHENTICATED": `Authentifié`,
  "generic.authentication.NOT_AUTHENTICATED": `Échec d'authentification`,
  "generic.authentication.NOT_REQUIRED": `Non requise`,
  get "generic.authentication.undefined"() {return this["generic.na"]},

  "generic.authentication.subtitle": `Indique si un appareil a été authentifié ou non avec des informations d'identification légitimes. Les équipements SentinelC doivent être authentifiés pour être pleinement fonctionnels.`,
  "generic.authorization": `Permission`,
  "generic.authorization.PENDING": `Permission en attente`,
  "generic.authorization.ENABLED": `Actif`,
  "generic.authorization.DISABLED": `Désactivé`,
  "generic.authorization.UPDATED": `Modifié`,
  "generic.authorization.NO_AUTH": `Aucune permission`,
  "generic.authorized": `{count, plural, =0 {0 autorisé} one {1 autorisé}  other {{count} autorisés}}`,
  "generic.authorized.simple": `Autorisé`,
  "generic.authorized.by": `Autorisé par`,
  "generic.auto": `Auto`,
  "generic.automaticAuthorization": `Autorisé automatiquement`,
  "generic.automaticAuthorization.notRequired": `Aucune permission requise`,
  "generic.automaticDeny": `Refusé automatiquement`,
  "generic.automaticIgnore": `Ignoré automatiquement`,
  "generic.available": `Disponible`,
  "generic.back": `Retour`,
  "generic.backHome": `Retour à l'accueil`,
  "generic.backOffice": `Tableau de bord administration`,
  "generic.backOffice.action": `Basculer vers le tableau de bord administration`,
  "generic.backOffice.home": `Tableau de bord administration`,
  "generic.badConnection": `Mauvaise connexion`,
  "generic.badConnection.FALSE": `Non`,
  "generic.badConnection.TRUE": `Oui`,
  "generic.badConnection.filterBy": `Mauvaise connexion`,
  "generic.badConnection.long": `Mauvaise connexion - Cette connexion n'est pas permise, veuillez vérifier la configuration de vos ports.`,
  "generic.block": `Bloquer`,
  "generic.blocked": `Bloqués`,
  "generic.blockedSingle": `Bloqué`,
  "generic.blockedSingle.FALSE": `Non`,
  "generic.blockedSingle.TRUE": `Oui`,
  "generic.blockedSingle.filterBy": `Bloqué`,
  "generic.blockedSince.date": `Bloqué depuis le {date}`,
  "generic.blockedSince.date.on": `Bloqué depuis le {date} sur {location}`,
  "generic.by": `par`,
  "generic.By": `Par`,
  "generic.By.name": `Par nom`,
  "generic.chart": `Graphique`,
  "generic.claim": `Ajouter`,
  "generic.claimAppliances": `Ajouter des équipements`,
  "generic.clients": `Clients`,
  "generic.clients.explanation": `Incluant les appareils clients, les équipements SentinelC et les services (app.).`,
  "generic.close": `Fermer`,
  "generic.closed": `Fermé`,
  "generic.completion": `Finalisation`,
  "generic.configuration": `Configuration`,
  "generic.configure": `Configurer`,
  "generic.configuring": `Configuration en cours`,
  "generic.connected": `Connecté`,
  "generic.connectedCount": `{count, plural, =0 {Aucun connecté} one {1 connecté}  other {{count} connectés}}`,
  "generic.connectedPlural": `Connectés`,
  "generic.connectedSince": `Connecté depuis le`,
  "generic.connectedTo": `Connecté à`,
  "generic.connection": `Connexion`,
  "generic.connection.ONLINE": `En ligne`,
  "generic.connection.OFFLINE": `Hors-ligne `,
  "generic.connections": `{count, plural, =0 {Connexion} one {Connexion} other {Connexions}}`,
  "generic.connection.none.withFilter": `Aucune connexion ne correspond aux critères sélectionnés.`,
  "generic.connectionType": `Type de connexion`,
  "generic.connectionType.WIFI": `Wi-Fi`,
  "generic.connectionType.ETHERNET": `Ethernet`,
  "generic.connectionType.VIRTUAL": `Virtuelle`,
  "generic.containers": `Conteneurs`,
  "generic.copied": `Copié dans le presse-papier`,
  "generic.createdOn.male": `Créé le`,
  "generic.createdOn.female": `Créée le`,
  "generic.createdBy.male": `Créé par`,
  "generic.current": `(actuel)`,
  "generic.custom": `Personnalisé`,
  "generic.customParameters": `Paramètres personnalisés`,
  "generic.dashboard": `Tableau de bord`,
  "generic.date": `Date`,
  "generic.date.today": `Aujourd'hui`,
  "generic.date.yesterday": `Hier`,
  "generic.day.mon": `Lundi`,
  "generic.day.tue": `Mardi`,
  "generic.day.wed": `Mercredi`,
  "generic.day.thu": `Jeudi`,
  "generic.day.fri": `Vendredi`,
  "generic.day.sat": `Samedi`,
  "generic.day.sun": `Dimanche`,
  "generic.day.fromBeginning": `Depuis toujours`,
  "generic.days": `{count, plural, =0 {0 jour} one {1 jour} other {{count} jours}}`,
  "generic.default": `Défaut`,
  "generic.defective": `Défectueux`,
  "generic.deleted": `Supprimé`,
  "generic.deletedWifi": `Wi-Fi supprimé`,
  "generic.denied": `{count, plural, =0 {Aucune refusé} one {1 refusé} other {{count} refusés}}`,
  "generic.denied.simple": `Refusé`,
  "generic.description": `Description`,
  "generic.details": `Détails`,
  "generic.detectedSince": `Détecté depuis le`,
  "generic.devices": `{count, plural, =0 {Aucun appareil} one {1 appareil} other {{count} appareils}}`,
  "generic.devicesState": `États équipements`,
  "generic.direction": `Direction`,
  "generic.direction.filterBy": `Par direction`,
  "generic.direction.INGRESS": `Entrant`,
  "generic.direction.EGRESS": `Sortant`,
  "generic.disabled": `Désactivé`,
  "generic.disabled.fem": `Désactivée`,
  "generic.disks.count": `{count, plural, =0 {Aucun disque} one {1 disque} other{{count} disques}}`,
  "generic.disks.count.error": `{count, plural, =0 {Aucun disque concerné} one {1 disque concerné} other{{count} disques concernés}}`,
  "generic.disks.count.success": `{count, plural, =0 {Aucun disque impliqué} one {S'applique à un disque} other{S'applique à {count} disques}}`,
  "generic.dnsRequest": `{count, plural, =0 {No requête DNS} one {1 requête DNS} other {{count} requêtes DNS}}`,
  "generic.dnsRequest.plural": `Requêtes DNS`,
  "generic.documentation": `Documentation`,
  "generic.down": `Inactif`,
  "generic.accounts.down": `Inactif`,
  "generic.accounts.down.explanation": `Tous les équipements du compte sont hors-ligne`,
  "generic.lines": `{count, plural, =0 {Aucun ligne} one {1 ligne} other {{count} lignes}}`,
  "generic.locations.down": `Inactif`,
  "generic.locations.down.explanation": `Tous les équipements de l'emplacement sont hors-ligne`,
  "generic.devices.down": `Hors-ligne`,
  "generic.duration": `Durée`,
  "generic.edit": `Éditer`,
  "generic.empty": `vide`,
  "generic.enabled": `Activé`,
  "generic.enabled.fem": `Activée`,
  "generic.endtime": `Heure de fin`,
  "generic.error": `Erreur`,
  "generic.errors": `{count, plural, =0 {aucune erreur} one {une erreur} other {{count} erreurs}}`,
  "generic.errors.occured": `{count, plural, =0 {Aucune erreur} one {Une erreur est survenue} other {{count} erreurs sont survenues}}`,
  "generic.events": `Évènements`,
  "generic.existingNetwork": `Réseau existant`,

  "generic.feedback": `Envoyez-nous vos commentaires`,
  "generic.file.name": `Nom du fichier`,
  "generic.file.size": `Taille du fichier`,
  "generic.filters": `Filtre(s)`,
  "generic.firmware": `Micrologiciel`,
  "generic.firmwareInstalled": `Micrologiciel installé`,
  "generic.firmwareStatus": `Statut du micrologiciel`,
  "generic.firmwareUpdateSchedule": `Horaire de mise à jour micrologiciel des équipements`,
  "generic.firmwareUpdateType": `Planification des mises à jour micrologiciel`,
  "generic.firmwareUpdateTime": `Heure planifiée des mises à jour micrologiciel`,
  "generic.firmwareRelease": `Flux de version`,
  "generic.firmwareRelease.filterBy": `Par flux de version`,
  "generic.firstseen": `Vu la première fois`,
  "generic.hardware": `Matériel`,
  "generic.hostTypes": `Type d'appareils`,
  "generic.hostTypes.filterBy": `Par type`,
  "generic.hostTypes.DEVICE": `Équipements SentinelC`,
  "generic.hostTypes.DEFAULT": `Appareils clients`,
  "generic.hostTypes.POD": `Services SentinelC`,
  "generic.lastseen": `Vu la dernière fois`,
  "generic.functionnal": `Fonctionnel`,
  "generic.filterBy": `Filtrer par`,
  "generic.flags": `Types`,
  "generic.authentication.filterBy": `Par authentification`,
  "generic.authorization.filterBy": `Par autorisation`,
  "generic.category.filterBy": `Par catégorie`,
  "generic.connection.filterBy": `Par connexion`,
  "generic.connectionType.filterBy": `Par type de connexion`,
  "generic.firmware.filterBy": `Par micrologiciel`,
  "generic.firmwareStatus.filterBy": `Par statut du micrologiciel`,
  "generic.incident.filterBy": `Par type d'incident`,
  "generic.instant": `Instantané`,
  "generic.operatingMode.filterBy": `Par mode`,
  "generic.period.filterBy": `Par période`,
  "generic.potentialRogue.filterBy": `Potentiel Wi-Fi malveillant détecté`,
  "generic.priority.filterBy": `Par priorité d'incident`,
  "generic.state.filterBy": `Par état`,
  "generic.status.filterBy": `Par statut`,
  "generic.permission.filterBy": `Par permission`,
  "generic.level.filterBy": `Par niveau`,
  "generic.wifi.filterBy": `Par Wi-Fi`,
  "generic.zone.filterBy": `Par zone`,
  "generic.zoneType.filterBy": `Par type de zone`,
  "generic.type.filterBy": `Par type`,
  "generic.period": `Période`,
  "generic.period.startTime.filterBy": `Période (heure planifiée)`,
  "generic.potentialRogue": `Wi-Fi malveillant potentiel`,
  get "generic.potentialRogue.FALSE"() {return this["generic.no"]},
  get "generic.potentialRogue.TRUE"() {return this["generic.yes"]},
  "generic.categories": `Catégories`,
  "generic.category": `Catégorie`,
  "generic.categoryType": `Catégorie/Type`,
  "generic.hostPorts.count.error": `{count, plural, =0 {Aucun port concerné} one {1 port concerné} other{{count} ports concernés}}`,
  "generic.hostPorts.count.success": `{count, plural, =0 {Aucun port impliqué} one {S'applique à un port} other{S'applique à {count} ports}}`,
  "generic.incident": `Type d'incident`,
  "generic.incidents": `{count, plural, =0 {Aucun incident} one {incident} other{incidents}}`,
  "generic.incidents.count": `{count, plural, =0 {Aucun incident} one {1 incident} other{{count} incidents}}`,
  "generic.incidents.count.error": `{count, plural, =0 {Aucun incident concerné} one {1 incident concerné} other{{count} incidents concernés}}`,
  "generic.incidents.count.success": `{count, plural, =0 {Aucun incident impliqué} one {S'applique à un incident} other{S'applique à {count} incidents}}`,
  "generic.incidents.byState": `Incidents par statut`,
  "generic.incidents.byCategory": `Incidents par catégorie`,
  "generic.incidents.viewAll": `Voir tous les incidents`,
  "generic.incidents.viewAll.open": `Voir tous les incidents ouverts`,
  "generic.incidents.viewAll.openCount": `{count, plural, =0 {Aucun incident} one {Voir l'incident ouvert} other{Voir les {count} incidents ouverts}}`,
  "generic.incidents.none": `Aucun incident`,
  "generic.incidents.none.withFilter": `Aucun incident ne correspond aux critères sélectionnés.`,
  "generic.status": `Statut`,
  "generic.statusDate": `Date du statut`,
  "generic.permission": `Permission`,
  "generic.permissions": `Permissions sur les zones/vlans`,
  "generic.permission.subtitle": `Indique si l'addresse mac de l'appareil est autorisée dans cette zone. Applicable seulement si la zone est restreinte.`,
  "generic.permission.subtitle.connections": `Statut d'un appareil au moment de la connextion indiquant s'il est autorisé ou non à se connecter au réseau dans lequel il souhaite se connecter.`,
  "generic.category.BOT": `Bot`,
  "generic.category.MALWARE": `Malware`,
  "generic.category.THREAT": `Menace`,
  "generic.category.NETWORK": `Réseau`,
  "generic.category.FIRMWARE": `Micrologiciel`,
  "generic.category.SERVICE": `Service`,
  "generic.category.OPERATION": `Opération`,
  "generic.category.SURVEILLANCE": `Surveillance`,
  "generic.incident.BAD_CONNECTION": `Mauvaise connexion`,
  "generic.incident.MAX_AUTH_FAILURE": `Échec d'authentification`,
  "generic.incident.ROGUE_SSID": `Réseau malveillant`,
  "generic.incident.POD_FAILURE": `Échec du service`,
  "generic.incident.FAILED_UPDATE": `Échec de mise à jour`,
  "generic.incident.DEVICE_OFFLINE": `Équipement hors-ligne`,
  "generic.incident.DEVICE_VPN_OFFLINE": `VPN hors-ligne`,
  "generic.incident.reportedon": `Rapporté le {date}`,
  "generic.incident.resolvedon": `Résolu le {date}`,
  "generic.incident.firstseenon": `Première occurence le {date}`,
  "generic.state.ACTIVE": `Actif`,
  "generic.state.ALLOW": `Autorisé`,
  "generic.state.AVAILABLE": `Disponible`,
  "generic.state.BLOCKED": `Bloqué`,
  "generic.state.BAD_CONNECTION": `Mauvaise connexion`,
  "generic.state.CANCELLED": `Annulé`,
  "generic.state.CLEAR_DISK": `Retrait du disque`,
  "generic.state.CLEAR_PENDING": `Retrait en attente`,
  "generic.state.CLEAR_IN_PROGRESS": `Retrait en cours`,
  "generic.state.CLOSED": `Fermé`,
  "generic.state.COMPLETED": `Terminé`,
  "generic.state.CRITICAL": `Critique`,
  "generic.state.CRITICAL.plural": `Critique(s)`,
  "generic.state.DEACTIVATED": `Désactivé`,
  "generic.state.DELETING": `Suppression`,
  "generic.state.DENY": `Refusé`,
  "generic.state.DEPRECATED": `Obsolète`,
  "generic.state.DISABLED": `Désactivé`,
  "generic.state.DUPLICATE": `Doublon`,
  "generic.state.ENABLED": `Activé`,
  "generic.state.ERASE_PENDING": `Effacement en attente`,
  "generic.state.ERASE_IN_PROGRESS": `Effacement en cours`,
  "generic.state.ERROR": `Erreur`,
  "generic.state.FAILED": `Échec`,
  "generic.state.FORMAT_PENDING": `Formatage en attente`,
  "generic.state.FORMAT_IN_PROGRESS": `Formatage en cours`,
  "generic.state.GOOD": `Bon`,
  "generic.state.HIGH": `Élevé`,
  "generic.state.HIGH.plural": `Élevé(s)`,
  "generic.state.IGNORE": `Ignoré`,
  "generic.state.INACTIVE": `Inactif`,
  "generic.state.INSTALL_FAILED": `Échec de l'installation`,
  "generic.state.INSTALLING": `Installation`,
  "generic.state.INSTALLED": `Installé`,
  "generic.state.MISSING": `Indisponible`,
  "generic.state.MODERATE": `Modéré`,
  "generic.state.MODERATE.plural": `Modéré(s)`,
  "generic.state.NONE": `Inconnu`,
  "generic.state.NOT_AVAILABLE": `Non disponible`,
  "generic.state.OPEN": `Ouvert`,
  "generic.state.PENDING": `En attente`,
  "generic.state.POD_FAILED": `Échec du service`,
  "generic.state.POD_DEGRADED": `Service dégradé`,
  "generic.state.PROMPT": `En attente de permission`,
  "generic.state.READY": `Prêt`,
  "generic.state.RESOLVED": `Résolu`,
  "generic.state.RUNNING": `En cours`,
  "generic.state.STANDBY": `En attente`,
  "generic.state.STARTED": `En cours`,
  "generic.state.STARTING": `En cours de démarrage`,
  "generic.state.STOPPED": `Stoppé`,
  "generic.state.STOPPING": `En cours d'arrêt`,
  "generic.state.TASK_FAILED": `Échec de la tâche`,
  get "generic.state.UNKNOWN"() {return this["generic.unknown"]},
  "generic.state.UNFORMATTED": `Non formaté`,
  "generic.state.UNINSTALL_FAILED": `Échec désinstallation`,
  "generic.state.UPDATING_PARAMETERS": `Mise à jour des paramètres`,

  "generic.permission.ALLOW": `Autorisé`,
  "generic.permission.BLOCKED": `Bloqué`,
  "generic.permission.DENY": `Refusé`,
  "generic.permission.IGNORE": `Ignoré`,
  "generic.permission.PROMPT": `En attente de permission`,

  get "generic.status.null"() {return this["generic.unknown"]},
  "generic.status.ACTIVE": `Actif`,
  "generic.status.ALLOW": `Autorisé`,
  "generic.status.ALLOWED": `Autorisé`,
  "generic.status.AVAILABLE": `Disponible`,
  "generic.status.BLOCKED": `Bloqué`,
  "generic.status.BAD_CONNECTION": `Mauvaise connexion`,
  "generic.status.CANCELLED": `Annulé`,
  "generic.status.CLEAR_DISK": `Retrait du disque`,
  "generic.status.CLEAR_PENDING": `Retrait en attente`,
  "generic.status.CLEAR_IN_PROGRESS": `Retrait en cours`,
  "generic.status.CLOSED": `Fermé`,
  "generic.status.COMPLETED": `Terminé`,
  "generic.status.CRITICAL": `Critique`,
  "generic.status.DEACTIVATED": `Désactivé`,
  "generic.status.DELETING": `Suppression`,
  "generic.status.DENY": `Refusé`,
  "generic.status.DEPRECATED": `Obsolète`,
  "generic.status.DISABLED": `Désactivé`,
  "generic.status.DUPLICATE": `Doublon`,
  "generic.status.ENABLED": `Activé`,
  "generic.status.ERASED": `Effacé`,
  "generic.status.ERASED.desc": `Le disque a été effacé complètement avec des zéros.`,
  "generic.status.ERASE_PENDING": `Effacement en attente`,
  "generic.status.ERASE_IN_PROGRESS": `Effacement en cours`,
  "generic.status.ERROR": `Erreur`,
  "generic.status.FAILED": `Échec`,
  "generic.status.FORMAT_PENDING": `Formatage en attente`,
  "generic.status.FORMAT_IN_PROGRESS": `Formatage en cours`,
  "generic.status.GOOD": `Bon`,
  "generic.status.HIGH": `Élevé`,
  "generic.status.IGNORE": `Ignoré`,
  "generic.status.IN_USE": `Utilisé`,
  "generic.status.INACTIVE": `Inactif`,
  "generic.status.INSTALL_FAILED": `Échec de l'installation`,
  "generic.status.INSTALLING": `Installation`,
  "generic.status.MISSING": `Indisponible`,
  "generic.status.MODERATE": `Modéré`,
  "generic.status.MODIFICATION_PENDING": `Modification en attente`,
  "generic.status.MODIFICATION_IN_PROGRESS": `Modification en cours`,
  "generic.status.NOT_AVAILABLE": `Non disponible`,
  "generic.status.NOT_IN_USE": `Pas en utilisation`,
  "generic.status.OPEN": `Ouvert`,
  "generic.status.PENDING": `En attente`,
  "generic.status.POD_FAILED": `Échec du service`,
  "generic.status.POD_DEGRADED": `Service dégradé`,
  "generic.status.PROMPT": `En attente de permission`,
  "generic.status.READY": `Prêt`,
  "generic.status.RESOLVED": `Résolu`,
  "generic.status.RUNNING": `En cours`,
  "generic.status.STANDBY": `En attente`,
  "generic.status.STARTED": `En cours`,
  "generic.status.STARTING": `En cours`,
  "generic.status.STOPPED": `Stoppé`,
  "generic.status.STOPPING": `En cours d'arrêt`,
  "generic.status.SUSPICIOUS": `Suspicieux`,
  "generic.status.TASK_FAILED": `Échec de la tâche`,
  "generic.status.UNFORMATTED": `Non formaté`,
  "generic.status.UNINSTALL_FAILED": `Échec désinstallation`,
  "generic.status.UNINSTALLED": `Désinstallé`,
  "generic.status.UNKNOWN": `Inconnu`,
  "generic.status.UPDATING_PARAMETERS": `Mise à jour des paramètres`,
  "generic.status.UPDATING_PARAMETERS.long": `Une tâche est en cours pour la mise à jour des paramètres...`,
  
  "generic.status.undefined": `Non défini`,

  "generic.usage": `Usage`,
  "generic.usage.filterBy": `Par usage`,
  "generic.usage.OS_BOOT": `OS (boot)`,
  "generic.usage.OS_ACTIVE": `OS (firmware actif)`,
  "generic.usage.OS_PASSIVE": `OS (firmware passif)`,
  "generic.usage.OS_DATA": `OS (data)`,
  "generic.usage.OS_DATA_AND_CONTAINER_STORAGE": `OS (data) et Services`,
  "generic.usage.CONTAINER_STORAGE": `Services`,
  "generic.usage.NONE": `Aucun`,
  "generic.usage.STORAGE": `Stockage`,

  "generic.inventoryStatus": `Statut`,
  "generic.inventoryStatus.filterBy": `Par statut`,
  "generic.inventoryStatus.IN_USE": `Utilisé`,
  "generic.inventoryStatus.NOT_IN_USE": `Pas en utilisation`,
  "generic.inventoryStatus.UNAVAILABLE": `Indisponible`,
  "generic.inventoryStatus.IN_STOCK": `En stock`,
  "generic.inventoryStatus.DISCARDED": `Révoqué`,
  "generic.inventoryStatus.DEFECTIVE": `Défectueux`,
  "generic.inventoryStatus.DEFECTIVE.explanation": `Cet équipement a été identifié défectueux par un client. Vérifiez-le et testez-le. S'il fonctionne correctement vous pouvez l'ajouter à l'inventaire, autrement vous pouvez le supprimer définitivement de l'inventaire.`,
  "generic.inventoryStatus.RETURNED": `Retourné`,
  "generic.inventoryStatus.RETURNED.explanation": `Cet équipement a été retourné par un client. Vérifiez-le et testez-le. S'il fonctionne correctement vous pouvez l'ajouter à l'inventaire, autrement vous pouvez le supprimer définitivement de l'inventaire.`,
  "generic.inventoryStatus.NEW": `Nouveau`,
  "generic.inventoryStatus.NEW.explanation": `Cet équipement est inconnu de l'inventaire. Vérifiez le si nécessaire puis vous pourrez l'ajouter à l'inventaire.`,
  "generic.inventoryStatus.notValid.explanation": `Cet équipement n'a pas complété sa requête d'enregistrement. Il pourrait avoir perdu la connexion ou s'être arrêté pour diverses raisons.{linebreak}Vérifiez si nécessaire et vous pourrez ensuite ajouter cet équipement à l'inventaire. La prochaine fois qu'il essayera de se connecter, il complètera la requête d'enregistrement.`,

  "generic.kernelVersion": `Version du kernel`,

  "generic.level.CRITICAL": `Critique`,
  "generic.level.HIGH": `Élevé`,
  "generic.level.MODERATE": `Modéré`,
  "generic.level.LOW": `Bas`,
  "generic.level.OK": `Bas`,
  "generic.level.WARNING": `Élevé`,
  "generic.level.NA": `Non applicable`,
  get "generic.level.UNKNOWN"() {return this["generic.unknown.masc"]},
  "generic.operatingMode.ROUTER": `Routeur`,
  "generic.operatingMode.DRONE": `Surveillance`,
  "generic.operatingMode.DRONE.long": `Équipement de surveillance`,
  "generic.operatingMode.BRIDGE": `Point d'accès`,
  "generic.firmwareStatus.UP_TO_DATE": `À jour`,
  "generic.firmwareStatus.SCHEDULED": `Planifié`,
  "generic.firmwareStatus.DEPRECATED": `Obsolète`,
  "generic.firmwareStatus.PENDING": `En attente`,
  "generic.firmwareStatus.UNAVAILABLE": `Indisponible`,
  "generic.firmwareStatus.AVAILABLE": `Disponible`,
  "generic.firmwareStatus.STARTED": `Démarré`,
  "generic.firmwareStatus.DOWNLOADING": `Téléchargement`,
  "generic.firmwareStatus.REBOOTING": `Redémarrage`,
  "generic.hostIsUp": `Statut de l'appareil`,
  "generic.hostIsUp.filterBy": `Par statut de l'appareil`,
  "generic.hostIsUp.YES": `Répond`,
  "generic.hostIsUp.NO": `Ne répond pas`,
  "generic.hasResult": `Résultats`,
  "generic.hasResult.filterBy": `A des résultats`,
  "generic.hasResult.YES": `Oui`,
  "generic.hasResult.NO": `Non`,
  "generic.pending": `En attente`,
  "generic.pending.long": `Mise à jour en attente`,
  "generic.started.long": `Mise à jour en cours`,
  "generic.priority": `Priorité`,
  "generic.priority.CRITICAL": `Critique`,
  "generic.priority.HIGH": `Élevée`,
  "generic.priority.MODERATE": `Modérée`,
  "generic.priority.NONE": `Aucune`,
  "generic.priority.CRITICAL.masc": `Critique`,
  "generic.priority.HIGH.masc": `Élevé`,
  "generic.priority.MODERATE.masc": `Modéré`,
  "generic.priority.NONE.masc": `Aucun`,

  "generic.type.CLEAR_DISK": `Retrait du disque`,
  "generic.type.DISK": `Disque`,
  "generic.type.PACKET_CAPTURE": `Capture de paquets`,
  "generic.type.DELETE_POD_VOLUME": `Suppression du volume`,
  "generic.type.FACTORY_RESET": `Remise à zéro`,
  "generic.type.PORT_SCAN": `Scan de ports`,
  "generic.type.WIFI_SURVEY": `Scan Wi-Fi`,
  "generic.type.PARTITION": `Partition`,
  "generic.type.REBOOT": `Redémarrage`,
  "generic.type.SWITCH_API_ENDPOINT": `Changement d'adresse d'API`,
  "generic.type.SWITCH_ENVIRONMENT": `Changement d'adresse d'API`,
  "generic.type.SLEEP": `Veille`,
  "generic.type.FIRMWARE_UPDATE": `Mise à jour du micrologiciel`,
  "generic.type.CREATE_POD": `Installation du service`,
  "generic.type.REMOVE_POD": `Suppression du service`,
  "generic.type.START_POD": `Démarrage du service`,
  "generic.type.STOP_POD": `Arrêt du service`,
  "generic.type.FORMAT_DISK": `Configuration du disque`,
  "generic.type.STRESS_WIFI_AUTH": `Test d'auth. Wi-Fi`,
  "generic.type.UPDATE_POD_PARAMETERS": `Mise à jour des paramètres du service`,

  "generic.usersadmin.none": `Aucun administrateur.`,
  "generic.usersadmin.none.withFilter": `Aucun administrateur ne correspond aux critères sélectionnés.`,
  "generic.usershistory.none.withFilter": `Aucune entrée d'historique ne correspond aux critères sélectionnés.`,
  "generic.downloading": `Téléchargement`,
  "generic.downloadFile": `Téléchargement du fichier`,
  "generic.downloadedOnBy": `Téléchargé le {date} par {name}`,
  "generic.frequency.filterBy": `Par fréquence`,
  "generic.frequency": `Fréquence`,
  "generic.frequency.BAND_5GHZ": `5 GHZ`,
  "generic.frequency.BAND_24GHZ": `2.4 GHz`,
  "generic.fullName": `Nom complet`,
  "generic.fullScreen": `Plein écran`,
  "generic.galleryPicture": `Galerie de photos`,
  "generic.galleryPicture.edit": `Éditer la galerie`,
  "generic.galleryPicture.count": `{count, plural, =0 {Aucune photo} one {Une photo} other {{count} photos}}`,
  "generic.galleryPicture.add.count": `{count, plural, =0 {Aucune photo ajoutée} one {Une photo ajoutée} other {{count} photos ajoutées}}`,
  "generic.galleryPicture.add.badFormat": `Ce format n'est pas supporté`,
  "generic.galleryPicture.delete.count": `{count, plural, =0 {Aucune photo ne sera supprimée} one {Une photo sera supprimée} other {{count} photos seront supprimées}}`,
  "generic.galleryPicture.bulk.message.update.all": `Les photos ont été modifiées/supprimées avec succès.`,
  "generic.galleryPicture.bulk.message.update.singleDeletion": `La photo a été supprimée avec succès.`,
  "generic.galleryPicture.upload": `Téléverser des photos`,
  "generic.galleryPicture.tags.devices": `Équipements visibles`,
  "generic.galleryPicture.tags.devicesremoved": `Cet équipement n'est plus dans l'emplacement`,
  "generic.galleryPicture.tags.hosts": `Appareils visibles`,
  "generic.goto.admin": `Voir dans l'administration`,
  "generic.goto.admin.connections": `Voir toutes les connexions`,
  "generic.goto.bottom": `Aller en bas`,
  "generic.goto.dashboard": `Voir dans l'application`,
  "generic.goto.documentation": `Voir la documentation`,
  "generic.goto.top": `Revenir en haut`,
  "generic.history": `Historique`,
  "generic.homepage": `Accueil`,
  "generic.host": `Appareil`,
  "generic.host.target": `Appareil cible`,
  "generic.hosts": `Appareils`,
  "generic.hosts.authorized": `Appareils autorisés`,
  "generic.hosts.count": `{count, plural, =0 {Aucun appareil} one {1 appareil} other {{count} appareils}}`,
  "generic.hosts.count.error": `{count, plural, =0 {Aucun appareil concerné} one {1 appareil concerné} other{{count} appareils concernés}}`,
  "generic.hosts.count.success": `{count, plural, =0 {Aucun appareil impliqué} one {S'applique à un appareil} other{S'applique à {count} appareils}}`,
  "generic.id": `ID`,
  "generic.ignored": `{count, plural, =0 {Ignoré} one {Ignoré} other {Ignorés}}`,
  "generic.ignoredCount": `{count, plural, =0 {0 ignoré} one {1 ignoré} other {{count} ignorés}}`,
  "generic.in": `dans`,
  "generic.incidentHistory": `Historique d'incidents`,
  "generic.incidentHistory.info": `L'incident le plus récent apparait toujours en premier.`,
  "generic.incidentId": `ID incident`,
  "generic.inPort": `Entrée`,
  "generic.information": `Information`,
  "generic.informations": `Informations`,
  "generic.installations": `{count, plural, =0 {Aucune installation} one {1 installation} other {{count} installations}}`,
  "generic.installedNb": `Nb. installations`,
  "generic.installedOn": `Installé le`,
  "generic.invalid": `Invalide`,
  "generic.isWhitelisted.filterBy": `Marqué comme sûr`,
  "generic.isWhitelisted": `Liste blanche`,
  "generic.isWhitelisted.WHITELISTED": `Oui`,
  "generic.isWhitelisted.NOT_WHITELISTED": `Non`,
  "generic.lastConnection": `Dernière connexion`,
  "generic.lastIncident": `Dernier incident`,
  "generic.lastDataUpdate": `Dernière mise à jour des données`,
  "generic.lastUpdate": `Dernière mise à jour`,
  "generic.latestUpdate": `Dernière mise à jour`,
  "generic.latestVersion": `Dernière version`,
  "generic.legend": `Légende`,
  "generic.loading": `Chargement`,
  "generic.localTime": `Temps local`,
  "generic.logs": `Journaux de bord`,
  "generic.logout": `Déconnexion`,
  "generic.manage": `Gérer`,
  "generic.management": `Gestion`,
  "generic.matchTypes.filterBy": `Par règle`,
  "generic.matchTypes": `Règles de correspondance`,
  "generic.matchTypes.EXACT":`Exacte`,
  "generic.matchTypes.CONTAINS":`Contient`,
  "generic.matchTypes.explanation.CONTAINS":`Contient <strong>{ssid}</strong>`,
  "generic.matchTypes.REGEXP":`Expression régulière`,
  "generic.matchTypes.explanation.REGEXP":`Déclenche l'expression régulière {value}`,
  "generic.matchTypes.PROXIMITY":`Proximité`,
  "generic.matchTypes.explanation.PROXIMITY":`Ressemblance avec votre Wi-Fi <strong>{ssid}</strong>`,

  "generic.maxCaracters": `{count} caractères maximum.`,
  "generic.maxLimitReached": `Échecs excessifs`,
  "generic.modifiedOn": `Modifié le`,
  "generic.monitoring": `Surveillance`,
  "generic.moreDetails": `Autres actions`,
  "generic.name": `Nom`,
  "generic.neverConnected": `Jamais connecté`,
  "generic.notes": `{count, plural, =0 {Note} one {Note} other {Notes}}`,
  "generic.notes.count": `{count, plural, =0 {Aucune note} one {une note} other {{count} notes}}`,
  "generic.notRespondingState": `Ne répond pas`,
  "generic.wifiNetworks": `{count, plural, =0 {Réseau Wi-Fi} one {Réseau Wi-Fi} other {Réseaux Wi-Fi}}`,
  "generic.na": `N/A`,
  "generic.nbTimes": `# vues`,
  "generic.networkRessource": `Ressource réseau`,
  "generic.nextOccurenceOn": `Prochaine occurence le`,
  "generic.no": `Non`,
  "generic.noModificationToApply": `Aucun modification à application`,
  "generic.none": `Aucun`,
  "generic.notAssigned": `Non assigné`,
  "generic.notConfigured": `Non configuré`,
  "generic.notConfigured.plural": `{count, plural, =0 {Aucun non configuré} one {1 non configuré} other {{count} non configurés}}`,
  "generic.notSupported": `Non supporté`,
  "generic.noneFem": `Aucune`,
  "generic.obsolete": `Obsolète`,
  "generic.offline": `Hors-ligne`,
  "generic.offlineSince": `Hors-ligne depuis le`,
  "generic.on": `le {date}`,
  "generic.onATotalOf": `sur un total de {count}`,
  "generic.online": `En ligne`,
  "generic.onlineSince": `En ligne depuis le`,
  "generic.onlineBySchedule": `En ligne via horaires`,
  "generic.open": `Ouvert`,
  "generic.operatingMode": `Mode d'opération`,
  "generic.or": `ou`,
  "generic.organization": `{count, plural, =0 {Aucun compte SentinelC} one {Mon compte SentinelC}  other {Mes comptes SentinelC}}`,
  "generic.other": `Autre`,
  "generic.others": `Autres`,
  "generic.overview": `Aperçu`,
  "generic.backToOrganizations": `Retour aux comptes SentinelC`,
  "generic.backToAppliances": `Retour aux équipements`,
  "generic.packets": `{count, plural, =0 {0 paquet} one {1 paquet} other {{count} paquets}}`,
  "generic.pages.submenu": `Sous-menu`,
  "generic.pages.subpages": `Sous-pages`,
  "generic.pagination.XOfY": `{x} - {y} sur {z} résultats`,
  "generic.partial": `Partiel`,
  "generic.accounts.partial": `Partiel`,
  "generic.accounts.partial.explanation": `Plusieurs équipements du compte sont hors-ligne`,
  "generic.locations.partial": `Partiel`,
  "generic.locations.partial.explanation": `Plusieurs équipements de l'emplacement sont hors-ligne`,
  "generic.partials": `Partiels`,
  "generic.pause": `Pause`,
  "generic.port": `Port`,
  "generic.ports": `Ports`,
  "generic.ports.count": `{count, plural, =0 {0 port} one {1 port} other {{count} ports}}`,
  "generic.portState": `État port`,
  "generic.preparingUpdate": `Préparation de la mise à jour`,
  "generic.product": `Produit`,
  "generic.project": `Projet`,
  "generic.projects": `Projets`,
  "generic.projects.count": `{count, plural, =0 {0 projet} one {1 projet} other {{count} projets}}`,
  "generic.projects.count.error": `{count, plural, =0 {Aucun projet concerné} one {1 projet concerné} other{{count} projets concernés}}`,
  "generic.projects.count.success": `{count, plural, =0 {Aucun projet impliqué} one {S'applique à une projet} other{S'applique à {count} projets}}`,
  "generic.projects.none.withFilter": `Aucun projet ne correspond aux critères sélectionnés.`,
  "generic.prompt": `{count, plural, =0 {0 en attente} one {1 en attente} other {{count} en attente}}`,
  "generic.prompt.simple": `En attente`,
  "generic.properties": `Propriétés`,
  "generic.protocol": `Protocole`,
  "generic.protocol.filterBy": `Par protocole`,
  "generic.protocol.UDP": `UDP`,
  "generic.protocol.TCP": `TCP`,
  "generic.scanTypes": `Type de scan`,
  "generic.scanTypes.filterBy": `Par type de scan`,
  "generic.scanTypes.UDP": `Ports UDP`,
  "generic.scanTypes.TCP": `Ports TCP`,
  "generic.scanTypes.OS_DETECTION": `Détection de l'OS`,
  "generic.publishDate": `Date de publication`,
  "generic.reaction": `Réaction`,
  "generic.reaction.filterBy": `Par réaction`,
  "generic.reaction.ALLOW": `Autorisation`,
  "generic.reaction.BLOCK": `Blocage`,
  "generic.reaction.LOG_AND_ALLOW": `Journalisation et autorisation`,
  "generic.reaction.LOG_AND_BLOCK": `Journalisation et blocage`,
  "generic.reaction.LOG": `Journalisation`,
  "generic.reaction.NONE": `Aucune`,
  "generic.reason": `Raison`,
  "generic.rebooting": `Redémarrage`,
  "generic.recurrence": `Récurrence`,
  "generic.recent": `Récentes`,
  "generic.recordedTime": `Temps de l'enregistrement`,
  "generic.refreshNow": `Rafraîchir maintenant`,
  "generic.refreshNow.requestSent": `Votre requête a été envoyée. Les données seront rafraîchies sous peu.`,
  "generic.report": `Rapport`,
  "generic.reserved": `Réservé`,
  "generic.restricted": `Restreint`,
  "generic.results": `{count, plural, =0 {Aucun résultat} =1 {1 résultat} other {{count} résultats}}`,
  "generic.resultsOn": `sur {count}`,
  "generic.revoke.PENDING": `Retrait en attente`,
  "generic.rogueswifi": `Wi-Fi malveillant`,
  "generic.rogueSsids.none.withFilter": `Aucun Wi-Fi potentiellement malveillant ne correspond aux critères sélectionnés.`,
  "generic.routers": `{count, plural, =0 {Aucun routeur} =1 {Routeur} other {Routeurs}}`,
  "generic.radio": `Radio`,
  "generic.responding": `Répond`,
  "generic.select.appliance": `Sélectionnez un équipement`,
  "generic.selected.XOfY": `{x} sur {y} sélectionné(s)`,
  "generic.started": `Démarré`,
  "generic.statistics": `Statistiques`,
  "generic.statistics.none": `Pas encore de statistique`,
  "generic.scans": `Scans`,
  "generic.schedule": `Horaires`,
  "generic.scheduled": `Planifié`,
  "generic.scheduled.long": `Mise à jour planifiée`,
  "generic.scheduledOn": `Planifié le`,
  "generic.scheduledOn.fem": `Planifiée le`,
  "generic.seconds": `{seconds, plural, =0 {0 seconde} one {1 seconde} other {{seconds} secondes}}`,
  "generic.security": `Sécurité`,
  "generic.security.filterBy": `Critère de sécurité`,
  "generic.security.HAS_OPENPORTS": `A des ports ouverts`,
  "generic.security.HAS_IPEVENTS": `A des événements IP`,
  "generic.sendMeAnEmail": `M'envoyer un courriel`,
  "generic.service": `Service`,
  "generic.services": `Services`,
  "generic.services.actions": `Comprendre les actions sur les services`,
  "generic.services.containers": `Comprendre les conteneurs`,
  "generic.services.volumes": `Comprendre les volumes`,
  "generic.services.count": `{count, plural, =0 {Aucun service} =1 {1 service} other {{count} services}}`,
  "generic.services.count.error": `{count, plural, =0 {Aucun service concerné} one {1 service concerné} other{{count} services concernés}}`,
  "generic.services.count.success": `{count, plural, =0 {Aucun service impliqué} one {S'applique à un service} other{S'applique à {count} services}}`,
  "generic.services.project": `Services (pour ce projet seulement)`,
  "generic.settings": `Paramètres`,
  "generic.settings.advanced": `Paramètres avancés`,
  "generic.settings.advancedMode": `Mode simplifié`,
  "generic.settings.homeLanding": `Accueil administration`,
  "generic.settings.darkMode": `Mode sombre`,
  "generic.settings.user.profile": `Éditer le profil`,
  "generic.settings.user.password": `Changer le mot de passe`,
  "generic.see": `Voir`,
  "generic.seeDetails": `Voir les détails`,
  "generic.seeLess": `Voir moins`,
  "generic.seeMore": `Voir plus`,
  "generic.services.none.withFilter": `Aucun service ne correspond aux critères sélectionnés.`,
  "generic.since": `depuis le`,  
  "generic.size": `Taille`,
  "generic.starttime": `Heure de début`,
  "generic.state": `État`,
  "generic.stop": `Stop`,
  "generic.stoppedState": `Stoppé`,
  "generic.stoppedBySchedule": `Stoppé via horaires`,
  "generic.stopped": `{count, plural, =0 {Aucune stoppé} one {1 stoppé} other {{count} stoppés}}`,
  "generic.timestamp": `Date`,
  "generic.thisMonth": `Ce mois-ci`,
  "generic.thisWeek": `7 derniers jours`,
  "generic.today": `Aujourd'hui`,
  "generic.summary": `Résumé`,
  "generic.supportedArchi": `Architectures supportées`,
  "generic.wifiSummary": `Résumé Wi-Fi`,
  "generic.synchronized.not": `Non synchronisé`,
  "generic.system": `Système`,
  "generic.taskId": `ID tâche`,
  "generic.task": `Tâche`,
  "generic.task.id": `Tâche {id} {status}`,
  "generic.tasks": `Tâche(s)`,
  "generic.tasks.count": `{count, plural, =0 {0 tâche} one {1 tâche} other {{count} tâches}}`,
  "generic.tasks.count.error": `{count, plural, =0 {Aucune tâche concernée} one {1 tâche concernée} other{{count} tâches concernées}}`,
  "generic.tasks.count.success": `{count, plural, =0 {Aucune tâche impliquée} one {S'applique à une tâche} other{S'applique à {count} tâches}}`,
  "generic.tasks.none.withFilter": `Aucune tâche ne correspond aux critères sélectionnés.`,
  "generic.technicalInformations": `Informations techniques`,
  "generic.technicalGuides": `Guides techniques`,
  "generic.terminal": `Terminal`,
  "generic.time": `Heure`,
  "generic.timezone": `Fuseau horaire`,
  "generic.timezone.placeHolder": `Sélectionnez le fuseau horaire de l'emplacement`,
  "generic.toSearch": `Rechercher`,
  "generic.total": `Total`,
  "generic.type": `Type`,
  "generic.types": `Types`,
  "generic.boardFirmware": `Version du micrologiciel (BIOS)`,
  "generic.boardRevision": `Version de carte-mère`,
  "generic.releaseChannels": `Version`,
  "generic.releaseChannels.filterBy": `Par version`,
  "generic.releaseNotes": `Notes de versions`,
  "generic.value": `Valeur`,
  "generic.warning": `Attention`,
  "generic.warningLevelReached": `Échecs fréquents`,
  "generic.wifi": `Wi-Fi`,
  "generic.wifis.count": `{count, plural, =0 {aucun Wi-Fi} one {1 Wi-Fi} other {{count} Wi-Fis}}`,
  "generic.rogueSsids.count": `{count, plural, =0 {aucun Wi-Fi} one {1 Wi-Fi} other {{count} Wi-Fis}}`,
  "generic.rogueSsids.count.error": `{count, plural, =0 {Aucun Wi-Fi concerné} one {1 Wi-Fi concerné} other{{count} Wi-Fis concernés}}`,
  "generic.rogueSsids.count.success": `{count, plural, =0 {Aucun Wi-Fi impliqué} one {S'applique à un Wi-Fi} other{S'applique à {count} Wi-Fis}}`,
  "generic.wifiRadios": `Émetteurs Wi-Fi`,
  "generic.networking": `Réseautique`,
  "generic.networking.CNI": `Confinée`,
  "generic.networking.CNI.desc": `Ce service a un accès réseau limité à la configuration visible ci-après.`,
  "generic.networking.HOST": `Non-confinée`,
  "generic.networking.HOST.desc": `Ce service a plein accès au réseau et peut voir, modifier et injecter du traffic réseau sur tous les ports de l'équipement hôte.`,
  "generic.networkPorts": `Ports réseau`,
  "generic.desktop": `bureau`,
  "generic.mobile": `appareil mobile`,
  "generic.unblock": `Débloquer`,
  "generic.unchanged": `Inchangé`,
  "generic.unknown": `Inconnu(e)`,
  "generic.unknown.fem": `Inconnue`,
  "generic.unknown.masc": `Inconnu`,
  "generic.unauthorized": `Non autorisé`,
  "generic.unavailable": `Indisponible`,
  "generic.up": `Actif`,
  "generic.accounts.up": `Actif`,
  "generic.accounts.up.explanation": `Tous les équipements du compte sont en ligne`,
  "generic.locations.up": `Actif`,
  "generic.locations.up.explanation": `Tous les équipements de l'emplacement sont en ligne`,
  "generic.devices.up": `En ligne`,
  "generic.updated": `Mis à jour`,
  "generic.updatedBy": `Mise à jour par`,
  "generic.updatedOn": `Mise à jour le`,
  "generic.user": `{count, plural, =0 {Aucun utilisateur} one {1 utilisateur} other {{count} utilisateurs}}`,
  "generic.userGuides": `Guides utilisateur`,
  "generic.vendor": `Fabricant`,
  "generic.version": `Version`,
  "generic.viewAllStatus": `Voir tous les statuts`,
  "generic.viewList": `Vue liste`,
  "generic.viewGrid": `Vue grille`,
  "generic.viewGrid.tooMany": `Trop d'équipements pour afficher la vue grille`,
  "generic.virtual": `Virtuel`,
  "generic.volumes": `Volumes`,
  "generic.volumes.count": `{count, plural, =0 {Aucun volume} one {1 volume} other {{count} volumes}}`,
  "generic.volumes.count.error": `{count, plural, =0 {Aucun volume concerné} one {1 volume concerné} other{{count} volumes concernés}}`,
  "generic.volumes.count.success": `{count, plural, =0 {Aucun volume impliqué} one {S'applique à un volume} other{S'applique à {count} volumes}}`,
  "generic.volumes.none.withFilter": `Aucun volume ne correspond aux critères sélectionnés.`,
  "generic.AllStatusOk": `Tous les statuts sont corrects.`,
  "generic.SomeStatusNok": `Des statuts doivent être vérifiés.`,
  "generic.yes": `Oui`,
  "generic.wifiState": `État Wi-Fi`,
  "generic.zone": `Zone`,
  "generic.zoneType.NONE": `Ouverte`,
  "generic.zoneType.MAC_WHITELIST": `Restreinte`,
  "generic.zones": `Zones`,

  "generic.colors.black": `Noir`,
  "generic.colors.blue": `Bleu`,
  "generic.colors.green": `Vert`,
  "generic.colors.red": `Rouge`,

  "generic.data.invalid": `Donnée invalide`,
  "generic.data.transfer": `Données transférées`,
  "generic.data.b": `o`,
  "generic.data.b.infoTitle": `Octet(s)`,
  "generic.data.kb": `ko`,
  "generic.data.kb.infoTitle": `Kilo-octet(s)`,
  "generic.data.mb": `Mo`,
  "generic.data.mb.infoTitle": `Mega-octet(s)`,
  "generic.data.gb": `Go`,
  "generic.data.gb.infoTitle": `Giga-octet(s)`,
  "generic.data.tb": `To`,
  "generic.data.tb.infoTitle": `Tera-octet(s)`,
  "generic.data.pb": `Po`,
  "generic.data.pb.infoTitle": `Peta-octet(s)`,
  "generic.time.datetime": `Date/heure`,
  "generic.time.from": `De`,
  "generic.time.to": `à`,
  "generic.time.ss": `{count, plural, =0 {seconde} one {seconde} other {secondes}}`,
  "generic.time.mm": `{count, plural, =0 {minute} one {minute} other {minutes}}`,
  "generic.time.HH": `{count, plural, =0 {heure} one {heure} other {heures}}`,
  "generic.time.DD": `{count, plural, =0 {jour} one {jour} other {jours}}`,

  "generic.errors.message.unloading": `La page est en cours de déchargement...`,
  "generic.errors.message.short": `Une erreur est survenue`,
  "generic.errors.message.long": `Notre équipe a été prévenue et est en train de corriger le problème. Revenez un peu plus tard ou cliquez sur "Recharger" pour tenter de recharger la page courante.`,
  "generic.errors.message.thx": `Merci de votre compréhension et patience.`,
  "generic.errors.message": `Une erreur est survenue, veuillez réessayer plus tard.`,
  "generic.errors.errorOccured.title": `Une erreur est survenue, veuillez réessayer.`,
  "generic.errors.errorOccured.detail": `Il se peut que votre dernière action n'ait pu aboutir.`,
  "generic.errors.networkErrorOccured.title": `Une erreur réseau est survenue, veuillez réessayer`,
  "generic.errors.docusaurusSerchFile": `Les résultats de la documentation ne sont pas accessibles, veuillez réessayer plus tard.`,
  "generic.errors.unauthorized": `
    <p>Un problème d'accès est survenu de manière inattendue. Votre session a été déconnectée.  Veuillez essayer à nouveau de vous connecter.</p>
  `,
  "generic.errors.connectionLost": `
    <p>Connexion perdue, vérifiez votre connexion internet.</p>
    <p>L'application se reconnectera automatiquement lorsque la connexion sera rétablie.</p>
  `,
  "generic.errors.tokenExpired.btn": `Se reconnecter`,
  "generic.errors.tokenExpired": `
    Votre jeton d'authentification a expiré.{linebreak}
    Pour plus de sécurité veuillez vous reconnecter.
  `,

  "generic.errors.inputFile.serverRejection": `Votre fichier a été rejeté par le serveur. Vérifiez qu'il est valide.`,
  "generic.errors.inputFile.serverRejection.withSize": `Votre fichier a été rejeté par le serveur. Vérifiez qu'il est valide et que sa taille n'excède pas {size}.`,

  "generic.buttons.accept": `Accepter`,
  "generic.buttons.add": `Ajouter`,
  "generic.buttons.addComponent": `Ajouter un composant`,
  "generic.buttons.allow": `Autoriser`,
  "generic.buttons.allow.disabledReason": `Déjà autorisé`,
  "generic.buttons.apply": `Appliquer`,
  "generic.buttons.back": `Retour`,
  "generic.buttons.block": `Bloquer`,
  "generic.buttons.cancel": `Annuler`,
  "generic.buttons.change": `Changer`,
  "generic.buttons.close": `Fermer`,
  "generic.buttons.clear": `Effacer`,
  "generic.buttons.clearAllFilter": `Effacer tous les filtres`,
  "generic.buttons.clearAllValues": `Effacer toutes les valeurs`,
  "generic.buttons.clearFilter": `Effacer`,
  "generic.buttons.choose": `Choisir`,
  "generic.buttons.confirm": `Confirmer`,
  "generic.buttons.connexion": `Connectez-vous`,
  "generic.buttons.continue": `Continuer`,
  "generic.buttons.copy": `Copier`,
  "generic.buttons.createAccount": `Créer le compte`,
  "generic.buttons.createLocation": `Créer l'emplacement`,
  "generic.buttons.delete": `Supprimer`,
  "generic.buttons.deleteUserAccount": `Révoquer l'accès au compte`,
  "generic.buttons.download": `Télécharger`,
  "generic.buttons.deny": `Refuser`,
  "generic.buttons.deny.disabledReason": `Déjà refusé`,
  "generic.buttons.edit": `Éditer`,
  "generic.buttons.erase": `Effacer`,
  "generic.buttons.finish": `Terminer`,
  "generic.buttons.format": `Formater`,
  "generic.buttons.getAKey": `Obtenir une clé`,
  "generic.buttons.getMyKey": `Montrer ma clé`,
  "generic.buttons.ignore": `Ignorer`,
  "generic.buttons.import": `Importer`,
  "generic.buttons.install": `Installer`,
  "generic.buttons.login": `Connexion`,
  get "generic.buttons.manage"() {return this["generic.manage"]},
  "generic.buttons.modifyAccount": `Modifier le compte`,
  "generic.buttons.modifyLocation": `Modifier l'emplacement`,
  "generic.buttons.next": `Suivant`,
  get "generic.buttons.no"() {return this["generic.no"]},
  "generic.buttons.ok": `Ok`,
  "generic.buttons.previous": `Précédent`,
  "generic.buttons.reboot": `Redémarrer`,
  "generic.buttons.updateFirmwareNow": `Mise à jour immédiate`,
  "generic.buttons.rebootDevice": `Redémarrer l'équipement`,
  "generic.buttons.removeDevice": `Retirer l'équipement`,
  "generic.buttons.removeLocation": `Retirer l'emplacement`,
  "generic.buttons.rescan": `Rescanner`,
  "generic.buttons.resendToken": `Renvoyer le jeton`,
  "generic.buttons.reset": `Réinitialiser`,
  "generic.buttons.retry": `Réessayer`,
  "generic.buttons.save": `Sauvegarder`,
  "generic.buttons.start": `Démarrer`,
  "generic.buttons.stop": `Stopper`,
  "generic.buttons.sendToken": `Envoyer le jeton`,
  "generic.buttons.update": `Mettre à jour`,
  "generic.buttons.upload": `Téléverser`,
  "generic.buttons.view": `Voir`,
  "generic.buttons.viewAll": `Voir tout`,
  get "generic.buttons.yes"() {return this["generic.yes"]},

  "faq": `FAQ`,
  "faq.title": `Aide`,
  "faq.noresults": `Aucune question renseignée.`,
  "faq.questions.1.label": `Qu’est-ce que SentinelC?`,
  "faq.questions.1.answer": `
    <p>
      SentinelC est un « routeur sans fil » qui permet de sécuriser l’ensemble des réseaux sans fil d’une petite entreprise en utilisant les meilleures technologies et pratiques de sécurité des communications sans fils.
    </p>
  `,
  "faq.questions.2.label": `À qui s’adresse le routeur SentinelC?`,
  "faq.questions.2.answer": `
    <p>
      Le routeur SentinelC est essentiellement destiné à des petites entreprises qui souhaitent protéger leurs réseaux sans fil sans devoir engager des budgets astronomiques pour profiter des technologies de niveau Entreprise.
    </p>
  `,
  "faq.questions.3.label": `De quelle façon SentinelC sécurise-t-il les réseaux sans fils?`,
  "faq.questions.3.answer": `
    <p>
      SentinelC assure que chacun des réseaux sans fil que vous définissez soit protégé par des mots de passe et permettre en tout temps d’effectuer l’encryption des données transmises afin de protéger vos données et votre navigation Internet.
    </p>
  `,
  "faq.questions.4.label": `Est-ce que SentinelC offre une sécurité de niveau Entreprise?`,
  "faq.questions.4.answer": `
    <p>
      Oui. SentinelC offre la création de réseau sans fil avec protection de niveau Entreprise de type WPA2 - Entreprise. Cela signifie que vous pouvez donner accès spécifiquement à des personnes afin que celles-ci se connectent à votre réseau sécurisé en fournissant leur courriel et un mot de passe préalablement défini par eux.
    </p>
  `,
  "faq.questions.5.label": `Quels types de réseau puis-je créer avec un routeur SentinelC?`,
  "faq.questions.5.answer": `
    <p>
      Le routeur SentinelC vous permet de créer jusqu’à trois (3) réseaux sans fil, soit :
    </p>
    <ul>
      <li>
        un réseau privé sécurisé pour vos employés ou utilisateurs privilégiés
      </li>
      <li>
        un réseau Invité sécurisé pour vos clients ou visiteurs
      </li>
      <li>
        un réseau public (ouvert) de type Portail qui permet à vos clients/visiteurs d’obtenir une clé d’accès pour se connecter à votre réseau Invité sécurisé
      </li>
    </ul>
  `,

  "user.add": `Créer un profil administrateur`,
  "user.add.all": `Ajouter tous les utilisateurs`,
  "user.deletion.explanation": `
    <p>
      Vous allez supprimer le profil de <strong>{user}</strong>. Cette action est irréversible et supprimera tous les accès ainsi que les données associées.
    </p>
    <p>
      Êtes-vous certain de vouloir retirer l’accès à ce compte à ce profil utilisateur?
    </p>
  `,
  "user.inexistant": `L'utilisateur demandé a été supprimé ou n'existe pas.`,
  "user.account": `Profil administrateur`,
  "user.password": `Changer le mot de passe`,
  "user.password.info": `Veuillez noter qu'un changement de mot de passe s'appliquera à tous vos comptes.`,
  "user.form.password": `Pour modifier votre mot de passe, cliquez sur le button ci-dessous.`,
  "user.form.password.twoFactor": `Authentification double-facteur`,
  "user.form.password.twoFactor.btn": `Gérer l'authentification double-facteur`,
  "user.form.password.btn": `Modification du mot de passe`,
  "user.manage.account.date": `Profil créé le {date}`,
  "user.manage.account.block": `Bloquer tous les accès à {location}`,
  "user.manage.support.privileges": `Administrateur support`,
  "user.creation.password": `L'utilisateur sera invité à définir son mot de passe en suivant le lien dans le courriel d'activation qui lui sera transmis.`,
  "user.updateGeneric.success.content":`Le profil de <strong>{firstname} {lastname}</strong> a été mis à jour.`,
  "user.updateYours.success.content":`Votre profil a été mis à jour.`,
  "user.updatePassword.success.content":`Votre mot de passe a été modifié avec succès!`,
  "user.addGeneric.success.content":`L'utilisateur <strong>{firstname} {lastname}</strong> a été ajouté à votre compte SentinelC.`,
  "user.remove.success":`L'utilisateur <strong>{firstname} {lastname}</strong> a été supprimé de votre compte SentinelC.`,

  "users.online.guest": `{count, plural, =0 {Aucun invité connecté} one {1 invité connecté} other {{count} invités connectés}}`,
  "users.search.placeholder": `Chercher un courriel ou un nom (3 caractères min.)`,
  "users.search.notfound": `Aucun utilisateur trouvé`,
  "users.title": `Administrateurs`,

  "organization.edit": `Éditer le compte`,
  "organization.inexistant": `Compte inexistant`,
  "organization.map": `Aperçu du compte`,
  "organization.profile": `Compte SentinelC`,
  "organization.profile.edit": `Éditer le compte SentinelC`,
  "organization.profile.update.success": `<strong>{organization}</strong> a été mis à jour!`,
  "organization.owner": `Contact`,
  "organization.owner.small": `Ces informations nous seront utiles pour communiquer avec vous afin d'assurer un support adéquat en cas de nécessité.`,
  "organization.details": `Détails`,

  "organization.networks.state.offlinedevices": `{count, plural,
      =0 {Aucun équipement n'est <strong>hors-ligne</strong>}
      one {Un équipement est <strong>hors-ligne</strong>}
      other {{count} équipements sont <strong>hors-ligne</strong>}
    }
  `,
  "organization.networks.state.partial": `La disponibilité est <strong>partielle</strong>`,
  "organization.networks.state.noSsid": `<strong>Aucun</strong> réseau Wi-Fi défini.`,
  "organization.networks.state.allok": `Fonctionnement <strong>optimal</strong>`,
  "organization.noDevice": `<strong>Aucun</strong> équipement SentinelC`,
  "organization.noLocation": `Vous ne possédez aucun emplacement, pour en ajouter un, veuillez cliquer sur`,
  "organization.addLocation": `ajouter une SentinelC`,

  "location.activity": `Activité de l'emplacement`,
  "location.addCamera": `Ajouter une caméra`,
  "location.addFastConnect": `Ajouter un FactConnect!`,
  "location.addNetworks": `Ajouter un Wi-Fi`,
  "location.addNetworks.explanation": `Ajouter un wi-fi s'effectue au niveau de l'emplacement afin de permettre l'émission de celui-ci par d'autres équipements de l'emplacement s'il y a lieu.`,
  "location.addDrone": `Ajouter une équipement de surveillance`,
  "location.addDrone.content": `Cette fonction a pour but d’ajouter un équipement de surveillance SentinelC.`,
  "location.addDrone.update.success": `Équipement de surveillance {deviceLocation} ajoutée avec succés`,
  "location.addDrone.offline.warning.title": `Votre équipement semble être hors-ligne.`,
  "location.addDrone.offline.warning.content": `
    <p>
      Vous pouvez toujours procéder à l'installation même si votre nouvel équipement de surveillance n'est pas déjà branché ou si la connexion Internet n'est pas disponible.
    </p>
    <p>
      La configuration sera appliquée dès que votre appareil sera connecté à Internet.
    </p>
  `,

  "location.addRouter": `Ajouter un routeur`,
  "location.addRouter.update.success": `Routeur {deviceLocation} ajouté avec succés`,
  "location.addRouter.content": `Cette fonction a pour but d’ajouter un routeur SentinelC à votre emplacement afin de profiter de ce dernier.`,
  "location.addRouter.online.content": `
    <p>Votre équipement est en ligne.</p>
    <p>
      Cliquez sur le bouton Confirmer pour poursuivre l'installation de votre routeur.
    </p>
  `,
  "location.addRouter.offline.content": `
    <p>Votre équipement semble être hors-ligne.</p>
    <p>
      Vous pouvez toujours procéder à l'installation de ce dernier, la configuration sera appliquée dès que votre appareil sera connecté à Internet.
    </p>
  `,

  "location.addAccessPoint": `Ajouter un point d'accès`,
  "location.addAccessPoint.update.success": `Point d'accès {deviceLocation} ajouté avec succés`,
  "location.addAccessPoint.content": `Cette fonction a pour but d’ajouter un équipement SentinelC qui vous permettra d’étendre le signal de certains ou de tous vos réseaux Wi-Fi afin d’assurer une plus grande couverture du signal Wi-Fi, lorsque requis.`,
  "location.addAccessPoint.networksToExtend": `Réseaux Wi-Fi que vous souhaitez étendre`,

  "location.addAccessPoint.online.inTrunk.message": `Nous avons détecté votre point d'accès dans un port valide (port {port}) de votre équipement {location}.`,
  "location.addAccessPoint.online.inTrunk.content": `
    <p>
      Cliquez sur le bouton Confirmer pour poursuivre l'installation de votre point d'accès.
    </p>
    <p>
      Aucune autre action n'est requise.
    </p>
  `,

  "location.addAccessPoint.online.outTrunk.message": `Nous avons détecté que votre point d'accès est connecté à un type de port non valide (port {port}) sur votre appareil {location}`,
  "location.addAccessPoint.online.outTrunk.content": `
    <p>
      Vous pouvez toujours poursuivre l'installation en cliquant sur le bouton Confirmer.
    </p>
    <p>
      Cependant, après avoir confirmé l'installation, vous devez brancher votre point d'accès sur un port de type <strong>POINT D'ACCÈS</strong>. Vous pouvez soit modifier le type de port réel, soit sélectionner un port de type POINT D'ACCÈS disponible.
    </p>
  `,

  "location.addAccessPoint.offline.warning.title": `Votre équipement semble être hors-ligne.`,
  "location.addAccessPoint.offline.warning.content": `
    <p>
      Vous pouvez toujours procéder à l'installation même si votre nouveau point d'accès n'est pas déjà connecté à votre appareil SentinelC ou si la connexion Internet n'est pas disponible.
    </p>
    <p>
      La configuration sera appliquée dès que votre appareil sera connecté à votre appareil SentinelC et à Internet.
    </p>
  `,
  "location.addAccessPoint.offline.noAvailablePort.content": `
    <p>
      {count, plural,
        =0 {Vous disposez actuellement d'aucun équipement SentinelC et dons <strong>aucun port</strong> n'est disponible.}
        one {Vous disposez actuellement d'un équipement SentinelC mais <strong>aucun port</strong> n'est disponible.}
        other {Vous disposez actuellement de {count} équipements SentinelC mais <strong>aucun port</strong> n'est disponible.}
      }
    </p>
    <p>
      Si vous souhaitez toujours ajouter ce nouveau point d'accès, vous devrez réorganiser vos ports de manière à pouvoir en libérer un et le configurer comme port de type POINT D'ACCÈS.
    </p>
  `,
  "location.addAccessPoint.offline.hasAvailablePort.content": `
    <p>
      {count, plural,
        =0 {Vous disposez actuellement d'aucun équipement SentinelC et dons <strong>aucun port</strong> n'est disponible.}
        one {Vous disposez actuellement d'un équipement SentinelC dans lesquels vous pouvez brancher ce nouveau point d'accès.}
        other {Vous disposez actuellement de {count} équipements SentinelC dans lesquels vous pouvez brancher ce nouveau point d'accès.}
      }
    </p>
    <p>
      Sachez que vous devez brancher cet appareil sur un port de type <strong>POINT D'ACCÈS</strong> OU modifier un port <strong>DISPONIBLE</strong> pour le configurer comme port de type <strong>POINT D'ACCÈS</strong>.
    </p>
    <p>
      <strong>Ports Points d’accès et disponibles par équipement:</strong>
    </p>
  `,
  "location.map": `Carte du réseau`,
  "location.map.label": `Pour consulter la carte complète du réseau*`,
  "location.map.label.noRouter": `La carte du réseau n'est pas disponible lorsqu'aucun équipement n'est en mode routeur.`,
  "location.map.sublabel": `* Résolution minimale requise (minimum 768px et non tactile)`,
  "location.map.smallScreen": `Cette fonctionnalité est optimisée pour un affichage plus grand, elle pourrait ne pas fonctionner sur les petits appareils.`,
  "location.map.hasLoop": `Il existe des boucles dans les branchements d'équipements, ceci peut entrainer des anomalies au niveau visuel des branchements sur ce graphique.`,
  "location.noNetworks": `Vous ne possédez aucun réseau Wi-Fi. {linebreak}Pour en ajouter un, veuillez cliquer sur`,
  "location.maximumNetworksReached": `Vous avez atteint le nombre maximum de réseaux possibles sur l'ensemble de vos équipements.`,
  "location.noRadioOnDevices": `{count,plural, =0 {Aucun équipement} one {Votre équipement ne peut émettre aucun réseau Wi-Fi} other {Vos équipements ne peuvent émettre aucun réseau Wi-Fi}}`,

  "location.addModal.explanation": `Ajouter le réseau Wi-Fi* qui répond le mieux à vos besoins**:`,
  "location.addModal.note": `* Tous les types de réseau Wi-Fi sont sécurisés par une clé partagée par les utilisateurs (appareils).`,
  "location.addModal.note2": `** Vous pouvez ajouter autant de Wi-Fi que vous désirez, mais un trop grand nombre pourrait être plus complexe à gérer.`,

  "location.addModal.addPortal.title": `Wi-Fi Portail Entreprise`,
  "location.addModal.addPortal.content": `Vous souhaitez permettre à vos employés de réinitialiser leur mot de passe leur donnant accès au réseau Entreprise.`,

  "location.addModal.addPSK.title": `Réseau Wi-Fi <strong>Privé</strong>`,
  "location.addModal.addPSK.content": `<p>Ce type de réseau permet aux appareils connectés de <strong>partager des ressources de manière sécurisée</strong>.</p>`,

  "location.addModal.addGuest.title": `Réseau Wi-Fi <strong>Invité</strong>`,
  "location.addModal.addGuest.content": `<p>Ce type de réseau autorise seulement <strong>un accès à internet</strong> à vos invités ou visiteurs.</p>`,

  "location.addDevice": `Ajouter un emplacement`,
  "location.addDevice.success": `Emplacement {networkLocation} créé avec succès`,
  "location.addDevice.content": `Cette fonction vous permet d'ajouter un nouveau routeur principal qui vous permet de définir des réseaux Wi-Fi distincts et séparés reliés à votre compte SentinelC.`,
  "location.addDevice.warning.content": `Non prévue pour être un amplificateur de portée.`,

  "location.addPortal.title": `Ajouter un réseau Wi-Fi Portail Entreprise`,
  "location.addPortal.content": `
    <p>
      Ce type de réseau permet d’offrir une page d’accueil à vos visiteurs afin d’obtenir un accès à votre réseau Wi-Fi public.
    </p>
    <p>
      <strong>Non recommandé pour une utilisation à domicile</strong>, car il ouvre votre réseau à des tentatives de connexion non désirées.
    </p>
  `,

  "location.addGuestWifi.title": `Ajouter un Wi-Fi Invité`,
  "location.addGuestWifi.content": `
    <p>
     Un réseau de cette nature est idéal pour offrir un accès Internet à des visiteurs (Invités) ou pour des appareils que vous souhaitez isoler de votre réseau privé.
    </p>
  `,
  "location.addGuestWifi.content2": `
    <p>
      Le mode de fonctionnement diffère s’il s’agit d’un réseau destiné à une utilisation à la maison ou en entreprise.
    </p>
  `,

  "location.updateschedule.modes.listAppliances": ` { count, plural,
    =0 {Aucun équipement}
    one {L'équipement suivant héritera du changement de configuration:}
    other {Les équipements suivants hériteront du changement de configuration:}
  }
  `,
  "location.updateschedule.label": `Horaire de mise à jour du routeur`,
  "location.updateschedule.modes.label": `Sélectionnez l'horaire qui vous convient le plus et qui n'affecte pas vos activités à cet emplacement.`,
  "location.updateschedule.modes.placeholder": `Sélectionnez un mode de mise à jour`,
  "location.updateschedule.modes.AUTO": `Automatique`,
  "location.updateschedule.modes.AUTO.sentence": `Les mises à jour seront appliquées entre 2:00 et 3:00 am.`,
  "location.updateschedule.modes.IMMEDIATE": `Immédiat`,
  "location.updateschedule.modes.IMMEDIATE.sentence": `Les mises à jour seront appliquées dès qu'elles seront disponibles.`,
  "location.updateschedule.modes.CUSTOM": `Personnalisé`,
  "location.updateschedule.modes.CUSTOM.sentence": `Les mises à jour seront effectuées dans l'heure qui suit {hour}.`,
  "location.updateschedule.modes.CUSTOM.sentence.withoutHour": `Les mises à jour seront effectuées dans l'heure qui suit l'heure de début.`,
  "location.updateschedule.interval.label": `Heure de début`,
  "location.updateschedule.interval.endTime": `Heure de fin`,

  "location.currentFirmware.version": `Version actuelle du micrologiciel`,
  "location.currentFirmware.installed": `Version installée le`,
  "location.availableFirmware.version": `Dernière version disponible`,
  "location.availableFirmware.uptodate": `À jour`,
  "location.addPskWifi.title": `Ajouter un Wi-Fi à clé partagée`,
  "location.addPskWifi.content": `
    <p>
      Un réseau privé de cette nature convient autant à la <strong>maison</strong> qu’en <strong>entreprise</strong>. Il s’agit du même type de réseau que l’on retrouve de manière généralisée.
    </p>
    <p>
      Dans ce cas, l’accès au réseau sans fil est donné aux appareils qui s’y connectent avec la clé (mot de passe).
    </p>
  `,
  "location.edit": `Éditer l'emplacement`,
  "location.inexistant": `L'emplacement n'existe pas ou a été supprimé.`,
  "location.isOffline": `{count, plural,
    =0 {Aucun équipement hors-ligne ou indisponible}
    one {
      {
        total, plural,
        one {L'équipement est hors-ligne ou indisponible}
        other {Un équipement est hors-ligne ou indisponible}
      }
    }
    other {{count} équipements sont hors-ligne ou indisponibles}}`,
  "location.configuredWiFiNetwork.noNetworks": `Cet emplacement ne peut émettre aucun réseau Wi-Fi.`,
  "location.firmware": `Version du micrologiciel`,
  "location.versionStatus": `Statut du micrologiciel`,
  "location.ip": `Adresse IP`,
  "location.address": `Adresse`,
  "location.address.none": `Aucune adresse spécifiée`,
  "location.address.edit": `Modifier l'adresse de l'emplacement`,
  "location.address.address": `Adresse`,
  "location.address.apt": `Appartement, suite, etc.`,
  "location.address.zipcode": `Code postal`,
  "location.address.country": `Pays`,
  "location.address.city": `Ville`,
  "location.address.state": `État/Province`,
  "location.address.GPS": `Coordonnées GPS`,
  "location.address.GPS.lat": `Lat.`,
  "location.address.GPS.lat.long": `Latitude`,
  "location.address.GPS.lon": `Lon.`,
  "location.address.GPS.lon.long": `Longitude`,
  "location.location": `Emplacement`,
  "location.location.placeholder": `Emplacement (ex. Bureau, Maison, etc.)`,
  "location.mac": `Adresse MAC`,
  "location.model": `Modèle`,
  "location.owner": `Contact`,
  "location.product": `Clé du produit`,
  "location.remove": `Supprimer {location}`,
  "location.remove.success": `Votre emplacement {location} est en cours de suppression, cela peut prendre quelques minutes.`,
  "location.appliances": `{count, plural, =0 {Équipement} one {Équipement} other {Équipements}}`,
  "location.appliances.operations": `Comprendre les opérations sur les équipements`,
  "location.routeurs": `{count, plural, =0 {Routeur} one {Routeur} other {Routeurs}}`,
  "location.remove.modal.title": `Supprimer {location}`,
  "location.remove.modal.messageAP": `Vous devez d'abord retirer tous vos équipements avant de pouvoir retirer cet emplacement.`,
  "location.remove.modal.content": `
    <p>Cette action supprimera l'emplacement <strong>{location}</strong>, et toutes les configurations associées seront perdues.</p>
    <p><strong>Notez que cette action est irréversible</strong>{linebreak} Êtes-vous sûr de vouloir supprimer cet emplacement?</p>
  `,
  "location.sentinelCDevice": `Équipement SentinelC de cet emplacement. {linebreak}Authentification réussie.`,
  "location.sentinelCDevices.routersandAP": `Routeurs, points d'accès et équipements de surveillance`,
  "location.sentinelCDevices.videoCameras": `Caméras vidéo`,
  "location.sentinelCDevices.diskDrives": `Disques durs`,
  "location.sentinelCDevices.IOT": `Appareils intelligents`,
  "location.serial": `Numéro de série`,
  "location.update": `Dernière mise à jour`,
  "location.update.success": `L'emplacement <strong>{location}</strong> a été mis à jour.`,
  "location.delete.success": `L'emplacement <strong>{location}</strong> a été supprimé.`,

  "location.modal.devices": `Statut récapitulatif des équipements`,
  "location.modal.ssids": `Statut récapitulatif des Wi-Fis`,
  "location.modal.hosts": `Statut récapitulatif des appareils`,

  "locations.title": `Emplacements`,

  "video.liveFeed": `Live feed`,
  "video.noVideoCamera": `No video camera installed.`,

  "host.connectionDetails": `Détails de connexion`,
  "host.inexistant": `L'appareil demandé n'existe pas`,
  "host.info": `Informations sur l'appareil`,
  "host.name": `Nom de l'appareil`,
  "host.lastSeen": `Vu le`,
  "host.hostname": `Nom d'origine de l'appareil`,
  "host.hostname.short": `Nom d'origine`,
  "host.mac": `Adresse MAC`,
  "host.category": `Catégorie`,
  "host.category.filterBy": `Par catégorie`,
  "host.category.placeholder": `Sélectionnez une catégorie`,
  "host.category.MOBILE_PHONE": `Téléphone cellulaire`,
  "host.category.MOBILE_TABLET": `Tablette`,
  "host.category.DESKTOP": `Ordinateur de bureau`,
  "host.category.LAPTOP": `Ordinateur portable`,
  "host.category.VOIP_PHONE": `Téléphone voix par IP`,
  "host.category.AUDIO": `Système de son`,
  "host.category.CAMERA": `Caméra IP`,
  "host.category.SECURITY": `Système de sécurité`,
  "host.category.SIGNAGE": `Signalisation digitale`,
  "host.category.HVAC": `Chauffage, ventilation, air conditionné`,
  "host.category.SENSOR": `Capteur`,
  "host.category.TV": `Télévision, télévision intelligente, appareil de capture`,
  "host.category.NETWORKING": `Équipements réseau (routeur, switch, pare-feu, point d'accès)`,
  "host.category.WEARABLE": `Vêtement ou matériel portable`,
  "host.category.SERVER": `Serveur`,
  "host.category.PRINTER": `Imprimante`,
  "host.category.GAMING_CONSOLE": `Console ou système de jeu`,
  "host.category.IOT_HUB": `Concentrateur pour appareils intelligents`,
  "host.category.KIOSK": `Kiosque intéractif`,
  "host.category.LIGHT": `Lampe ou système d'éclairage`,
  "host.category.APPLIANCE": `Électroménager`,
  "host.category.OTHER.simple": `Autres (précisez)`,
  get "host.category.OTHER"() {return this["generic.others"]},
  "host.categoryOther": `Description de la catégorie`,
  "host.manufacturer": `Fournisseur/Fabricant`,
  "host.provider": `Fournisseur`,
  "host.manufacturer.placeholder": `Ex. Bell, HP, Apple, Sony,...`,
  "host.use": `Usage attendu`,
  "host.vlan": `Zone`,
  "host.vlan.ip": `Adresse IP`,
  "host.use.placeholder": `Décrivez l'utilisation attendue de l'appareil.`,

  "host.isApplianceLocation": `
    <strong>Cet appareil est un équipement réseau SentinelC de votre compte.</strong>{linebreak}
    Pour modifier ses propriétés en tant qu'équipement veuillez cliquer sur le lien suivant:
  `,
  "host.isApplianceOfAnotherLocation.goto": `
    Aller à l'appareil
  `,

  "host.whitelisting.addVlanPermission": `Ajouter une permission à la zone restreinte`,
  "host.whitelisting.authorization": `Permissions sur les zones protégées (par emplacement)`,
  "host.whitelisting.authorization.vlan.none": `Aucune permission accordée, veuillez cliquer sur éditer pour mettre à jour les permissions`,
  "host.whitelisting.authorization.vlan.none.short": `Aucune permission accordée`,
  "host.whitelisting.connected.on.wifi.label": `Connecté en Wi-Fi sur`,
  "host.whitelisting.connected.on.wire.label": `Connecté en filaire`,
  "host.whitelisting.connected.on.virtual.label": `Connecté virtuellement`,
  "host.whitelisting.connected.toAppliance": `Connecté à l'équipement`,
  "host.whitelisting.connectionAttempt": `Tentative de connexion`,
  "host.whitelisting.connectionAttempt.number": `# d'échec de connexion`,
  "host.whitelisting.authorizedOn": `Autorisé le`,
  "host.whitelisting.authorizedOnVlan": `Autorisé sur la zone`,
  "host.whitelisting.lastAttemptOn": `Dernière tentative de connexion le`,
  "host.whitelisting.deniedOn": `Refusé le`,
  "host.whitelisting.ignoredOn": `Ignoré le`,
  "host.whitelisting.manageAttemptRequired": `Vous devez définir les permissions (Accepter or Refuser) pour toutes les tentatives de connexion.`,
  "host.whitelisting.selectVlanPermission": `Sélectionner une zone protégée`,
  "host.whitelisting.suggestBlock": `Vous avez refusé toutes les tentatives d'accès pour cet appareil. Si vous souhaitez bloauer cet appareil, vous pouvez cliquer sur le bouton Annuler de cette fenêtre et bloquer l'appareil depuis la liste des appareils non-autorisés.`,
  "host.whitelisting.ignored": `La tentative de connexion a été ignorée.`,
  "host.whitelisting.failure.attemptCount": `Nombre de tentatives`,
  "host.whitelisting.failure.explanation": `Appareils ayant plusieurs tentatives de connexion qui n'ont pas abouties.`,
  "host.whitelisting.failure": `Dernier échec d'authentification le `,
  "host.whitelisting.neverSuccedToConnect": `Aucune connexion réussie`,
  "host.whitelisting.neverSuccedToConnect.label": `Vu le`,

  "hosts.filterby.network": `Filtrer par emplacement`,
  "hosts.filterby.appliance": `Filtrer par équipement`,
  "hosts.filterby.connectionStatus": `Filtrer par statut de connexion`,
  "hosts.filterby.search": `Filtrer par type`,

  "vlans.open.count": `{count, plural, =0 {0 ouverte} one {1 ouverte} other {{count} ouvertes}}`,
  "vlans.restricted.count": `{count, plural, =0 {0 restreinte} one {1 restreinte} other {{count} restreintes}}`,

  "vlans.manage.addVlan": `Ajouter une nouvelle zone`,
  "vlans.manage.editVlan": `Modifier une zone`,
  "vlans.manage.editVlan.nonEditableForInternal": `Non modifiable sur une zone système.`,
  "vlans.noOne": `Vous n'avez pas de zone définie. Une zone constitue un regroupement logique qui permet de segmenter et sécuriser un réseau local dans un sous-réseau virtuel.`,
  "vlans.noOne.add.sentencePart1": `Pour en ajouter une, veuillez cliquer sur`,

  "vlan.manage": `Gestion des accès aux zones restreintes`,
  "vlan.manage.modal.content1": `
    {count, plural,
      =0 {
        <p>Aucun appareil a reconfigurer</p>
      }
      one {
        <p>Afin d'appliquer le changement demandé, l'équipement suivant sera reconfiguré :</p>
      }
      other {
        <p>Afin d'appliquer le changement demandé, les équipements suivants seront reconfigurés :</p>
      }
    }
  `,
  "vlan.manage.modal.content2": `
    <p>Cette reconfiguration causera une courte interruption de service (environ 30 secondes par équipement).</p>
    <p>Assurez-vous que vos branchements correspondent aux changements de configuration des ports d'accès physiques.</p>
  `,
  "vlan.delete.modal.box": `
    <p>Vous êtes sur le point de supprimer la zone <strong>{zone}</strong>.</p>
    <p>Cette action supprimera tous les réseaux Wi-Fi qui y sont définis ainsi que tous les accès aux ressources (ports filaires) associés à cette zone.</p>
  `,
  "vlan.edit.title": `Édition de la zone «{zone}»`,
  "vlan.edit.title.noName": `Édition de la zone`,
  "vlan.edit": `Éditer la zone`,
  "vlan.delete": `Supprimer la zone`,
  "vlan.delete.named": `Suppression de la zone {zone}`,
  "vlan.noOne.onAppliance": `Aucune zone en utilisation sur cet équipement.`,
  "vlan.manage.noneAttempted": `Aucune tentative de connexion`,
  "vlan.manage.attempted.waitingForPermission": `En attente de permission`,
  "vlan.manage.attempted.denied": `Accès réfusé`,
  "vlan.restricted.tooltip": `Les appareils accèdant à cette zone logique <strong>doivent être explicitement autorisés</strong> pour obtenir l'accès aux ressources réseau.`,
  "vlan.open.tooltip": `Les appareils accèdant à cette zone logique <strong>ne nécessitent pas d'être explicitement autorisés</strong> pour obtenir l'accès aux ressources réseau.`,

  "vlan.mode.title": `Mode réseau`,
  "vlan.mode.routed": `Routage`,
  "vlan.mode.routed.sublabel": `En mode routage, les appareils clients connectés à cette zone seront routés vers l'internet. La traduction d'adresses réseau (NAT) et les services réseaux essentiels (DHCP et DNS) seront fournis.`,
  "vlan.mode.passthrough": `Passthrough`,
  //"vlan.mode.passthrough.disabled": `Cette option n'est pas disponible car il existe déjà une zone passthrough dans cet emplacement. Une seule zone passthrough authorisée par emplacement.`,
  "vlan.mode.passthrough.sublabel": `Dans une zone "passthrough", les appareils clients sont inter-connectés directement vers le port Internet (WAN). Une seule zone passthrough peut être configurée par emplacement réseau.`,

  "vlan.accessPolicy.title": `Politique d'accès`,
  "vlan.accessPolicy.management": `Permet la gestion (web et ssh) des équipements SentinelC lorsque connecté à cette zone.`,
  "vlan.accessPolicy.placeholder": `Sélectionner une politique d'accès`,
  "vlan.accessPolicy.NONE": `Ouvert`,
  "vlan.accessPolicy.NONE.sublabel": `Tous les appareils sont autorisés à se connecter`,
  "vlan.accessPolicy.MAC_WHITELIST": `Restreint`,
  "vlan.accessPolicy.MAC_WHITELIST.sublabel": `Les appareils doivent être autorisés`,
  "vlan.accessPolicy.null.sublabel": `La politique d'accès définit si cette zone sera ouverte aux connexions ou restreinte`,

  "vlan.id": `ID vlan`,
  "vlan.gatewayIp": `Passerelle IP`,
  "vlan.securityProfile.explanation": `Un profil de sécurité vous permet de définir et d'activer des fonctions de sécurité specifiques au niveau du réseau de l'emplacement tel que la détection et le blocage d'IPs malicieuses, l'obligatation de l'utilisation du serveur DNS de l'emplacement, etc. Le profil de sécurité peut être défini au niveau de la zone/vlan pour des besoins spécifiques.`,
  "vlan.securityProfile.templateVersion": `Version du profile`,
  "vlan.securityProfile.ipBlacklist": `Groupe de listes noires d'IP`,
  "vlan.securityProfile.ipBlacklist.sublabel": `Le groupe de listes noires d'IP est un moyen de surveiller et d'intercepter les connexions à des IPs malicieuses.`,
  "vlan.securityProfile.status.deprecated": `Le profil est obsolète. Il doit être changé.`,
  "vlan.securityProfile.status.needUpdate": `Le profil doit être mis à jour.`,
  "vlan.securityProfile.status.lowLevel": `Le profil a un niveau de sécurité faible.`,
  "vlan.securityProfile.status.upToDate": `Le profil est à jour.`,
  "vlan.securityProfile.status.allOverride": `Les zones surchargent toutes le profil de sécurité de l'emplacement.`,
  "vlan.securityProfile.status.noOverride": `Les zones héritent toutes du profil de sécurité de l'emplacement.`,
  "vlan.securityProfile.status.partialOverride": `Les zones suivantes surchargent le profil de sécurité de l'emplacement`,
  "vlan.securityProfile": `Profil de sécurité`,
  "vlan.securityProfile.override": `Surcharge du profil de sécurité`,
  "vlan.securityProfile.filterBy": `Par profil de sécurité`,
  "vlan.securityProfile.profile": `Profil`,
  "vlan.securityProfile.onLocation": `Profil de sécurité actuel sur les zones/vlan`,
  "vlan.securityProfile.onLocation.note": `Uniquement ces zones/vlans hériteront du profil de sécurité de l'emplacement.`,
  "vlan.securityProfile.edit": `Éditer le profil de sécurité`,
  "vlan.securityProfile.inherit": `Hériter de l'emplacement`,
  "vlan.securityProfile.inherit.select": `Choisir "Hériter de l'emplacement"`,
  "vlan.securityProfile.inherited": `Hérité de l'emplacement`,
  "vlan.securityProfile.placeholder": `Choisir un profil de sécurité`,
  "vlan.dnsConfiguration": `Paramètres DNS`,
  "vlan.dnsConfiguration.placeholder": `Choisir les paramètres DNS`,
  "vlan.dnsConfiguration.sublabel": `Ce paramètre, si activé, indique que les requêtes DNS seront gérées par une configuration Personnalisée.`,
  "vlan.dnsProvider": `Service DNS`,
  "vlan.dnsProvider.sublabel": `Permet de configurer divers attributs DNS qu'un serveur virtuel ou un objet d'écoute DNS applique au trafic DNS (par exemple, filtrage de contenu Web, protection renforcée contre les logiciels malveillants et le phishing, etc.). IP : 9.9.9.9, 149.112.112.112`,
  "vlan.dnsProvider.placeholder": `Choisir un service DNS`,
  "vlan.description.CUSTOM": `Utiliser des configurations personnalisées via le formulaire ci-dessous.`,
  "vlan.description.DISABLE": `Aucun service DNS ne sera offert aux clients de la zone.`,
  "vlan.enforceDNS": `Forcer le DNS`,
  "vlan.enforceDNS.sublabel": `Empêche les clients d'utiliser un autre serveur DNS en interceptant les requêtes. Cela n'a aucun effet si le client utilise un protocole DNS encrypté.`,
  "vlan.monitorDns": `Surveillance du DNS`,
  "vlan.monitorDns.sublabel": `Collecte les données liées à l'utilisation du DNS.`,
  "vlan.enableDot": `DNS over TLS`,
  "vlan.enableDot.sublabel": `DNS over TLS (DoT) sécurise les requêtes DNS en les cryptant via le protocole TLS.`,
  "vlan.dns": `DNS`,
  "vlan.dns.disabled": `DNS désactivé`,
  "vlan.dnsProfile": `Profil DNS`,
  "vlan.dnsProfile.placeholder": `Choisir un profil DNS`,
  "vlan.dnsProfile.sublabel": `Vous permet de configurer divers attributs DNS qu'un serveur virtuel ou un objet d'écoute DNS applique au trafic DNS (ex.: Filtre des contenus web, protection contre les malwares et les phishing, etc)`,
  "vlan.internal.deletionDisabled.explanation": `Il n'est pas possible de supprimer la zone interne.`,
  "vlan.maxDownloadRate": `Vitesse de téléchargement max.`,
  "vlan.guaranteedDownloadRate": `Vitesse de téléchargement garantie`,
  "vlan.maxUploadRate": `Vitesse de téléversement max.`,
  "vlan.guaranteedUploadRate": `Vitesse de téléversement garantie`,
  "vlan.vid.value.sublabel": `L'ID doit être comprise entre 1 et 4094.`,
  "vlan.vid.reserved.sublabel": `Cette ID de zone est réservée et ne peut être modifiée.`,
  "vlan.vid.existing.sublabel": `Voir les IDs de vlan existantes`,
  "vlan.subnet": `Sous-réseau`,
  "vlan.subnet.sublabel": `Le sous-réseau doit être unique au travers de votre emplacement.`,
  "vlan.subnet.sublabel.existingSubnet": `Voir les sous-réseaux existants`,
  "vlan.subnet.sublabel.creation": ` Par défaut nous vous proposons un sous-réseau disponible.`,
  "vlan.internetAccess.title": `Accès Internet`,
  "vlan.internetAccess.yes.sublabel": `Permet l'accès à Internet.`,
  "vlan.internetAccess.no.sublabel": `N'offre aucun accès Internet.`,
  "vlan.internetAccess.limited": `Limité`,
  "vlan.internetAccess.limited.sublabel": `Offre un accès Internet limité.`,
  "vlan.zoneType": `Type de zone`,
  "vlan.zoneType.PUBLIC.title": `Publique`,
  "vlan.zoneType.PUBLIC.sublabel": `Les clients sont isolé les uns des autres.`,
  "vlan.zoneType.PORTAL.title": `Portail`,
  "vlan.zoneType.PORTAL.sublabel": `L'accès des clients est limité au Wi-Fi portail.`,
  "vlan.zoneType.PRIVATE.title": `Privée`,
  "vlan.zoneType.PRIVATE.sublabel": `Permet aux clients de communiquer entre eux.`,
  "vlan.zoneType.null.sublabel": `Le type de zone permet de savoir si les appareils peuvent communiquer entre eux ou non.`,

  "vlan.AccessportsAllocated": `Ports d'accès alloués`,

  "vlan.updateGeneric.success.content":`La zone <strong>{name}</strong> a été mise à jour.`,
  "vlan.addGeneric.success.content":`La zone <strong>{name}</strong> a été ajoutée à votre emplacement.`,
  "vlan.remove.success":`La zone {name} a été supprimée de votre emplacement.`,

  "vlan.wifis.none": `Aucun Wi-Fi`,
  "vlan.wifis.noneConfigured": `Aucun Wi-Fi configuré`,
  "vlan.wifis.actives": `{countActives, plural,
    =0 {
      Aucun Wi-Fi <strong>actif</strong> sur {count}
    }
    one {
      1 Wi-Fi <strong>actif</strong> sur {count}
    }
    other {
      {countActives} Wi-Fi <strong>actifs</strong> sur {count}
    }
  }`,

  "vlan.configuredPorts": `{count, plural, =0 {Aucun port d'accès configuré} one {Un port d'accès configuré} other {{count} ports d'accès configurés}}`,


  "device.identification": `Identification de l'appareil`,
  "device.identification.placeholder": `Nom, identification ou emplacement spécifique`,
  "device.machineType": `Type de machine`,
  "device.model": `Modèle`,
  "device.editHardware": `Modifier les valeurs matériels (réécriture)`,
  "device.editHardware.systemValue": `Valeur système`,
  "device.editHardware.resetValues": `Remettre les propriétés aux valeurs système`, 
  "device.cpu": `Processeur`,
  "device.cpuModel": `Modèle processeur`,
  "device.cpuDetails": `Détails processeur`,
  "device.cores": `coeur(s)`,
  "device.threads": `fil(s) d'exécution`,
  "device.operatingMode.short": `Mode de config.`,
  "device.configurationTemplate": `Configuration`,
  "device.outOfNetwork": `Équipement comme appareil client dans cet emplacement.`,
  "device.type.BRIDGE": `Point d'accès`,
  "device.type.ROUTER": `Routeur principal`,
  "device.type.DRONE": `Équipement de surveillance`,
  "device.location.BRIDGE": `Nom du point d'accès`,
  "device.location.ROUTER": `Nom du routeur principal`,
  "device.location.DRONE": `Nom`,
  "device.summary.wifiBroadcasted": `{count, plural, =0 {Wi-Fi émis} =1 {Wi-Fi émis} other {Wi-Fi émis}}`,
  "device.summary.wiredConnected": `{count, plural, =0 {Filaire connecté} =1 {1 filaire connecté} other {{count} filaires connectés}}`,
  "device.wifi.enabled.ROUTER": `Expose {enabled} Wi-Fi sur {total}`,
  "device.wifi.enabled.BRIDGE": `Expose {enabled} Wi-Fi sur {total}`,
  "device.wifi.enabled.DRONE": `Expose {enabled} Wi-Fi sur {total}`,
  "device.wifi.exposed": `{count, plural, =0 {Exposé} one {Exposé} other {Exposés}}`,
  "device.wifi.notExposed": `{count, plural, =0 {Non exposé} one {Non exposé} other {Non exposés}}`,
  "device.wifi.none": `Aucun Wi-Fi exposé`,
  "device.wifi.nowifi": `Aucune radio Wi-Fi disponible`,
  "device.wifi.nowifi.short": `Aucune radio`,
  "device.wifi.nowifi.filtered": `Aucun réseau Wi-Fi avec ce filtre.`,
  "device.wifi.nowifi.configured": `Aucun réseau Wi-Fi configuré`,
  "device.wifi.nowifi.broadcasted": `Aucun réseau Wi-Fi émis`,
  "device.wifi.availability": `Émet {countAllSsids} Wi-Fi sur une capacité de {maxSsids}.`,
  "device.wifi.mustSelectOne": `Vous devez sélectionner un équipement au minimum pour émettre ce réseau Wi-Fi.`,
  "device.wifi.signalStrength": `Force du signal`,
  "device.wifi.frequency": `Fréquence`,
  "device.wifi.potentialRogueWifi": `Wi-Fi potentiellement malveillant`,
  "device.offlineSince": `Déconnecté depuis`,
  "device.connectedSince": `Connecté depuis`,
  "device.firstConnection": `Première connexion`,
  "device.activeConnection": `Connexion active`,
  "device.failureConnection": `Échec d'authentification`,
  "device.detectedOnPort": `Détecté dans un port`,
  "device.pastConnections": `Connexion(s) passée(s)`,

  "device.badWiredConnection": `Mauvaises connexions filaires`,
  "device.VerifyPortDetails": `Vérifiez les détails des ports`,
  "device.awaitingRemoval": `Cet équipement est en cours de suppression de cet emplacement due à une demande de remise à l'état d'usine le {date}.`,

  "device.edit": `Éditer l'équipement`,
  "device.inexistant": `L'équipement n'existe pas ou a été supprimé.`,
  "device.alias.label": `Nommer cet appareil`,
  "device.alias.disabled": `Vous ne pouvez pas éditer le nom d'un équipement de l'inventaire`,

  "device.updateGeneric.success.content": `L'équipement a été mis à jour.`,
  "device.block": `Bloquer l'appareil`,
  "device.block.modal.content": `Cette action bloquera tous les accès de cet appareil aux réseaux et ressources de <strong>{organization}</strong>, incluant l'accès à Internet.`,
  "device.block.modal.content3": `Êtes-vous sûr de vouloir bloquer cet appareil?`,
  "device.block.modal.field.label": `Raison du blocage de cet appareil`,
  "device.block.modal.field.placeholder": `Saisir une raison`,

  "device.block.success.content": `L'appareil a été bloqué.`,
  "device.block.error.content": `Une erreur est survenue. L'appareil n'a pas été bloqué.`,
  "device.unblock": `Débloquer l'appareil`,
  "device.unblock.modal.content": `Êtes-vous sûr de vouloir redonner plein accès aux ressources et réseaux de <strong>{organization}</strong>?`,
  "device.unblock.whitelistingOn.modal.message": `
    <p>
      Cet appareil ne peut être débloqué tant que l'ensemble des champs de description requis ne sont pas remplis.
    </p>
  `,
  "device.unblock.whitelistingOn.modal.content": `
    <p>
      Vous pouvez éditer ces propriétés en cliquant sur le bouton d'édition sur cette page.
    </p>
  `,
  "device.unblock.success.content": `L'appareil a été débloqué.`,
  "device.unblock.error.content": `Une erreur est survenue. L'appareil n'a pas été débloqué.`,
  "device.noname": `<Anonyme>`,
  "device.extendedWiFiNetwork": `Réseaux Wi-Fi étendus`,
  "device.configuredWiFiNetwork": `Réseaux Wi-Fi émis`,
  "device.configuredWiFiNetwork.noNetworks": `Cet équipement ne peut émettre aucun réseau Wi-Fi.`,
  "device.configuredWiFiNetwork.maxNetworks": `Cet équipement peut émettre jusqu'à {count, plural, =0 {0 réseau Wi-Fi} =1 {1 réseau Wi-Fi} other {{count} réseaux Wi-Fi}}`,
  "device.configuredWiFiNetwork.maxNetworks.info": `Si un Wi-Fi est <strong>SEULEMENT</strong> émis par cet équipement, il ne peut être retiré de la configuration. {linebreak}Si nécessaire, vous pouvez le supprimer via la page Wi-Fi dans la zone associée à ce réseau Wi-Fi.`,
  "device.ssids.deactivateWithDependencies.message": `
    Vous avez décoché l'émission du réseau Wi-Fi portail <strong>«{ssid}»</strong> par cet équipement.
  `,
  "device.ssids.deactivateWithDependencies.content": `
    <p>
      L'accès
      {count, plural, =0 {au réseau public} one {au réseau public} other {aux réseaux publics}}
      <strong>«{ssids}»</strong>
      {count, plural, =0 {associé} one {associé} other {associés}}
      au portail pourrait s'en trouver limité voire impossible, notamment pour les utilisateurs connectés depuis cette borne.
    </p>
    <p>
    Toutefois, les appareils actuellement connectés à
      {count, plural, =0 {ce réseau public} one {ce réseau public} other {ces réseaux publics}}
      demeureront connectés.
    </p>
  `,

  "device.ssids.deactivateGuest.message": `
    Vous avez décoché l'émission du réseau Wi-Fi public <strong>«{ssid}»</strong> par cet équipement.
  `,
  "device.ssids.deactivateGuest.content": `
    <p>
      Les appareils se connectant au réseau Wi-Fi portail
      <strong>«{parent}»</strong>
      depuis cette borne pourraient ne pas être en mesure de se connecter à votre réseau public
      <strong>«{child}»</strong>.
    </p>
  `,

  "device.ssids.deactivateSsids.message": `
    Vous avez décoché l'émission des réseaux Wi-Fi suivants: <strong>{ssids}</strong> par cet équipement.
  `,
  "device.ssids.deactivateSsids.content": `
    <p>
      Cette action va forcer la déconnexion des appareils connectés à ces réseaux depuis cette borne d'accès.
    </p>
  `,

  "device.status.dataNotLive": `Note: Les données sont mises à jour chaque minute.`,

  "device.statusLegend": `Autres status`,
  "device.statusLegend.certified": `Équipement SentinelC certifié`,
  "device.statusLegend.forbidden": `Appareil interdit`,
  "device.statusLegend.restrictedZone": `Zone restreinte`,
  "device.statusLegend.internalZone": `Zone systeme`,
  "device.statusLegend.unrestrictedZone": `Zone non-restreinte`,
  "device.statusLegend.disabledZone": `Zone désactivée`,

  "device.port.statusLegend": `Légende port`,
  "device.port.ACCESS": `Disponible`,
  "device.port.ACCESS.open": `Non restreint`,
  "device.port.NONE": `Désactivé`,
  get "device.port.DISABLED"() {return this["generic.disabled"]},
  "device.port.TRUNK": `Réservé`,
  "device.port.TRUNK.noTrunk": `Aucun port Accès point sur l'équipement parent`,
  "device.port.WAN": `Réservé`,

  "device.port.zone.noName": `Veuillez saisir un nom de zone`,

  "device.port.zone.ACCESS": `Zone`,
  "device.port.zone.CUSTOM": `Personnalisé`,
  "device.port.zone.DISABLED": `Non configuré`,
  "device.port.zone.NONE": `Désactivé`,
  get "device.port.zone.PASSTHROUGH"() {return this["vlan.mode.passthrough"]},
  "device.port.zone.POD": `Service`,
  "device.port.zone.TRUNK.forChildren": `Réseau`,
  "device.port.zone.TRUNK": `Point d'accès`,
  "device.port.zone.TRUNK.forParent": `Point d'accès`,
  "device.port.zone.WAN": `Internet`,

  "device.port.modal.ROUTER": `
    <p>
      Veuillez noter que les modifications effectuées vont provoquer une reconfiguration complète de votre routeur principal <strong>{device}</strong>.
    </p>
  `,
  "device.port.modal.DRONE": `
    <p>
      Veuillez noter que les modifications effectuées vont provoquer une reconfiguration complète de votre équipement de surveillance <strong>{device}</strong>.
    </p>
  `,
  "device.port.modal.BRIDGE": `
    <p>
      Veuillez noter que les modifications effectuées vont provoquer une reconfiguration complète de votre point d’accès <strong>{device}</strong>.
    </p>
  `,
  "device.port.modal.generic": `
    <p>
      De plus, cette situation va entraîner la déconnexion temporaire de tous les appareils connectés à cet équipement.
    </p>
    <p>
      Assurez-vous que vos branchements correspondent aux changements de configuration des ports d'accès physiques.
    </p>
  `,
  "device.port.modal.alert.accessPoint1": `
    <p>
      {count, plural,
        =0 {Vous n'avez modifié aucun port de type <strong>Point d'accès</strong> connecté à un appareil.}
        one {Vous avez modifié un port de type <strong>Point d'accès</strong> dans lequel est branché l'équipement:}
        other {Vous avez modifié plusieurs ports de type <strong>Point d'accès</strong> dans lesquels sont branchés les équipements suivants:}
      }
    </p>
  `,
  "device.port.modal.alert.accessPoint2": `
    <p>
      {count, plural,
        =0 {}
        one {Cette opération pourrait faire en sorte que ce point d'accès devienne inopérant.}
        other{Cette opération pourrait faire en sorte que les points d'accès deviennent inopérants.}
      }
    </p>
  `,

  "device.unappliedChanges":`{count, plural,
    =0 {Aucune modification en attente}
    one {Vous avez une modification de configuration en attente}
    other {Vous avez {count} modifications de configuration en attente}
  }`,
  "device.unappliedChanges.onDevice": `{count, plural, =0 {Aucun équipement à modifier} one {Ce changement sera appliqué sur l'équipement:} other {Ce changement sera appliqué sur les équipements:}}`,
  "device.unappliedChanges.info": `Les mises à jour seront appliquées dès que votre équipement sera disponible ou en ligne.`,
  "device.unappliedChanges.DEVICE_ADD": `Configuration initiale de l'équipement <em>{name}</em>.`,
  "device.unappliedChanges.DEVICE_REPLACE": `La configuration de l'équipement <em>{name_old}</em> a été transferée sur <em>{name_new}</em>.`,
  "device.unappliedChanges.VLAN_ADD": `La zone <em>{name}</em> a été créée.`,
  "device.unappliedChanges.VLAN_UPDATE": `La zone <em>{name}</em> a été modifiée.`,
  "device.unappliedChanges.VLAN_DELETE": `La zone <em>{name}</em> a été supprimée.`,
  "device.unappliedChanges.SSID_ADD": `Le réseau Wi-Fi <em>{name}</em> a été configuré.`,
  "device.unappliedChanges.SSID_DEL": `Le réseau Wi-Fi <em>{name}</em> a été supprimé.`,
  "device.unappliedChanges.SSID_UPDATE_SCHEDULE": `Les horaires du réseau Wi-Fi <em>{name}</em> ont été modifiés.`,
  "device.unappliedChanges.SSID_UPDATE_NAME": `Le nom du réseau Wi-Fi <em>{previous_name}</em> a été modifié pour <em>{new_name}</em>`,
  "device.unappliedChanges.SSID_UPDATE_PSK": `La clé du réseau Wi-Fi <em>{name}</em> a été modifiée.`,
  "device.unappliedChanges.SSID_ENABLE": `Le réseau Wi-Fi <em>{name}</em> a été démarré.`,
  "device.unappliedChanges.SSID_DISABLE": `Le réseau Wi-Fi <em>{name}</em> a été arrêté.`,
  "device.unappliedChanges.CHANGE_OPERATING_MODE": `Reconfiguration de l'équipement <strong>{name}</strong>.`,
  "device.unappliedChanges.WHITELIST_UPDATE": `L'accès d'un appareil à une zone restreinte a été modifié.`,
  "device.unappliedChanges.NETWORK_UPDATE": `La configuration réseau de l'emplacement a été modifiée.`,
  "device.unappliedChanges.FACTORY_RESET": `Remise à l'état d'usine de l'équipement <strong>{name}</strong>.`,
  "device.unappliedChanges.CHANGE_PORT_CONFIG": `{count, plural,
    =0 {Aucune modification des ports filaires}
    one {Changement de configuration du port filaire {ports}.}
    other {Changement de configuration des ports filaires ({ports}).}
  }`,
  "device.unappliedChanges.CHANNEL_AUTO_SELECTION": `
    Une modification de canal a été fait sur la radio {radio} ({frequency} GHz) de l'équipement <strong>{name}</strong> pour améliorer les performances.
    {linebreak}
    - Canal précédent: {oldChannelID, plural, =-1 {Aucun} other {{oldChannelID} - largeur de bande: {oldChannelBandwidth} MHz}}
    {linebreak}
    - Nouveau canal: {newChannelID, plural, =-1 {Aucun} other {{newChannelID} - largeur de bande: {newChannelBandwidth} MHz}}
  `,

  "device.replace": `Cliquez sur confirmer pour finaliser le remplacement.`,
  "device.replace.noWarning": `Le nouvel équipement est pleinement compatible avec votre ancien routeur. La configuration sera copiée vers votre nouveau routeur.`,
  "device.replace.warning.intro": `Le ou les éléments de configuration suivant(s) ne seront pas migrés vers votre nouvel équipement en raison de limites matérielles:`,
  "device.replace.success": `L'équipement a été remplacé avec succès.`,
  "device.update.uptodate": `À jour`,
  "device.update.outtodate": `Obsolète`,
  "device.update.success": `L'équipement <strong>{location}</strong> a été mis à jour.`,
  "device.reboot": `Cette action va redémarrer l'équipement avec la configuration actuelle. Tous les utilisateurs vont être déconnectés et devront se reconnecter.`,
  "device.reboot.modal.title": `Redémarrer l'équipement {name}.`,
  "device.reboot.success": `Redémarrage de l'équipement en cours.`,
  "device.reboot.forbidden": `Vous n'êtes pas autorisé à faire cette action.`,
  "device.reboot.failed": `Le redémarrage a échoué, votre équipement est peut-être hors-ligne ou déjà en cours de redémarrage.`,
  "device.remove.note": `
    <p>Êtes-vous sûr de vouloir retirer cet équipement ?</p>
  `,
  "device.remove.BRIDGE": `Votre point d'accès <strong>{location}</strong> a été retiré avec succès.`,
  "device.remove.BRIDGE.content": `<p>Cette action retirera le point d'accès <strong>{location}</strong> de votre configuration, et supprimer l'extension des réseaux Wi-Fi créée par cet appareil. Votre appareil sera également réinitialisé aux paramètres d'usine.</p>`,

  "device.remove.DRONE": `Votre équipement de surveillance <strong>{location}</strong> a été retiré avec succès.`,
  "device.remove.DRONE.content": `<p>Cette action retirera l'équipement de surveillance <strong>{location}</strong> de votre configuration. Votre appareil sera également réinitialisé aux paramètres d'usine.</p>`,

  "device.remove.ROUTER": `Votre routeur principal <strong>{location}</strong> a été retiré avec succès.`,
  "device.remove.ROUTER.hasChildren.content": `
    <strong>ATTENTION!</strong>
    <p>
      <strong>Cet équipement est un routeur principal et ne peut être retiré</strong> tant que d'autres équipements y sont reliés, par exemple des points d'accès ou d'autres équipements SentinelC.
    </p>
    <p>
      Vous devez dans un premier temps retirer tous les autres équipements un par un avant de retirer votre routeur principal.
    </p>
  `,
  "device.remove.ROUTER.content": `
    <p>
      Cette action enverra une tâche de <strong>Remise à l'état d'usine</strong> à l'équipement. Lorsque la tâche se complètera avec succès, l'équipement sera retiré de cet emplacement.
    </p>    
    <p>
      L'emplacement, incluant toutes ses configurations réseaux et son historique sera conservé. Vous pourrez le retirer par la suite en utilisant l'opération <strong>Supprimer l'emplacement</strong>.
    </p>
    <p>
      L'équipement va demeurer réclamé dans votre compte et pourra être ré-ajouté dans cet emplacement ou un autre. Utilisez l'opération <strong>Retour au fournisseur</strong> pour le retourner.
    </p>
  `,

  "device.whitelisting.isUnknownAP": `
    Cet appareil est un équipement réseau SentinelC inconnu.{linebreak}
    Cliquez sur Gérer pour l'ajouter à votre réseau.
  `,
  "device.whitelisting.isExternalAPOnTrunk": `
    <strong>Cet équipement réseau SentinelC n'est pas autorisé dans votre réseau.</strong>{linebreak}
    Cliquez sur <strong>Ignorer</strong> et débranchez cet équipement.
  `,
  "device.whitelisting.isInternalAPOutOfTrunk": `
    <strong>Cet appareil est un de vos point d'accès SentinelC.</strong>{linebreak}
    Cliquez sur <strong>Ignorer</strong> et branchez l'équipement dans un <strong>port Point d'accès</strong> ({ports}) disponible.
  `,
  "device.whitelisting.isExternalAPOutOfTrunk": `
    <strong>Cet appareil est un équipement réseau SentinelC.</strong>{linebreak}
    Si vous tentez d'installer un Point d'accès (AP), cliquez sur <strong>Ignorer</strong> et branchez l'équipement dans un <strong>port Point d'accès</strong> ({ports}).{linebreak}
    Autrement, cliquez sur <strong>Autoriser</strong>.
  `,
  "device.whitelisting.isExternalAPOutOfTrunk.form.warning": `
   Si vous tentez d'ajouter ce <strong>Point d'accès</strong> à ce réseau, <strong>annulez</strong> cette opération et branchez l'équipement dans un port de type <strong>Point d'accès</strong>.
  `,
  "device.whitelisting.isExternalAPOutOfTrunk.form.text": `
    En autorisant cet équipement, il sera reconnu en tant que routeur principal indépendant, qui peut être associé à votre compte SentinelC ou à un autre.
  `,
  "device.whitelisting.isHostInTrunk": `
    Cet appareil <strong>n'est pas un équipement réseau SentinelC</strong>.{linebreak}
    Il ne peut être autorisé dans un port de type Point d'accès.{linebreak}
    Cliquez sur <strong>Ignorer</strong>.
  `,
  "device.whitelisting.deletedWifi": `Le réseau Wi-Fi auquel cet appareil a essayé de se connecter n'existe plus. Cliquez sur <strong>Gérer</strong> si vous souhaitez malgré tout l'autoriser sur la zone <strong>{zone}</strong>. Sinon cliquez sur <strong>Ignorer</strong>`,
  "device.whitelisting.deletedWifi.short": `Le réseau Wi-Fi auquel cet appareil a essayé de se connecter n'existe plus.`,

  "device.isAPOutOfTrunk": `
    <strong>Vérification de la connexion avec le routeur principal en cours...</strong>{linebreak}
    Vérifiez que votre appareil est branché dans un port de type <strong>POINT D'ACCÈS</strong> pour assurer son fonctionnement.
  `,

  "device.tile.isAPOutOfTrunk": `
    Vérification de la connexion avec le routeur principal en cours...
  `,

  "device.whitelisting.connexionAttempt": `{count, plural, =0 {Aucune tentative de connexion à un réseau existant} one {Tentative de connexion à un réseau} other {Tentative de connexion à {count} réseaux}}`,
  "device.whitelisting.modal.title": `Bloquer l'accès`,

  "device.updateFirmware": `Mise à jour des équipements`,
  "device.updateFirmware.content": `L'horaire de mise à jour des équipements défini le moment auquel une mise à jour logicielle peut être installée. L'installation stoppe temporairement le réseau et les Wi-Fi configurés (moins de 2 minutes).`,
  "device.updateFirmware.success": `Mise à jour des équipements en cours.`,
  "device.updateFirmware.error": `Échec de la mise à jour des équipements.`,
  "device.updateFirmware.forbidden": `Vous n'êtes pas autorisé à faire cette action.`,

  "device.contact.useOrganizationContact": `Utiliser le contact du compte SentinelC`,
  "device.contact.title": `Mise à jour du contact`,
  "device.contact.content": `Utiliser le contact du compte SentinelC supprimera les champs de contact actuel.`,

  "device.replaceRouter.title": `Remplacer le routeur principal`,
  "device.replaceRouter.content": `Cette option vous permet de remplace votre routeur principal sans perte de configuration ou de donnée en cas de problème matériel.`,
  "device.replaceRouter.validDevice.message": `Vous êtes sur le point de remplacer votre routeur principal <strong>{actualDevice}</strong> par un nouvel appareil nommé <strong>{newDevice}</strong>.`,
  "device.replaceRouter.validDevice.content": `Votre configuration actuelle sera transposée sur votre nouvel appareil. Toutes les données acquises par le routeur seront sauvegardées et disponibles dans le menu Activité.`,
  "device.replaceRouter.renameLocation.info": `Vous pouvez, si vous le désirez, modifier l'identification de l'appareil.`,
  "device.replaceRouter.renameLocation.label": `Identification du nouvel appareil`,
  "device.replaceRouter.complete.message": `Veuillez noter que le transfert de configuration a été programmé et peut prendre une minute pour terminer dans la mesure où le nouveau routeur maître est branché.`,
  "device.replaceRouter.complete.content": `
    <p>
      Si votre ancien routeur maître est toujours en ligne, vous pouvez alors débrancher cet équipement et brancher le nouveau routeur s'il n'est pas déjà branché.
    </p>
    <p>
      Vous devez aussi rebrancher tous les appareils filaires qui étaient branchés dans l'ancien router maître.
    </p>
    <p>
       Si les limites matérielles font en sorte que tous les anciens branchements filaires ne peuvent être effectués sur le nouveau routeur maître, vous devrez alors identifier sur quel équipement SentinelC ces branchements pourront être rebranchés, à moins que ces branchements ne soient plus requis.
    </p>
  `,

  "devices.title": `Appareils`,
  "devices.unauthorized.title": `Appareils non-autorisés`,
  "devices.manage": `Gérer les appareils`,
  "devices.last.connection": `Dernière connexion`,
  "devices.last.attempt.deletedWifi": `Dernière tentative le {date} sur l'emplacement <strong>{location}</strong> sur la zone retreinte <strong>{zone}</strong>`,
  "devices.search.placeholder": `Entrer le nom d'un appareil (3 caractères min.)`,
  "devices.search.notfound": `Aucun appareil trouvé`,
  "devices.online": `{count, plural, =0 {Aucun appareil connecté} one {1 appareil connecté} other {{count} appareils connectés}}`,
  "devices.onlineLong": `{count, plural, =0 {Aucun appareil n'est actuellement connecté} one {1 appareil est actuellement connecté} other {{count} appareils sont actuellement connectés}}`,
  "devices.waiting": `{count, plural, =0 {Aucun appareil en attente d'autorisation} one {1 appareil en attente d'autorisation} other {{count} appareils en attente d'autorisation}}`,
  "devices.waitingLong": `{count, plural, =0 {Aucun appareil n'est actuellement en attente d'autorisation} one {1 appareil est actuellement en attente d'autorisation} other {{count} appareils sont actuellement en attente d'autorisation}}`,
  "devices.blocked": `{count, plural, =0 {Aucun appareil bloqué} one {1 appareil bloqué} other {{count} appareils bloqués}}`,
  "devices.blockedLong": `{count, plural, =0 {Aucun appareil n'est actuellement bloqué} one {1 appareil est actuellement bloqué} other {{count} appareils sont actuellement bloqués}}`,
  "devices.used.by": `Utilisé par`,
  "devices.online.static": `Appareils connectés`,

  "devices.whitelisting.needAuthorization.transitionOff": `{count, plural,
    =0 {
      Aucun appareil non-autorisé
    } one {
      1 appareil non-autorisé
    } other {
      {count} appareils non-autorisés
  }}`,
  "devices.whitelisting.message.newasking.transitionOff": `{count, plural,
    =0 {
      Aucun appareil ne requiert la permission d'accéder à votre réseau.
    } one {
      1 appareil requiert la permission d'accéder à votre réseau.
    } other {
      {count} appareils requièrent la permission d'accéder à votre réseau.
  }}`,

  "devices.unauthorizedDevice.message.newasking.transitionOff": `{count, plural,
    =0 {
      Aucun appareil ne requiert la permission d'accéder à votre réseau.
    } one {
      Vous devez donner la permission à cet appareil pour se connecter à votre réseau..
    } other {
      Vous devez donner la permission à ces appareils pour se connecter à votre réseau.
  }}`,

  "surveillance.title": `Surveillance`,

  "surveillance.task.cancel": `Annuler cette tâche`,
  "surveillance.task.cancelled.by": `Annulée par`,
  "surveillance.task.cancelled.on": `Annulée le`,
  "surveillance.task.designatedAppliance": `Équipement`,
  "surveillance.task.parameters": `Paramètres de la tâche`,
  "surveillance.task.results": `Résultats de la tâche`,
  "surveillance.task.results.context": `Contexte de l'équipement à l'heure d'exécution - version du micrologiciel`,

  "surveillance.tasks.title": `Tâches`,
  "surveillance.tasks.title.forAYear": `Volume de tâches des 12 derniers mois`,
  "surveillance.tasks.emptyMessage": `Aucune tâche avec le statut "En attente" ou "En cours"`,
  "surveillance.tasks.none": `Aucune tâche`,
  "surveillance.tasks.none.filtered": `Aucune tâche ne correspond aux critères sélectionnés.`,

  "surveillance.ssids.none": `Aucun réseau Wi-Fi`,
  "surveillance.ssids.none.filtered": `Aucune réseau Wi-Fi ne correspond aux critères sélectionnés.`,

  "surveillance.wifiSurveys.title": `Scan Wi-Fi`,
  "surveillance.wifiSurveys.newWifiSurvey": `Nouveau scan Wi-Fi`,
  "surveillance.wifiSurveys.none": `Aucun scan Wi-Fi`,
  "surveillance.wifiSurveys.none.filtered": `Aucun scan Wi-Fi ne correspond aux critères sélectionnés.`,
  "surveillance.wifiSurveys.requestedTime": `Scan effectué le`,
  "surveillance.wifiSurveys.endTime": `Date de fin`,
  "surveillance.wifiSurveys.ssidReported": `BSSID détectés`,
  "surveillance.wifiSurveys.potentialRogue": `Potentiellement malveillants`,
  "surveillance.wifiSurveys.potentialRogue.linkTo": `Voir le rapport`,

  "surveillance.rogueWifis.title": `Wi-Fi potentiellement malveillants`,
  "surveillance.rogueWifis.bssid": `Bssid`,

  "surveillance.addWifiSurveys.title": `Formulaire de scan Wi-Fi`,
  "surveillance.addWifiSurveys.subtitle": `Veuillez noter qu’il est possible de pré-populer ce Formulaire en cochant un équipement à partir du menu Équipements du tableau de bord administratif et en sélectionnant l'action <strong>Démarrer une surveillance Wi-Fi</strong>.`,
  "surveillance.addWifiSurvey.message.wifiSurveyStart": `Un nouveau scan Wi-Fi a été créé avec succès.`,

  "surveillance.releaseChannels.title": `# Équipement par flux de version`,
  "surveillance.releaseChannels.legend.upd": `À jour`,
  "surveillance.releaseChannels.legend.otd": `Obsolète`,

  "surveillance.ips.statsWeekly": `# Événements IP pour les derniers 7 jours`,
  "surveillance.ips.statsWeekly.length": `{count, plural, =0 {aucun événement IP} one {1 événement IP} other {{count} événements IP}}`,
  "surveillance.ips.statsWeekly.none": `Aucun événement d'IP pendant les derniers 7 jours`,
  "surveillance.dns.statsWeekly": `# Requêtes DNS pour les derniers 7 jours`,
  "surveillance.dns.statsWeekly.length": `{count, plural, =0 {aucune requête} one {1 requête} other {{count} requêtes}}`,

  "surveillance.dns.title": `Journaux DNS`,
  "surveillance.dns.title.mostRecent": `Requêtes DNS les plus récentes`,
  "surveillance.dns.title.subtitle": `Résumé des requêtes par adresse MAC`,
  "surveillance.dns.filtered.noRequest": `Aucune requête DNS ne correspond aux critères sélectionnés.`,
  "surveillance.dns.noRequest": `Aucune requête DNS.`,
  "surveillance.dns.stats.desc": `Statistiques agrégées de toutes les requêtes DNS faites par appareil.`,
  "surveillance.dns.ranking": `Liste`,
  "surveillance.dns.ranking.desc": `Liste des requêtes DNS par nom de domaine et MAC adresses client.`,
  "surveillance.dns.requests": `Requêtes`,
  "surveillance.dns.requests.desc": `Requêtes réelles faites par MAC addresse des appareils clients.`,
  "surveillance.dns.requests.type": `Type de requête`,
  "surveillance.dns.requests.code": `code de réponse`,
  "surveillance.dns.requests.summary": `Résumé de la réponse`,
  "surveillance.dns.queryType.unsupported": `Type non supporté`,
  "surveillance.dns.queryType.error": `Valeur invalide`,
  "surveillance.dns.domainName": `Nom de domaine`,
  "surveillance.dns.nbOfRequest": `# de requêtes`,
  "surveillance.dns.filters.period.subtitle": `Un requête a été faite dans la période sélectionnée entre vu la première et la dernière fois.`,
  "surveillance.dns.filters.domain": `Domaine`,
  "surveillance.dns.filters.domain.filterBy": `Par niveau du domaine`,
  "surveillance.dns.filters.domain.highestLevel": `Domaine haut niveau`,
  "surveillance.dns.filters.domain.requestedName": `Domaine exact demandé`,
  "surveillance.dns.filters.responseCodes": `Code de réponse`,
  "surveillance.dns.filters.responseCodes.filterBy": `Par code de réponse`,
  "surveillance.dns.filters.responseCodes.NOERROR": "La requête DNS s'est terminée avec succès",
  "surveillance.dns.filters.responseCodes.FORMERR": "Erreur de format de requête DNS",
  "surveillance.dns.filters.responseCodes.SERVFAIL": "Le serveur n'a pas réussi à compléter la demande DNS",
  "surveillance.dns.filters.responseCodes.NXDOMAIN": "Le nom de domaine n'existe pas",
  "surveillance.dns.filters.responseCodes.NOTIMPL": "Fonction non implémentée",
  "surveillance.dns.filters.responseCodes.REFUSED": "Le serveur a refusé de répondre à la requête",
  "surveillance.dns.filters.responseCodes.YXDOMAIN": "Un nom qui ne devrait pas exister existe",
  "surveillance.dns.filters.responseCodes.XRRSET": "Un ensemble d'enregistrements qui ne devrait pas exister, existe",
  "surveillance.dns.filters.responseCodes.NOTAUTH": "Serveur non autoritaire pour la zone",
  "surveillance.dns.filters.responseCodes.NOTZONE": "Nom non présent dans la zone",
  "surveillance.dns.filters.queryTypes": `Type de requête`,
  "surveillance.dns.filters.queryTypes.filterBy": `Par type de requête`,
  "surveillance.dns.filters.queryTypes.A": `<strong>Enregistrement Adresse :</strong>{linebreak} Traduit les noms de domaine en adresses IPv4. Exemple : Les navigateurs Web tels que Chrome ou Firefox effectuent des requêtes d'enregistrement A pour résoudre les noms de domaine en adresses IP.`,
  "surveillance.dns.filters.queryTypes.AAAA": `<strong>Enregistrement d'adresse IPv6 :</strong>{linebreak} Résout les noms de domaine en adresses IPv6. Exemple : Les navigateurs Web modernes et les systèmes d'exploitation effectuent des requêtes d'enregistrement AAAA pour les sites Web prenant en charge IPv6.`,
  "surveillance.dns.filters.queryTypes.CNAME": `<strong>Enregistrement de nom canonique :</strong>{linebreak} Fréquemment utilisé pour les alias, en faisant correspondre un domaine à un autre.`,
  "surveillance.dns.filters.queryTypes.HTTPS": `<strong>Type de requête DNS HTTPS (HTTP sécurisé) :</strong>{linebreak} Indique le support de la communication sécurisée sur HTTP. Exemple : Les navigateurs et applications, comme Chrome ou Firefox, utilisent des requêtes DNS HTTPS pour déterminer si un domaine prend en charge les connexions chiffrées via HTTPS, permettant la transmission sécurisée des données entre le client et le serveur.`,
  "surveillance.dns.filters.queryTypes.NS": `<strong>Enregistrement de serveur de noms :</strong>{linebreak} Identifie les serveurs DNS autoritaires pour un domaine. Exemple : Les résolveurs DNS interrogent les enregistrements NS pour localiser les serveurs de noms autoritaires pour un domaine donné.`,
  "surveillance.dns.filters.queryTypes.PTR": `<strong>Enregistrement de pointeur :</strong>{linebreak} Résout une adresse IP en un nom de domaine. Exemple : Les recherches DNS inverses utilisent des enregistrements PTR pour associer une adresse IP à un domaine, souvent utilisé dans l'authentification des serveurs de messagerie électronique.`,
  "surveillance.dns.filters.queryTypes.SRV": `<strong>Enregistrement de service :</strong>{linebreak} Spécifie des informations sur les services disponibles dans le réseau. Exemple : Utilisé par des applications telles que la VoIP ou les clients de messagerie instantanée pour découvrir les emplacements de service dans le domaine.`,
  "surveillance.dns.filters.queryTypes.TXT": `<strong>Enregistrement de texte :</strong>{linebreak} Transporte des informations lisibles par l'homme et est souvent utilisé à diverses fins. Exemple : Les enregistrements SPF (Sender Policy Framework) au format TXT dans le DNS aident à prévenir le détournement d'e-mails.`,
  "surveillance.dns.filters.queryTypes.MX": `<strong>Enregistrement d'échange de courrier :</strong>{linebreak} Spécifie les serveurs de messagerie pour un domaine. Exemple : Les serveurs de messagerie utilisent des enregistrements MX pour déterminer où livrer les e-mails pour un domaine particulier.`,
  "surveillance.dns.filters.queryTypes.DNSKEY": `<strong>Enregistrement de clé DNS :</strong>{linebreak} Stocke des clés cryptographiques pour les extensions de sécurité DNS (DNSSEC). Exemple : Les résolveurs DNS utilisent des enregistrements DNSKEY pour valider l'authenticité des données DNS via DNSSEC.`,
  "surveillance.dns.filters.queryTypes.SOA": `<strong>Enregistrement de début d'autorité :</strong>{linebreak} Contient des informations administratives sur le nom de domaine, notamment le serveur DNS principal, l'e-mail de l'administrateur du domaine, le numéro de série du domaine et des minuteries pour actualiser la zone. Exemple : L'enregistrement SOA est crucial pour maintenir l'intégrité de la zone DNS et coordonner les mises à jour entre les serveurs DNS..`,
  "surveillance.dns.filters.queryTypes.SVCB": `<strong>Enregistrement de liaison de service :</strong>{linebreak} Fournit des informations supplémentaires sur les services offerts par un domaine, améliorant les performances et la sécurité Web. Exemple : Les navigateurs tels que Chrome peuvent utiliser des enregistrements SVCB pour optimiser l'établissement de la connexion et la récupération des ressources.`,

  "surveillance.ports.title": `Ports sous surveillance`,
  "surveillance.ports.desc": `Cette liste affiche les ports qui sont ou ont été ouverts par la passé.`,
  "surveillance.ports.lastScan": `Dernier scan`,
  "surveillance.ports.lastScanOn": `Date du dernier scan`,
  "surveillance.ports.scanOn": `Date du scan`,
  "surveillance.ports.state.message": `Lors du dernier scan du port.`,
  "surveillance.hostPorts.history": `Historique détaillé du port`,
  "surveillance.hostPorts.history.return": `Déselectionner ce port`,
  "surveillance.hostPorts.none": `Aucun port scanné`,
  "surveillance.hostPorts.none.filtered": `Aucun port scanné ne correspond aux critères sélectionnés.`,

  "surveillance.portScans.title": `Scans des ports`,
  "surveillance.portScans.title.project": `Scans des ports (pour ce projet seulement)`,
  "surveillance.portScans.hostIsUp": `Statut de l'appareil`,
  "surveillance.portScans.button.disabled": `Impossible de faire un scan de ports sur cet appareil.`,
  "surveillance.portScans.none": `Aucun scan des ports`,
  "surveillance.portScans.none.filtered": `Aucun scan des ports ne correspond aux critères sélectionnés.`,
  "surveillance.portScans.newPortScan": `Nouveau scan des ports`,
  "surveillance.portScans.capturedBy": `Capturé par`,
  "surveillance.portScans.openPorts": `Ports ouverts`,
  "surveillance.portScans.nbOpenPort": `# port(s) ouvert(s)`,
  "surveillance.portScans.requestedTime": `Heure d'exécution`,
  "surveillance.portScans.osDetectionReport": `Rapport de détection du système d'exploitation`,
  "surveillance.portScans.osDetectionReport.noResult": `L'appareil a répondu, mais aucune information sur son système d'exploitation n'a pu être détectée.`,
  "surveillance.portScans.countMonitoredPorts": `Port(s) sous surveillance`,
  "surveillance.portScans.closedPortsReport": `Rapport de(s) port(s) fermé(s)`,
  "surveillance.portScans.openPortsReport": `Rapport de(s) port(s) d'intérêts`,
  "surveillance.portScans.requester": `Requérant`,
  "surveillance.portScans.settings": `Paramètres du scan`,
  "surveillance.portScans.settings.tcp": `Scan de ports TCP`,
  "surveillance.portScans.settings.udp": `Scan de ports UDP`,
  "surveillance.portScans.osDetection": `Détection de l'OS`,
  "surveillance.portScans.tcp.CUSTOM": `Scan personnalisé du port TCP`,
  "surveillance.portScans.tcp.TOP_100": `Top 100 ports TCP`,
  "surveillance.portScans.tcp.TOP_1000": `Top 1000 ports TCP`,
  "surveillance.portScans.tcp.ALL": `Tous les ports TCP`,
  "surveillance.portScans.udp.CUSTOM": `Scan personnalisé du port UDP`,
  "surveillance.portScans.udp.TOP_100": `Top 100 ports UDP`,
  "surveillance.portScans.udp.TOP_1000": `Top 1000 ports UDP`,
  "surveillance.portScans.udp.ALL": `Tous les ports UDP`,
  "surveillance.portScans.viewPorts": `{count, plural, =0 {Aucun port} one {Voir le port} other {Voir les {count} ports}}`,
  "surveillance.portScans.reschedulte": `Replanifier cette tâche`,
  "surveillance.portScans.productNVersion": `Produit / version`,
  "surveillance.portScans.accuracy": `% Précision`,
  "surveillance.portScans.cpe": `Énumération des plateformes communes (CPE)`,
  "surveillance.portScans.osFamily": `Famille du système d'exploitation`,
  "surveillance.portScans.osGeneration": `Génération du système d'exploitation`,

  "surveillance.addPortScans.title": `Nouveau scan des ports`,
  "surveillance.addPortScans.subtitle": `Veuillez noter qu’il est possible de charger automatiquement les données de ce formulaires à partir d'un appareil dans le page <strong>Appareils</strong>.`,
  "surveillance.addPortScan.message.startPortScan": `Le scan de port a été créé avec succès.`,
  "surveillance.addPortScan.message.startPortScanAgain": `Le scan de port a été replanifié avec succès.`,
  "surveillance.addPortScan.message.portScanResultBulk": `Le scan des ports a été replanifié avec succès.`,

  "surveillance.packetCaptures.title": `Captures de paquets`,
  "surveillance.packetCaptures.title.project": `Captures de paquets (pour ce projet seulement)`,
  "surveillance.packetCaptures.none": `Aucune capture de paquets`,
  "surveillance.packetCaptures.none.filtered": `Aucune capture de paquets ne correspond aux critères sélectionnés.`,
  "surveillance.packetCaptures.newPacketCapture": `Nouvelle capture de paquets`,
  "surveillance.packetCaptures.capturedBy": `Capturé par`,
  "surveillance.packetCaptures.requester": `Requérant`,
  "surveillance.packetCaptures.requestedTime": `Heure d'exécution`,
  "surveillance.packetCaptures.cancelTime": `Annulée le`,
  "surveillance.packetCaptures.requestedDuration": `Durée de la requête (sec.)`,
  "surveillance.packetCaptures.nbMaxPackets": `Nombre de paquets requis`,
  "surveillance.packetCaptures.nbPackets": `Nombre de paquets capturés`,
  "surveillance.packetCaptures.appliedFilters": `Filtres appliqués`,
  "surveillance.packetCaptures.reschedulte": `Replanifier cette tâche`,
  "surveillance.packetCaptures.capturePackets": `Capturer les paquets`,
  "surveillance.packetCaptures.capturePackets.disabled": `Capture impossible. Le compte, l'emplacement, l'équipement, ou la zone n'existe plus.`,
  "surveillance.packetCaptures.capturePackets.disabledforInternalZone": `Impossible de faire une capture de la zone interne.`,

  "surveillance.addPacketCaptures.title": `Formulaire de capture de paquets`,
  "surveillance.addPacketCaptures.subtitle": `Veuillez noter qu’il est possible de charger automatiquement les données de ce formulaires à partir des menus <strong>Appareils</strong> et <strong>Connexions</strong>.`,
  "surveillance.addPacketCaptures.tooMuchResultSearch": `Trop de résultats, veuillez saisir des caractères`,
  "surveillance.addPacketCaptures.durationTime": `Durée (sec.)`,
  "surveillance.addPacketCaptures.durationTime.subtitle": `max. 24h`,
  "surveillance.addPacketCaptures.nbPackets.subtitle": `max. 100000`,
  "surveillance.addPacketCaptures.filter.label": `Filtre de capture Wireshark`,
  "surveillance.addPacketCaptures.filter.placeholder": `Ex.: host 192.168.8.15`,
  "surveillance.addPacketCaptures.filter.link": `Voir la documentation Wireshark pour remplir ce champ`,
  "surveillance.addPacketCaptures.message.updateCapture": `La capture de paquets a été modifiée avec succès.`, 
  "surveillance.addPacketCaptures.message.startCapture": `Une nouvelle capture de paquet a été créée avec succès.`,
  "surveillance.addPacketCaptures.message.startCaptureAgain": `Une nouvelle capture de paquet a été replanifiée avec succès.`,
  "surveillance.addPacketCaptures.message.bulkAction": `Annulation opérée avec succès.`, 
  "surveillance.addPacketCaptures.radios.vlan.subtitle": `Liste uniquement les zones en utilisation sur cet équipement.`,
  "surveillance.addPacketCaptures.radios.port.subtitle": `Liste uniquement les ports qui sont actuellement configurés (excluant les ports disponibles).`,

  "incidents.title": `Incidents`,
  "incident.summary": `Résumé de l'incident`,
  "incident.task": `Tâche associée`,
  "incident.toDoc": `Pour en savoir plus sur ce type d'incident et comment le résoudre vous pouvez consulter la`,
  "incident.toDocLink": `documentation associée`,
  "incident.category": `Catégorie d'incident`,
  "incident.supportNotes": `Notes de support`,
  "incident.supportNotes.add": `Ajouter une note`,
  "incident.supportNotes.add.placeholder": `Note pour la personne en charge de l'incident.`,
  "incident.note.updatedOnBy": `Mis à jour le {date} par {name}`,
  "incident.inexistant": `L'incident n'existe pas ou a été supprimé.`,
  "incident.update.success":`Incident mis à jour avec succès.`,
  "incident.note.add.success":`Note ajoutée.`,
  "incident.note.update.success":`Note modifiée.`,
  "incident.note.remove.success":`Note supprimée.`,

  "network.inexistant": `Le réseau demandé a été supprimé, n'existe pas, ou est en cours de suppression.`,
  "network.activity": `Activité du Wi-Fi`,
  "network.PARTIALLYONLINE": `Partiellement en ligne`,
  "network.OFFLINEDEVICES": `Tous les équipements émetteurs sont hors-ligne.`,
  "network.NO_AVAILABLE_RADIO": `Aucun des équipements émetteurs n'a d'émetteur Wi-Fi activé ou installé.`,
  "network.OFFLINE_NO_RADIO": `Certains équipements émetteurs sont hors-ligne et les autres n'ont pas de radios émettant.`,
  "network.broadcastedOn": `Émis par`,
  "network.edit": `Éditer le Wi-Fi`,
  "network.updateStatus.stop.success.content": `Le réseau Wi-Fi <strong>{network}</strong> a été stoppé avec succès!`,
  "network.updateStatus.start.success.content": `Le réseau Wi-Fi <strong>{network}</strong> a été démarré avec succès!`,
  "network.updateGeneric.success.content": `La configuration du réseau Wi-Fi <strong>{network}</strong> a été mise à jour avec succès!`,
  "network.updateGeneric.pending.content": `L'équipement' est actuellement hors-ligne. {linebreak}La mise à jour lui sera envoyée une fois qu'il sera revenu en ligne!`,
  "network.addGeneric.success.content": `Le réseau Wi-Fi <strong>{network}</strong> a été ajouté avec succès!`,
  "network.addGuest.success.content": `Le réseau Wi-Fi Invité <strong>{network}</strong> ajouté avec succès!`,
  "network.addGuestAndPortal.success.content": `Les réseaux Wi-Fi Invité <strong>{network}</strong> et Portail <strong>{portalnetwork}</strong> ajoutés avec succès!`,
  "network.addPortal.success.content": `Le réseau Wi-Fi Portail public <strong>{network}</strong> ajouté avec succès!`,
  "network.addPsk.success.content": `Le réseau Wi-Fi sécurisé par clé <strong>{network}</strong> ajouté avec succès!`,
  "network.pause.title": `Stopper le réseau {name}`,
  "network.pause.content": `Tous les appareils connectés sur ce réseau perdront leur connexion Internet.`,
  "network.remove.title": `Suppression du réseau {name}`,
  "network.remove.content": `
    <p>Vous êtes sur le point de supprimer un réseau. Tous les appareils connectés à ce dernier perdront leur connexion Internet.</p>

    {count, plural,
      =0 {}
      other {
        <p>
          {count, plural,
            one {Si votre point d'accès étend ce réseau, sa configuration}
            other {Si un ou plusieurs points d'accès étendent ce réseau, leur configuration}
          }
          sera modifiée en conséquence et les utilisateurs connectés à
          {count, plural,
            one {cette borne}
            other {une de ces bornes}
          }
          seront aussi déconnectés.
        </p>
      }
    }

  `,
   "network.remove.content.dependencies": `
    <p>
      {count, plural,
        one {Le réseau lié suivant sera également supprimé:}
        other {Les réseaux liés suivants seront également supprimés:}
      }
    </p>
  `,
  "network.remove.content.irreversible": `
    <p>Cette action est irréversible!</p>
  `,
  "network.remove.content.irreversible.noMarkup": `Cette action est irréversible!`,
  "network.remove.content.content1": `Vous avez demandé une <strong>réinitialisation d'usine</strong> pour les équipements suivant(s):`,

  "network.create.title": `Création du réseau {name}`,
  "network.update.title": `Mise à jour du réseau {name}`,
  "network.update.content": `
    Cette opération pourrait temporairement interrompre la connexion de tous les appareil connectés sur les équipements affectés par cette mise à jour.
  `,
  "network.update.rogues.part1": `Nous avons identifié un ou plusieurs réseaux Wi-Fi exposés dans votre périmètre ayant des similarités avec le nom de votre Wi-Fi <strong>{name}</strong>:`,
  "network.update.rogues.part2": `
    <p>Des noms de réseaux Wi-Fi trop similaires pourraient avoir pour conséquence de créer de la confusion au niveau de vos utilisateurs.</p>
    <p>Vous pouvez annuler cette opération pour modifier le nom de votre réseau Wi-Fi pour le rendre distinct ou unique, sinon cliquez sur <strong>Continuer</strong> pour compléter l’opération.</p>
  `,
  "network.remove.success": `Le réseau <strong>{network}</strong> a été supprimé avec succès.`,
  "network.remove.error.portal": `Vous devez supprimer le réseau Invité <strong>{network}</strong> avant de pouvoir supprimer le portail.`,
  "network.name": `Nom du Wi-Fi`,
  "network.security.OPEN": `Aucune (OPEN)`,
  "network.security.PSK": `Clé privée (WPA2-PSK)`,
  "network.security.RADIUS_USERPSK": `Clé privée (WPA2-PSK)`,
  "network.type.securedPrivate.psk": `Réseau sécurisé privé - Authentification par clé`,
  get "network.type.PRIVATE.PSK"() {return this["network.type.securedPrivate.psk"]},
  get "network.type.private.psk"() {return this["network.type.securedPrivate.psk"]},
  "network.type.securedPublic": `Réseau sécurisé Invité - Accès via réseau Portail`,
  "network.type.securedPublic.psk": `Réseau sécurisé Invité - Authentification par clé`,
  get "network.type.PUBLIC.PSK"() {return this["network.type.securedPublic.psk"]},
  get "network.type.PUBLIC.RADIUS_USERPSK"() {return this["network.type.securedPublic.psk"]},
  "network.type.portal": `Réseau Portail public`,
  get "network.type.PORTAL.OPEN"() {return this["network.type.portal"]},
  get "network.offline"() {return this["generic.offline"]},
  get "network.DISABLED"() {return this["generic.stoppedState"]},
  "network.NOT_CONFIGURED":`Non configuré`,
  get "network.OFFLINE"() {return this["generic.offline"]},
  get "network.PARTIAL"() {return this["generic.partial"]},
  get "network.ONLINE"() {return this["generic.online"]},

  "network.scheduledShutdown.closed.allDay": `toute la journée`,
  "network.scheduledShutdown.closed.fromTo": `de {from} à {to} le {day}`,

  "network.psk": `Mot de passe`,
  "network.psk.copied": `Mot de passe Wi-Fi copié dans le presse-papier!`,
  "network.psk.addToANewDeviceByQR": `Connexion par QR code`,
  "network.psk.addToANewDeviceByQR.content": `Utilisez ce QR code avec votre appareil mobile si ce dernier possède la capacité de le lire (nativement ou via une application).`,

  "network.add.vlan.restricted.title": `Vous avez sélectionné une zone restreinte`,
  "network.add.vlan.restricted.content": `Une zone restreinte exige que chacun des appareils qui se connectent soit explicitement autorisé.`,
  "network.add.vlan.noVlan": `Aucune zone disponible.`,
  "network.add.vlan.noVlan.message": `La création d'un Wi-Fi à clé partagée requiert d'avoir au moins une zone disponible.{linebreak}Vous pouvez en créer une en cliquant sur le lien ci-contre :`,

  "network.psk.rotate.title": `Récapitulatif`,
  "network.psk.rotate.confirmBox.title": `Confirmation de mot de passe`,
  "network.psk.rotate.confirmBox.content1": `Le mot de passe généré de votre réseau Wi-Fi est:`,
  "network.psk.rotate.confirmBox.content2": `
    Vous pourrez en tout temps consulter ce mot de passe en vous rendant sur la page <strong>{location}</strong> et cliquant sur le menu
  `,
  "network.psk.rotate.confirmBox.content3": `
    de ce réseau.
  `,

  "network.tile.offlineAppliances": `Normalement diffusé par {
    count, plural,
    =0 {aucun équipement}
    one {cet équipement hors-ligne}
    other {ces équipements hors-ligne}
  }.`,

  "network.schedule.title": `Horaires Wi-Fi`,
  "network.schedule.clickOnDay": `Cliquez sur une journée pour mettre à jour les horaires`,
  "network.schedule.hasScheduleModale.header.start": `Vous ne pouvez démarrer ce réseau Wi-Fi car il est géré par un calendrier d’activité.`,
  "network.schedule.hasScheduleModale.content.start": `
    <p>Pour être en mesure de le démarrer de cette manière, vous devrez d’abord désactiver l’horaire pour ce réseau.</p>
    <p>Vous pouvez aussi modifier l’horaire en conséquence afin que le démarrage s’effectue immédiatement.</p>
  `,
  "network.schedule.hasScheduleModale.header.stop": `Vous ne pouvez stopper ce réseau Wi-Fi car il est géré par un horaire d’activité.`,
  "network.schedule.hasScheduleModale.content.stop": `
    <p>Pour être en mesure de le stopper de cette manière, vous devrez d’abord désactiver l’horaire pour ce réseau.</p>
    <p>Vous pouvez aussi modifier l’horaire en conséquence afin que l’arrêt s’effectue immédiatement.</p>
  `,
  "network.schedule.planifiedStop": `Arrêt planifié`,
  "network.schedule.on": `Les horaires sont activés`,
  "network.schedule.off": `Les horaires sont désactivés`,
  "network.schedule.update": `Modifier les horaires`,
  "network.schedule.modal.message.title": `Vous êtes sur le point de désactiver les horaires.`,
  "network.schedule.modal.message.content": `Cela pourrait affecter la disponibilité du réseau pour vos utilisateurs.`,
  "network.schedule.modal.content": `Après la désactivation souhaitez-vous que votre réseau apparaisse:`,
  "network.schedule.modal.content.noRadio": `Soyez averti qu'il n'y a actuellement aucun équipement en mesure d'émettre ce Wi-Fi. {linebreak}Quel état souhaitez appliquer à ce Wi-Fi lorsqu'il sera possible de l'émettre?`,
  "network.schedule.toggle": `Gestion des horaires`,
  "network.schedule.toggle.infos": `Permet la gestion des horaires d'activité du réseau Wi-Fi. Il est possible d’établir un horaire par jour et de définir jusqu'à 5 plages d'activités durant lesquelles les utilisateurs pourront s'y connecter.`,
  "network.schedule.warning": `Tous les utilisateurs connectés à ce réseau pourront être déconnectés selon les horaires que vous appliquerez.`,
  "network.schedule.day.OPEN": `Ouvert 24 heures`,
  "network.schedule.day.CLOSED": `Fermé`,
  "network.schedule.day.VARIES": `Personnalisé`,
  "network.schedule.day.copy": `Identique à ...`,
  "network.schedule.day.copy.placeholder": `Sélectionnez un jour à copier`,
  "network.schedule.day.error": `Erreur: Les intervalles ne peuvent se chevaucher!`,
  "network.schedule.day.addInterval": `Ajouter un intervalle`,

  // Stats ============================================
  "stats.sunburst.button.show": `Voir le statut opérationnel global`,
  "stats.sunburst.button.hide": `Fermer le détail`,
  "stats.sunburst.legend.up": `Pleinement fonctionnel`,
  "stats.sunburst.legend.down": `Non fonctionnel`,
  "stats.sunburst.legend.partial": `Partiellement fonctionnel`,
  "stats.sunburst.subtitle": `Cliquez sur les portions de graphique pour afficher le détail des statuts`,
  "stats.sunburst.subtitle.generic": `Cliquez sur les portions de graphique pour aller aux détails`,
  "stats.sunburst.mention": `* avec au moins un équipement.`,
  "stats.sunburst.noAvailableData": `Aucune donnée disponible.`,
  "stats.sunburst.pie.noAccount": `Aucun compte avec au moins un équipement.`,
  "stats.sunburst.pie.noLocation": `Aucun emplacement avec au moins un équipement.`,
  "stats.sunburst.pie.noAppliance": `Aucun équipement.`,
  "stats.sunburst.spread.null": `Statut opérationnel`,
  "stats.sunburst.spread.accounts": `Statut opérationnel des comptes`,
  "stats.sunburst.spread.locations": `Statut opérationnel des emplacements`,
  "stats.sunburst.spread.devices": `Statut opérationnel des équipements`,

  "stats.map": `Carte des emplacements avec un ou plusieurs équipements hors-ligne`,
  "stats.map.desc": `Cliquez sur les emplacements pour voir plus de détails.`,
  "stats.map.locations.allGood": `Tous les emplacements sont pleinement opérationnels`,

  "stats.noData": `Aucune donnée disponible sur la période.`,
  "stats.noDataToday": `Aucune donnée disponible aujourd'hui.`,
  "stats.dataToday": `Données du jour`,
  "stats.activity.bandwidth.usage": `Consommation des données`,
  "stats.activity.bandwidth.upload": `Téléversement`,
  "stats.activity.bandwidth.download": `Téléchargement`,
  "stats.activity.average": `La moyenne est`,
  "stats.activity.usage": `consommés sur la période sélectionnée.`,
  "stats.activity.timeInterval": `Période de temps`,
  "stats.activity.average.last7": `--- La moyenne sur les 7 derniers jours est de {data}`,
  "stats.activity.average.above.noPercent": `au dessus de la moyenne`,
  "stats.activity.average.below.noPercent": `en dessous de la moyenne`,
  "stats.activity.average.current": `Moyenne courante`,
  "stats.activity.average.previous": `Moyenne précédente`,
  "stats.activity.average.prevPeriod": `Moyenne courante comparée à la moyenne de la période précédente.`,
  "stats.activity.unique.connection": `Connexions uniques`,
  "stats.activity.devices.top": `Top activité par appareil`,
  "stats.activity.active.connection": `Connexions actives`,
  "stats.activity.connection.total": `{count, plural, =0 {Connexion{linebreak} totale} one {Connexion{linebreak} totale} other {Connexions{linebreak} totales}}`,

  "stats.populations.title": `Données de population`,
  "stats.populations.devicesByLocation": `Équipements par emplacement`,
  "stats.populations.hostsByLocation": `Appareils par emplacement`,
  "stats.populations.locationsByAccount": `Emplacements par compte`,


  "stats.dns.title": `Statistiques DNS`,
  "stats.dns.topdomains": `Top domaines DNS`,
  "stats.dns.overview.breakdown": `Détails des requêtes DNS pour le domaine {domain}`,
  "stats.dns.overview.byHost": `Adresses MAC ayant demandé le domaine {domain}`,

  // FORMS ============================================
  "forms.generic.modalUpdatePending.title": `Des modifications sont en cours`,
  "forms.generic.modalUpdatePending.content": `
    <p>Vous allez quitter cette page alors que des modifications sont en cours.</p>
    <p>Toutes les modifications seront perdues.</p>
  `,

  "forms.generic.token": `Jeton`,
  "forms.generic.token.placeholder": `123456`,
  "forms.generic.firstName": `Prénom`,
  "forms.generic.lastName": `Nom`,
  "forms.generic.phoneNumber": `Numéro de téléphone`,
  "forms.generic.phoneNumber.placeholder": `+1 514 123 4567 ou +15141234567`,
  "forms.generic.password": `Mot de passe`,
  "forms.generic.email": `Courriel`,
  "forms.generic.email.placeholder": `Adresse courriel`,
  "forms.generic.productKey": `Clé produit`,
  "forms.generic.optionalMention": `(facultatif)`,
  "forms.generic.checkboxterms": `J'ai lu et accepté les conditions générales d'utilisation`,
  "forms.generic.search": `Chercher...`,
  "forms.generic.search.appliances.results": `{count, plural, =0 {Aucun équipement trouvé} one {1 équipement trouvé} other {{count} équipements trouvés}}`,
  "forms.generic.search.appliances.viewAll": `{count, plural, =0 {Aucun équipement trouvé} one {Voir l'équipement trouvé...} other {Voir les {count} équipements trouvés...}}`,
  "forms.generic.search.accounts.results": `{count, plural, =0 {Aucun compte trouvé} one {1 compte trouvé} other {{count} comptes trouvés}}`,
  "forms.generic.search.accounts.viewAll": `{count, plural, =0 {Aucun compte trouvé} one {Voir le compte trouvé...} other {Voir les {count} comptes trouvés...}}`,
  "forms.generic.search.locations.results": `{count, plural, =0 {Aucun emplacement trouvé} one {1 emplacement trouvé} other {{count} emplacements trouvés}}`,
  "forms.generic.search.locations.viewAll": `{count, plural, =0 {Aucun emplacement trouvé} one {Voir l'emplacement trouvé...} other {Voir les {count} emplacements trouvés...}}`,
  "forms.generic.selectLanguage": `Sélectionnez votre langue`,
  "forms.generic.selectMode": `Choix de la configuration`,
  "forms.generic.warning": `ATTENTION! Cette action est irreversible.`,
  "forms.generic.warning2": `ATTENTION - Cette action est irreversible!`,
  "forms.generic.warning.reset": `Cela réinitialisera également l'équipement aux paramètres d'usine.`,
  "forms.generic.warning.remove": `Cette action supprimera {location} et tous les réseaux Wi-Fi configurés à cet emplacement.`,

  // User form
  "forms.user.photo": `Photo`,
  "forms.user.enterActualPassword": `Saisir le mot de passe actuel`,
  "forms.user.enterPassword": `Entrer le nouveau mot de passe`,
  "forms.user.confirmPassword": `Confirmer le nouveau mot de passe`,
  "forms.user.password.validationBox.title": `Votre mot de passe doit:`,
  "forms.user.password.validationBox.rule1": `faire au minimum 12 caractères.`,
  "forms.user.password.validationBox.rule2": `inclure une majuscule et une minuscule.`,
  "forms.user.password.validationBox.rule3": `inclure un chiffre et un caractère spécial.`,

  // Organization form
  "forms.organization.name": `Nom du compte (entreprise, famille, groupe, équipe, etc.)`,
  "forms.organization.name.placeholder": `Ex.: Ma compagnie, Maison, etc.`,
  "forms.organization.tagline": `Slogan`,
  "forms.organization.tagline.placeholder": `Une phrase qui vous ressemble`,
  "forms.organization.logo": `Logo`,
  "forms.organization.logo.optional": `(facultatif, utile pour le réseau portail public Wi-Fi)`,
  "forms.organization.ownerFirstName": `Prénom`,
  "forms.organization.ownerLastName": `Nom`,
  "forms.organization.ownerEmail": `Courriel`,
  "forms.organization.ownerEmail.placeholder": `owner@domaine.com`,

  // Setup networks form
  "forms.networks.selectVlan": `Vous devez ajouter ce réseau dans une des zones logiques associées à votre compte.`,
  "forms.networks.addedToVlan": `Le réseau sera ajouté à la zone restreinte suivante:`,
  "forms.networks.addedToVlan.generic": `Le réseau sera ajouté à la zone suivante:`,
  "forms.networks.selectVlan.label": `Sélectionnez la zone logique appropriée pour ce Wi-Fi`,
  "forms.networks.selectDevices.label": `{count, plural,
    =0 {Sélectionnez un équipement pour émettre ce Wi-Fi}
    one {Sélectionnez un équipement pour émettre ce Wi-Fi}
    other {Sélectionnez des équipements pour émettre ce Wi-Fi}
  }`,

  "forms.networks.addPortal.vlan.content": `Zone spéciale pour le réseau Wi-Fi portail.`,
  "forms.networks.addPublic.vlan.content": `Zone spéciale pour les réseaux Wi-Fi publics.`,

  "forms.networks.deviceLocation": `Emplacement de l'équipement`,
  "forms.networks.deviceLocation.placeholder": `Ex.: Salon, Hall, Sous-sol, Garage, Bureau, etc.`,
  "forms.networks.privateNetworkNote": `À noter que tous les réseaux {exist, plural, =0 {} other {suivants }}font partis d'une même zone logique qui permet de communiquer avec tous les appareils et ressources qui y sont connectés`,

  "forms.networks.privateNetworkName.sublabel": `Lettres non-accentuées, chiffres, tiret (-) et sous-lignement (_)`,
  "forms.networks.portalNetworkName": `Nom du réseau Portail (Ouvert)`,
  "forms.networks.pskNetworkName": `Nom du réseau privé avec clé partagée (WPA2)`,
  "forms.networks.pskNetworkName.placeholder": ` `,

  "forms.networks.pskNetworkPwd": `Mot de passe du réseau`,
  "forms.networks.pskNetworkPwd.info": `Le mot de passe de votre réseau sera automatiquement généré et affiché suite à la création.`,
  "forms.networks.pskNetworkPwd.rotate": `
    <strong>Regénérer un mot de passe</strong>{linebreak}
    Cette action déconnectera tous les appareils de ce réseau.
  `,
  "forms.networks.pskNetworkPwd.sublabel": `Le mot de passe de votre réseau sera automatiquement généré.`,

  "forms.networks.publicNetwork.use": `Type d'utilisation`,
  "forms.networks.publicNetwork.use.home": `À la maison`,
  "forms.networks.publicNetwork.use.home.tooltip": `Vos visiteurs auront besoin d'une clé d'accès personnelle afin de se connecter à ce réseau. Vous devrez leur partager la clé (mot de passe) que vous aurez défini pour ce réseau.`,
  "forms.networks.publicNetwork.use.enterprise": `En entreprise`,
  "forms.networks.publicNetwork.use.enterprise.unavailable": `1 réseau disponible, 2 nécessaires`,

  "forms.networks.publicNetwork.use.enterprise.tooltip": `Vos visiteurs auront besoin d'une clé d'accès personnelle afin de se connecter à ce réseau. Ils devront obtenir cette clé en se connectant à votre réseau Portail public, ce dernier étant requis pour la création d'un réseau Invité en entreprise. Limité à 1 réseau Invité Entreprise`,
  "forms.networks.publicNetworkName": `Nom du réseau Invité (WPA2)`,
  "forms.networks.publicNetworkName.placeholder": `Wi-Fi Invité`,
  "forms.networks.networkUser.manage": `Gérer les utilisateurs`,
  "forms.networks.networkManager.label": `Administrateur(s) réseau`,
  "forms.networks.networkManager.add": `Gérer les administrateurs réseau`,
  "forms.networks.networkManager.modal.title": `Gérer les administrateurs réseau`,
  "forms.networks.networkManager.modal.text": `
    <p>
      Sélectionnez un utilisateur auquel vous souhaitez attribuer/retirer des privilèges d'administrateur réseau. Veuillez noter que ces privilèges permettent un accès complet pour administrer les réseaux.
    </p>
  `,


  // /FORMS ===========================================

  // ONBOARDING =======================================

  // Step 0
  "onboarding.step0.header.title": `Installation d'un point d'accès sécurisé`,
  "onboarding.step0.header.subtitle": `Nous protégeons vos réseaux numériques`,
  "onboarding.step0.title": `Procédure d'installation`,
  "onboarding.step0.content": `
    <p>Vous êtes maintenant prêt à procéder à l'installation de votre point d'accès SentinelC.</p>
    <p>La configuration et l’activation de votre réseau sécurisé ne vous demandera que quelques minutes.</p>
    <p><small>Vous pouvez interrompre le processus à tout moment et le reprendre par la suite.*</small></p>
    <p>Bonne installation!</p>
    <em><small>* Vos données seront enregistrées pendant 24 heures.</small></em>
  `,

  // Step 1
  "onboarding.step1.title": `Étape 1 - Enregistrer votre équipement`,
  "onboarding.step1.updateDevice.title": `Mise à jour de l'équipement`,
  "onboarding.step1.updateDevice.retry": `Relancer la mise à jour`,
  "onboarding.step1.updateDevice.message": `Votre équipement doit être mis à niveau.`,
  "onboarding.step1.updateDevice.desc": `
    <p>
      La mise à jour peut prendre quelques minutes
      selon la vitesse de votre connexion Internet.{linebreak}Vous serez redirigé automatiquement à la fin de celle-ci.</p>
    <p><strong>
      Veuillez ne pas débrancher équipement durant la mise à jour.
    </strong></p>
  `,
  "onboarding.step1.updateDevice.error": `L'installation a échoué !{linebreak} Veuillez réessayer.`,
  "onboarding.step1.content": `
    <p>Veuillez entrer la clé du produit qui se trouve imprimée à l’intérieur du Guide d’installation rapide.</p>
  `,

  // Step 1b
  "onboarding.step1.noCompleted.title": `Votre enregistrement n'a pas été complété`,
  "onboarding.step1.noCompleted.content":`
    <p>L'enregistrement a été démarré mais pas il n'est pas terminé pour votre équipement ({deviceLocation}), nous avons enregistré votre progression et les informations déjà définies.</p>
  `,
  "onboarding.step1.noCompleted.button": `Continuer l'enregistrement`,

  // Step 1c
  "onboarding.step1.waitingActivation.title": `Votre enregistrement est en attente d'activation`,
  "onboarding.step1.waitingActivation.content":`
    <p>S'il vous plaît cliquez sur le lien d'activation envoyé à l'adresse courriel: <strong>{emailAddress}</strong></p>
  `,
  "onboarding.step1.waitingActivation.list.intro": `Si vous n'avez pas reçu le courriel:`,
  "onboarding.step1.waitingActivation.list.item1": `Vérifiez dans vos pourriels`,
  "onboarding.step1.waitingActivation.list.item2": `Envoyer un autre courriel`,
  "onboarding.step1.waitingActivation.messages.sendSuccess": `Lien d'activation envoyé à {emailAddress}`,

  // Step 2
  "onboarding.step2.title": `Étape 2 - Enregistrer votre compte SentinelC`,
  "onboarding.step2.content":`
    <p>Cette étape consiste à créer votre compte SentinelC auquel seront associés votre routeur, vos réseaux ainsi que les utilisateurs et appareils autorisés à ceux-ci.</p>
  `,

  // Step 3
  "onboarding.step3.title": `Étape 3 - Créer un profil administrateur`,
  "onboarding.step3.content":`
    <p>Cette étape vous permet de créer un profil utilisateur privilégié afin d’administrer la sécurité de vos réseaux et de gérer en détail les accès à ceux-ci.</p>
  `,
  "onboarding.step3.useOwnerUser": `Utiliser les informations du contact du compte SentinelC pour la création du profil administrateur.`,
  "onboarding.step3.password.info": `Vous serez invité à définir votre mot de passe lors de l’activation de votre profil à partir d’un lien que vous aurez reçu à votre adresse de couriel.`,

  // Step 4
  "onboarding.step4.title": `Étape 4 - Créer un réseau sécurisé`,
  "onboarding.step4.content":`
    <p>Cette étape vous permet de configurer votre premier réseau Wi-Fi privé sécurisé.</p>
  `,
  "onboarding.step4.network.wantAWifi": `Souhaitez-vous créer un réseau Wi-Fi dès maintenant?`,
  "onboarding.step4.network.wantAWifi.note": `Vous pourrez aussi créer des réseaux Wi-Fi à partir de l'application.`,
  "onboarding.step4.network.update": `Horaires de mise à jour de l'équipement`,
  "onboarding.step4.network.noRadio.title": `Cet appareil ne peut émettre de réseau Wi-Fi`,
  "onboarding.step4.network.noRadio.content": `Cet appareil ne posséde aucune radio permettant d'émettre des réseaux Wi-Fi.`,

  // Step 5
  "onboarding.step5.1.title": `Étape 5 - Résumé de la configuration`,
  "onboarding.step5.1.organizationInformations": `Compte SentinelC`,
  "onboarding.step5.1.ownerInformations": `Contact`,
  "onboarding.step5.2.adminInformations": `Profil administrateur`,
  "onboarding.step5.3.networksInformations": `Réseaux`,

  // Step 6 - waiting for activation / reactivation
  "onboarding.step6.waitingActivation.title": `Félicitations, vous venez de terminer le processus d'installation SentinelC!`,
  "onboarding.step6.waitingActivation.content":`
    <p>Vous recevrez bientôt un courriel d'activation à l'adresse <strong>{emailAddress}</strong>, votre réseau sera disponible après l'activation.</p>
  `,
  "onboarding.step6.waitingActivation.list.intro": `Si vous n'avez pas reçu l'e-mail après quelques minutes:`,

  "onboarding.step6bis.waitingActivation.content":`
    <p>Le courriel d'activation n'a pu être envoyé à l'adresse <strong>{emailAddress}</strong>, votre réseau ne sera disponible qu'après l'activation.</p>
  `,
  "onboarding.step6bis.waitingActivation.list.intro": `Veuillez vérifier si votre boîte courriel n'est pas pleine.`,
  "onboarding.step6bis.reactivated.content": `<p>Votre courriel correspond a été utilisateur désactivé, ce dernier sera réactivé.</p><p>Veuillez vous connecter en cliquant sur le bouton ci-dessous.</p>`,

  // Installation process
  "installation.plugInstructions.expansionButton": `Consulter les directives de branchement de l’appareil`,
  "onboarding.step7.installationProcess.title": `Configuration de l'appareil SentinelC`,
  "onboarding.step7.installationProcess.waitingForDevice": `Veuillez patienter, votre appareil est en cours d'installation.`,
  "onboarding.step7.installationProcess.update.redirectIn": `Vous serez redirigé vers la finalisation dans {time}`,
  "onboarding.step7.installationProcess.onSuccess.title": `L'installation est complétée.`,
  "onboarding.step7.installationProcess.onSuccess.info.PSK": `
    <p><strong>L’installation est complétée avec succès!</strong></p>
    <p>
      Vous pouvez maintenant vous connecter au réseau «<strong>{ssid}</strong>» avec le mot de passe que vous avez défini précédemment.
    </p>
  `,

  "onboarding.step7.installationProcess.onSuccess.accessToApp": `Accéder à l'application`,
  "onboarding.step7.installationProcess.onSuccess.valid": `Votre compte est activé!`,
  "onboarding.step7.installationProcess.onError.title": `Une erreur est survenue lors de l'installation:`,
  "onboarding.step7.installationProcess.onError.offlineDevice": `Votre équipement semble hors ligne, veuillez vérifier la connexion Internet à l'appareil.`,
  "onboarding.step7.installationProcess.onError.offlineDeviceLong": `Votre équipement semble hors ligne, veuillez vérifier la connexion Internet à l'appareil afin de compléter la configuration de celui-ci.`,
  "onboarding.step7.installationProcess.onError.offlineDevice.instructions1": `
    <p>C’est maintenant le moment de brancher votre SentinelC à l’Internet.</p>
    <p><em>Si vous avez déjà un routeur installé, vous devez le déconnecter de votre modem. Si vous possédez un modem-routeur, vous devez désactiver votre routeur à partir de la configuration de l’appareil (contactez votre fournisseur).</em></p>
    <p>Vérifiez que le câble Internet est bien branché entre votre modem et la prise Internet située à l’arrière de l'équipement SentinelC.</p>
  `,
  "onboarding.step7.installationProcess.onError.offlineDevice.instructions2": `<p>Veuillez vérifier que les indicateurs lumineux situés à l’avant du boîtier de l’appareil sont au vert comme suit:</p>`,
  "onboarding.step7.installationProcess.onError.offlineDevice.instructions3": `<p>Vous pouvez ensuite lancer la configuration une fois ces vérifications complétées.</p>`,
  "onboarding.step7.installationProcess.onError.offlineDevice.startConfiguration":`Lancer la configuration`,

  // /ONBOARDING ======================================


  // BACKOFFICE =======================================

  "backOffice.404": `
    <p>
      La page demandée n'existe pas ou vous n'avez pas accès à cette page.
    </p>
  `,
  "backOffice.backToDashboard": `Quitter l'Administration`,
  "backOffice.adminPortal": `Administration`,
  "backOffice.sinceTheBeginning": `Depuis le début`,
  "backOffice.pendingTasks": `Tâches en attente`,
  "backOffice.failedTasks": `Tâches en échec`,
  "backOffice.lastConnectionValue": `Valeur lors de la dernière connexion.`,
  "backOffice.lastPortScanValue": `Valeur du dernier scan de ports.`,
  "backOffice.hasOpenIncidents": `{count, plural, =0 {Aucun incident ouvert} one {1 incident ouvert} other {{count} incidents ouverts}}.`,
  "backOffice.isPrivileged": `Votre profil possède la permission <strong>Privilégié</strong>.`,
  "backOffice.isPrivileged.long": `Ce niveau de permissions vous donne plein accès à toutes les données et actions disponibles sur ce site.`,
  "backOffice.isAuditor": `Votre profil possède la permission <strong>Auditeur</strong>.`,
  "backOffice.isAuditor.long": `Ce niveau de permissions vous donne accès en lecture seule aux données de ce site.`,

  get "backOffice.components.packetCaptures"() {return this["surveillance.packetCaptures.title"]},
  get "backOffice.components.portScan"() {return this["surveillance.portScans.title"]},
  "backOffice.components.disks": `Disques`,
  "backOffice.components.disks.complete": `Documentation complète sur les disques`,
  "backOffice.components.disks.statuses": `Comprendre les statuts d'un disque`,
  "backOffice.components.disks.usages": `Comprendre les usages d'un disque`,
  "backOffice.components.wifi": `Informations Wi-Fi`,
  get "backOffice.components.incidents"() {return this["backOffice.home.recentOpenIncidents"]},
  "backOffice.components.removeComponent": `Retirer le composant`,
  get "backOffice.components.ports"() {return this["generic.networkPorts"]},
  get "backOffice.components.appliances"() {return this["generic.appliances"]},
  get "backOffice.components.monitoringEvents"() {return this["backOffice.services.monitoringEvents"]},
  get "backOffice.components.logs"() {return this["generic.logs"]},
  get "backOffice.components.stats"() {return this["generic.statistics"]},
  get "backOffice.components.services"() {return this["generic.services"]},
  get "backOffice.components.tasks"() {return this["generic.tasks"]},

  "backOffice.logs.unavailable": `Données indisponible pour cet équipement.`,
  "backOffice.logs.unavailable.pod": `Données indisponible pour cette instance.`,

  "backOffice.pod.serviceId": `ID du service`,
  "backOffice.pod.startOption": `Option de démarrage`,
  "backOffice.pod.startOption.filterBy": `Par option de démarrage`,
  "backOffice.pod.startOption.onDemand": `À la demande`,
  "backOffice.pod.startOption.restart": `Redémarrage automatique`,
  "backOffice.pod.deployedOn": `Déployé sur l'équipement`,
  "backOffice.pod.applianceType": `Type de l'équipement`,

  "backOffice.disks.title": `Disques et partitions`,
  "backOffice.disks.component.subtitle": `Niveau de remplissage des disques`,
  "generic.disks.none.withFilter": `Aucun disque ne correspond aux critères sélectionnés.`,
  "backOffice.disks.status": `Statut du disque`,
  "backOffice.disks.status.filterBy": `Par statut du disque`,

  "backOffice.service.advancedParameters": `Paramètres avancés du service`,
  "backOffice.service.inexistant": `Ce service a été supprimé ou n'existe pas.`,
  "backOffice.service.inexistant.return": `Retourner à la liste des services embarqués`,
  "backOffice.service.parameters": `Paramètres`,
  "backOffice.service.gotoUI": `Lien vers l'interface Web`,
  "backOffice.service.networking.ports": `Nb. de ports requis`,
  "backOffice.service.networking.vlan": `Nb. de zones requises`,
  "backOffice.service.port": `Port physique`,
  "backOffice.service.ports": `Ports physiques`,
  "backOffice.service.ports.cloud": `Autoriser l'accès au proxy cloud`,
  "backOffice.service.ports.vlan": `Autoriser l'accès local à la zone définie`,
  "backOffice.service.forceStopAll": `Tout arrêter`,
  "backOffice.service.viewAllManage": `Voir tout et gérer`,
  "backOffice.service.minimumMemoryRequired": `Mémoire minimum requis`,
  "backOffice.service.minimumStorageRequired": `Stockage minimum requis`,
  "backOffice.service.message.bulk": `Service(s) mis à jour avec succès`,
  "backOffice.service.message.update": `Service mis à jour avec succès`,
  "backOffice.service.message.bulkpods": `Service(s) mis à jour avec succès`,
  "backOffice.service.message.parametersForm": `Formulaire des paramètres`,
  "backOffice.service.message.add": `L'installation du service a été initiée. Cette opération peut prendre plusieurs minutes. Vous pourrez voir la progression de l'installation dans le composant "services" de l'équipement. `,
  "backOffice.service.message.add.secrets": `ATTENTION: Les paramètres surlignés en jaune sont des valeurs secrètes et n'apparaîtront plus. Veuillez les prendre en note maintenant.`,
  "backOffice.service.addAnotherService": `Souhaitez-vous installer un autre service?`,
  "backOffice.service.uninstalled": `Service désinstallé`,

  "backOffice.services.monitoringEvents": `Surveillance des événements`,
  "backOffice.services.monitoringEvents.metadata": `Metadonnées Yaml`,
  "backOffice.services.monitoringEvents.metadata.none": `Aucune metadonnée`,
  "backOffice.services.monitoringEvents.none": `Aucun événement`,
  "backOffice.services.monitoringEvents.none.withDates": `Aucun événement entre le {from} et le {to}.`,
  "backOffice.services.monitoringEvents.none.withFilter": `Aucun événement ne correspond aux critères sélectionnés.`,
  "backOffice.services.monitoringEvents.filters.subtitle": `La période sélectionnée ne doit pas excéder 15 jours.`,
  "backOffice.services.monitoringEvents.filters.errorPeriodSup60": `La période sélectionnée excède 15 jours.`,
  "backOffice.services.monitoringEvents.filters.errorMissingDate": `Toutes les dates sont obligatoires`,
  
  "backOffice.services.projectRelated": `Projet relié`,
  "backOffice.services.embeddedService": `Service embarqué`,
  "backOffice.services.embeddedService.instanceOf": `Une instance du service`,
  "backOffice.services.embeddedService.instanceDeployed": `Instances de {name} déployées sur les équipements`,
  "backOffice.services.embeddedServiceDetails": `Paramètres`,
  "backOffice.services.embeddedServices": `Services embarqués`,
  "backOffice.services.embeddedServices.long": `Services embarqués par appareil`,
  "backOffice.services.embeddedServices.viewDetails": `Voir les détails du service`,
  "backOffice.services.embeddedServices.viewAll": `{count, plural, =0 {Aucun service} one {Voir les détails du service déployé} other {Voir les détails des {count} services déployés}}`,

  "backOffice.services.title": `Bibliothèque de services`,
  "backOffice.services.source": `Source`,
  "backOffice.services.source.filterBy": `Par source`,
  "backOffice.services.serviceSource": `Source du service`,
  "backOffice.services.serviceSource.filterBy": `Par source`,
  "backOffice.services.serviceState": `État du service`,
  "backOffice.services.serviceState.filterBy": `Par state`,
  "backOffice.services.homepage": `Page d'accueil`,
  "backOffice.services.installAService": `Installer un service`,
  "backOffice.services.installAService.selectAService": `Sélectionner un service à installer sur cet équipement`,
  "backOffice.services.installAService.selectAZone": `Sélectionner une  zone où installer ce service`,
  "backOffice.services.documentation": `Documentation`,
  "backOffice.services.upToDate": `À jour`,
  "backOffice.services.upToDate.filterBy": `Par statut à jour`,
  "backOffice.services.upToDate.YES": `Oui`,
  "backOffice.services.upToDate.NO": `Non`,
  "backOffice.services.upToDate.NO.popup": `La dernière version est la {version}`,

  "backOffice.ssid.noSsid": `Le réseau Wi-Fi n'existe pas ou a été supprimé.`,
  "backOffice.ssid.edit": `Modification du réseau Wi-Fi`,
  "backOffice.ssid.type": `Type de réseau Wi-Fi`,
  "backOffice.ssid.type.private.psk": `Privé`,
  "backOffice.ssid.type.private.psk.explanation": `Ce type de réseau permet à tous les appareils connectés de partager les ressources réseaux.`,
  "backOffice.ssid.type.public.psk": `Invité`,
  "backOffice.ssid.type.public.psk.explanation": `Ce type de réseau permet seulement un accès sécurisé à internet aux invités ou visiteurs sans partage des ressources réseaux.`,
  "backOffice.ssid.start": `Démarrer le réseau Wi-Fi`,
  "backOffice.ssid.stop": `Stopper le réseau Wi-Fi`,
  "backOffice.ssid.specifications": `Spécifications du Wi-Fi`,
  "backOffice.ssid.selectALocation": `Sélectionner un emplacement...`,
  "backOffice.ssid.noAppliance": `Aucun équipement, le Wi-fi sera créé mais ne pourra être émis.`,
  "backOffice.ssid.noApplianceSelected": `Aucun équipement sélectionné, le Wi-fi ne sera plus émis.`,
  "backOffice.ssid.noApplianceSelected.create": `Aucun équipement sélectionné, le Wi-fi sera créé mais ne pourra être émis.`,

  "backOffice.ip.title": `Événements IP`,
  "backOffice.ip.summary.title": `Analyse de l'événement`,
  "backOffice.interceptions.interceptions": `Interception(s)`,
  "backOffice.interceptions.reportList": `Liste noire`,
  "backOffice.interceptions.nbPackets": `# total de paquet(s)`,
  "backOffice.interceptions.nbPackets.source": `Paquet(s) de la source`,
  "backOffice.interceptions.nbPackets.destination": `Paquet(s) de la destination`,
  "backOffice.interceptions.date": `Date de l'événement`,
  "backOffice.interceptions.none": `Aucun événement`,
  "backOffice.interceptions.none.filtered": `Aucun événement ne correspond aux critères sélectionnés.`,
  "backOffice.interceptions.noInterception": `Aucun événement.`,
  "backOffice.interceptions.filtered.noInterception": `Aucun événement ne correspond aux critères sélectionnés.`,
  "backOffice.interceptions.title": `Événements IP`,
  "backOffice.interceptions.desc": `Liste des IPs demandées et identifiées dans une ou plusieurs listes noires.`,
  "backOffice.interceptions.name": `IP / Nom de domaine`,
  "backOffice.interceptions.thresdhold": `Seuil`,
  "backOffice.interceptions.source": `Source`,
  "backOffice.interceptions.destination": `Destination`,
  "backOffice.interceptions.nbDetections": `# de détections`,
  "backOffice.interceptions.title.mostRecent": `Événements IP les plus récents`,
  "backOffice.interceptions.direction": `Direction`,
  "backOffice.interceptions.packetSize": `Taille des paquets`,
  "backOffice.interceptions.sourcePort": `Port source`,
  "backOffice.interceptions.destPort": `Port de destination`,
  "backOffice.interceptions.reaction": `Réaction`,
  "backOffice.interceptions.nbEvents": `# événements`,
  "backOffice.interceptions.firstSeenEver": `Vu la toute première fois`,
  "backOffice.interceptions.location": `Géolocalisation`,
  "backOffice.interceptions.organization": `Organisation`,
  "backOffice.interceptions.firstSeenOnMAC": `Première fois sur cette adresse MAC`,
  "backOffice.interceptions.nbEventsRecorded": `# d'événements enregistrés`,
  "backOffice.interceptions.associatedDNSRequest": `Requête(s) DNS associée(s)`,
  "backOffice.interceptions.associatedDNSRequest.count": `{count, plural, =0 {Aucune requête} one {1 requête} other{{count} requêtes}}`,
  "backOffice.interceptions.tableTitle": `Cette IP a été vue dans les compte(s) et emplacement(s) suivant(s)`,

  "backOffice.appliance.services.currentState": `État courant`,
  "backOffice.appliance.services.currentState.crashed.desc": `Notez que ce service était démarré avant d'être arrêté sans aucune intervention d'un administrateur.`,
  "backOffice.appliance.services.currentState.crashed.retries": `{count, plural, =0 {Auncun redémarrage automatique} one {1 redémarrage automatique} other{{count} redémarrages automatiques}}`,
  "backOffice.appliance.services.deployedZone": `Zone de déploiement`,
  "backOffice.appliance.services.fullyOperational": `Pleinement opérationnel`,
  "backOffice.appliance.services.notOperational": `Non opérationnel`,
  "backOffice.appliance.services.none": `Il n'y a aucun service installé pour le moment.`,
  "backOffice.appliance.services.noneAvailable": `Aucun service disponible.`,
  "backOffice.appliance.services.startBtn": `Démarrer le service`,
  "backOffice.appliance.services.stopBtn": `Stopper le service`,
  "backOffice.appliance.services.logsBtn": `Voir les logs`,
  "backOffice.appliance.services.removeBtn": `Désinstaller le service`,
  "backOffice.appliance.services.UNINSTALL": `Désinstaller le service`,
  "backOffice.appliance.services.UNINSTALL.count": `{count, plural, =0 {Aucun service à désinstaller} one {Désinstaller le service} other {Désinstaller les services}}`,
  "backOffice.appliance.services.UNINSTALL.text": `Êtes-vous sûr de désinstaller {count, plural, =0 {le service} one {le service} other {les services}}?`,
  "backOffice.appliance.services.UNINSTALL.askVolumeDeletion": `Supprimer les volumes contenant les données {count, plural, =0 {du service} one {du service} other {des services}}`,
  "backOffice.appliance.services.paramsBtn": `Voir les paramètres`,
  "backOffice.appliance.services.paramsBtn.none": `Aucun paramètre`,
  "backOffice.appliance.services.podSystem.fullyOperational": `Opérationnels`,
  "backOffice.appliance.services.podSystem.notOperational": `Non opérationnels`,
  "backOffice.appliance.services.podSystem.DISK_FULL1": `L'engin de service a été stoppé à cause de la limite d'espace disque. Aucun service ne peut être démarré.`,
  "backOffice.appliance.services.podSystem.DISK_FULL": ` Le disque de service doit être vidé ou remplacé.`,
  "backOffice.appliance.services.podSystem.NO_CONTAINER_STORAGE": `Aucun disque configuré pour les services.`,
  "backOffice.appliance.services.podSystem.OK": `L'engin de services fonctionne correctement.`,
  "backOffice.appliance.services.podSystem.UNKNOWN_ERROR": `La gestion des services est en panne.`,

  "backOffice.location.accountName": `Nom du compte`,
  "backOffice.location.accountName.new": `Sélectionner un compte où créer l'emplacement`,
  "backOffice.location.addAppliances": `Ajouter des équipements`,
  "backOffice.location.addAppliances.claim": `Vous pouvez ajouter un nouvel appareil en vue de le configurer dans l'emplacement (optionnel)`,
  "backOffice.location.addAppliances.select": `Sélectionnez les équipements que vous souhaitez ajouter et spécifiez leur mode de configuration`,
  "backOffice.location.addAppliances.unselectedOperatingMode": `Veuillez spécifier le mode de configuration pour chaque appareil sélectionné.`,
  "backOffice.location.addAppliances.enterKey.label": `Veuillez entrer une clé d'enregistrement valide. Cliquez sur le bouton Ajouter afin d'ajouter cet équipement à la liste ci-dessous.`,
  "backOffice.location.backto": `Retour à l'emplacement`,
  "backOffice.location.details": `Détails de l'emplacement`,
  "backOffice.location.noLocation": `Aucun emplacement`,
  "backOffice.location.noLocation.toShow": `Aucun emplacement à afficher`,
  "backOffice.location.create": `Créer un nouvel emplacement`,
  "backOffice.location.create.success": `L'emplacement <strong>{name}</strong> a été créé avec succès`,
  "backOffice.location.update.success": `L'emplacement <strong>{name}</strong> a été modifié`,
  "backOffice.location.updateQos.success": `Le QoS a été modifié avec succès`,
  "backOffice.location.updateSecurityProfile.success": `Le profil de sécurité a été modifié avec succès`,
  "backOffice.location.qos": `QoS`,
  "backOffice.location.qos.long": `Qualité de Service (QoS)`,
  "backOffice.location.qos.internetConnection": `Connexion internet`,
  "backOffice.location.qos.zonesConfig": `Configuration des zones`,  
  "backOffice.location.exportConfig": `Exporter la configuration`,
  "backOffice.location.exportConfig.desc": `Cet export YAML de la configuration peut servir à reconfigurer un nouvel emplacement à partir du bouton <strong>Importer un emplacement</strong> de la page de détails d'un compte.`,
  "backOffice.location.importLoc": `Importer un emplacement`,
  "backOffice.location.importLoc.fail.delete": `L'import a partiellement échoué. Souhaitez-vous supprimer l'emplacement et éditer le YAML?`,
  "backOffice.location.importLoc.fail.import": `L'import a échoué`,
  "backOffice.location.importLoc.fail.editYaml": `Editer le YAML`,
  "backOffice.location.importLoc.desc": `Collez la configuration YAML de l'emplacement que vous souhaitez créer dans la zone bleue ci-dessous, peuvent être inclus les zones et leurs Wi-Fis.`,
  "backOffice.location.importLoc.errorConfig": `Configuration invalide`,
  "backOffice.location.edit": `Modifier l'emplacement`,
  "backOffice.location.editInfo": `Modifier les infos de l'emplacement`,
  "backOffice.location.filtered.noLocation": `Aucun emplacement ne correspond aux critères sélectionnés.`,
  "backOffice.location.configuredAccessPorts": `Ports d'accès configurés`,
  "backOffice.location.activeWiredConnections": `Clients filaires en ligne`,
  "backOffice.location.activeWiFiConnections": `Clients Wi-Fi en ligne`,
  "backOffice.location.showActiveWiFiConnections": `Voir les clients Wi-Fi en ligne`,
  "backOffice.location.showVlans": `Voir les zones/vlans`,
  "backOffice.location.columns.appliances": `Équipements hors-ligne/total`,
  "backOffice.location.columns.devices": `# d'appareils total`,
  "backOffice.location.columns.wifi": `Wi-Fis`,
  "backOffice.location.columns.pendingHosts": `Auth. en attente`,
  "backOffice.location.columns.blockedHosts": `Appareils bloqués`,
  "backOffice.location.vlanzone": `Zone/Vlan`,
  "backOffice.location.vlanszones": `Zones/Vlans`,
  "backOffice.location.vlanConfig": `Configuration des zones/vlans`,
  "backOffice.location.map": `Voir la carte du réseau`,

  "backOffice.appliance.noAppliance": `Aucun équipement`,
  "backOffice.appliance.addedOn": `Équipement ajouté le {date}`,
  "backOffice.appliance.addedToNetwork": `Ajout à l'emplacement`,
  "backOffice.appliance.removed": `Équipement retiré`,
  "backOffice.appliance.removed.message": `L'équipement a été retiré du projet le {date}, vous ne pouvez plus le modifier ou faire des opérations dessus`,
  "backOffice.appliance.filtered.noAppliance": `Aucun équipement ne correspond aux critères sélectionnés.`,
  "backOffice.appliance.tasks.tooltip.explanation": `Nombre de tâches <strong>En attente</strong> ou <strong>En cours</strong> pour un équipement.`,
  "backOffice.appliance.tasks.tooltip.pendingRemoval": `Retrait de l'équipement en attente.`,

  "backOffice.appliance.manageInventory": `Gérer l'inventaire`,
  "backOffice.appliance.addToInventory": `Vous devez ajouter l'équipement à l'inventaire pour l'utiliser.`,
  "backOffice.appliance.claimByRK": `Ajouter par clés d'enregistrement`,
  "backOffice.appliance.current": `Équipement courant`,
  "backOffice.appliance.selectAccountToClaim": `Sélectionner le compte de destination`,
  "backOffice.appliance.enterRK": `Entrer une ou plusieurs clés d'enregistrement (une par ligne)`,
  get "backOffice.appliance.returnTo"() {return this["generic.actions.RETURN_TO_OPERATOR"]},
  "backOffice.appliance.returnTo.message": `Vous êtes sur le point de retourner {count, plural,
    =0 {0 équipement}
    one {un équipement}
    other {{count} équipements}
  } {list} à votre fournisseur SentinelC.`,
  "backOffice.appliance.returnTo.messageForData": `Veuillez noter {count, plural,
    =0 {qu'aucun équipement ne peut}
    one {que l'équipement suivant peut}
    other {que les équipements suivants pourraient}
  } contenir des données sensibles.`,
  "backOffice.appliance.returnTo.messageForData.desc": `Vous ne serez plus en mesure de les supprimer une fois le retour effectué. Il est recommandé de désinstaller les services embarqués et d'effectuer un nettoyage des disques avant de retourner les équipements.`,
  "backOffice.appliance.returnTo.label": `Raison pour laquelle vous retournez l(es) équipement(s) à votre fournisseur SentinelC`,
  "backOffice.appliance.returnTo.content": `Vos équipements seront retournés à votre fournisseur SentinelC. Faites attention à bien renvoyer vos équipements après cette opération.`,
  "backOffice.appliance.returnTo.placeholder.DEFECTIVE": `Veuillez décrire votre problème...`,
  "backOffice.appliance.returnTo.placeholder.OTHER": `Veuillez expliquer la raison de votre retour...`,
 
 get "backOffice.appliance.discard"() {return this["generic.actions.DISCARD_DEFINITIVELY"]},
  "backOffice.appliance.discard.message": `Vous êtes sur le point de révoquer {count, plural,
    =0 {0 équipement}
    one {un équipement}
    other {{count} équipements}
  } {list} de votre inventaire.`,
  "backOffice.appliance.discard.messageSensitive": `Certains équipements que vous êtes sur le point de retirer ont le statut "Nouveau", "Retourné" ou "En stock".`,
  "backOffice.appliance.discard.label": `Raison pour laquelle vous souhaitez révoquer ces équipements`,
  "backOffice.appliance.discard.content": `Ces équipements n'existeront plus et seront présent dans la liste d'inventaire à titre informatif pour l'historique. Ils ne pourront plus être ajoutés s'ils tentent de se reconnecter à la plateforme.`,
  "backOffice.appliance.discard.placeholder.DEFECTIVE": `Veuillez décrire le problème...`,
  "backOffice.appliance.discard.placeholder.OBSOLETE": `Saisir les versions des équipements obsolètes...`,
  "backOffice.appliance.discard.placeholder.OTHER": `Veuillez expliquer la raison de la révocation...`,

  "backOffice.appliance.factoryreset.content": `Cette action supprimera toute configuration et retirera définitivement les équipements de leur emplacement mais ils resteront associés à leur compte actuel.`,

  "backOffice.appliance.selectAccountToTransferTo.transferTo.title.singular": `Transférer l'équipement dans...`, 
  "backOffice.appliance.selectAccountToTransferTo.transferTo.title": `Transférer les équipements dans...`,
  "backOffice.appliance.selectAccountToTransferTo.account": `Sélectionner le compte où transférer les équipements`,
  "backOffice.appliance.selectAccountToTransferTo.location": `Sélectionner un emplacement du compte`,
  "backOffice.appliance.selectAccountToTransferTo.location.placeholder": `Sélectionner un emplacement (facultatif)`,
  "backOffice.appliance.selectAccountToTransferTo.message": `Vous êtes sur le point de transférer {count, plural,
    =0 {0 équipement}
    one {un équipement}
    other {{count} équipements}
  } {list}.`,
  "backOffice.appliance.selectAccountToTransferTo.config": `Sélectionner une configuration pour vos équipements`,
  "backOffice.appliance.selectAccountToTransferTo.config.subtitle": `Un seul équipement peut être routeur maître dans cet emplacement`,
  "backOffice.appliance.selectAccountToTransferTo.config.subtitle.networkHasRouter": `Cet emplacement possède déjà un routeur maître.`,

  "backOffice.appliance.addTo.message": `Vous êtes sur le point d'ajouter {count, plural,
    =0 {0 équipement}
    one {un équipement}
    other {{count} équipements}
  } {list}.`,
  "backOffice.appliance.addTo.location": `Emplacement dans lequel ajouter le(s) appareil(s)`,

  get "backOffice.appliance.switchEndpoint"() {return this["generic.actions.SWITCH_ENVIRONMENT"]},
  "backOffice.appliance.switchEndpoint.message": `La modification de ce paramètre causera l'indisponibilité permanente {count, plural,
    =0 {de l'équipement}
    one {de l'équipement}
    other {des équipements}
  } {list}.`,
  "backOffice.appliance.switchEndpoint.endpoint.label": `Adresse API du serveur SentinelC de destination`,
  "backOffice.appliance.switchEndpoint.description.label": `Décrire la raison pour le transfert vers un autre environnement`,
  "backOffice.appliance.switchEndpoint.content.subtitle.actualEndpoint": `Cette adresse d'API est valide pour cet environnement.`, 
  "backOffice.appliance.switchEndpoint.content.subtitle.externalEndpoint": `Cette adresse d'API est externe à cet environnement. Les équipements seront <strong>indisponibles</strong> dans votre inventaire.`,
  
  "backOffice.appliance.disk.name": `Nom du disque`,
  "backOffice.appliance.disk.editSuccess": `La modification du disque a été planifiée avec succès!`,
  "backOffice.appliance.disk.formatSubmitted": `Une tâche démarrera sous peu pour appliquer les modifications à votre disque.{linebreak}Cette opération peut prendre plusieurs minutes dépendamment de la taille de votre disque. Le statut d'avancement sera affiché sur le composant des disques où vous pourrez vérifier l'état de la tâche liée à cette action.`,
  "backOffice.appliance.disk.formatDesc": `Cette action va effectuer un formatage rapide et assigner le disque aux services.`,
  "backOffice.appliance.disk.reinitDesc": `Cette action va effectuer un formatage rapide et retirer l'usage pour les services.`,
  "backOffice.appliance.disk.eraseDesc": `Cette action va effectuer un effacement complet et sécurisé du disque.`,
  "backOffice.appliance.disk.eraseOption": `Effectuer un effacement complet et sécurisé du disque.`,
  "backOffice.appliance.disk.eraseSubmitted": `Une tâche démarrera sous peu pour effacer votre disque.{linebreak}Cette opération peut prendre plusieurs minutes dépendamment de la taille de votre disque.`,
  
  "backOffice.appliance.disk.edit": `Éditer le disque`,
  "backOffice.appliance.disk.configure": `Configurer le disque de services`,
  "backOffice.appliance.disk.change": `Changer le disque de services`,
  "backOffice.appliance.disk.edit.chooseAPrep": `Vous devez choisir une préparation pour le disque`,
  "backOffice.appliance.disk.title.configure": `Choisir un disque`,
  "backOffice.appliance.disk.title.change": `Choisir un disque`,
  "backOffice.appliance.disk.select": `Choisir un disque`,
  "backOffice.appliance.disk.selectUsage": `Choisir l'usage`,
  "backOffice.appliance.disk.selectUsage.NONE": `Aucun - Le disque sera ignoré et non utilisé par le système.`,
  "backOffice.appliance.disk.selectUsage.CONTAINER_STORAGE": `Service - Utilise le disque comme stockage primaire pour les services embarqués.`,
  "backOffice.appliance.disk.selectUsage.STORAGE": `Stockage - Permet d'accéder au contenu du disque dans un but de stockage ou sauvegarde.`,
  "backOffice.appliance.disk.diskPreparation": `Préparation`,
  "backOffice.appliance.disk.diskPreparation.AS_IS": `Tel quel`,
  "backOffice.appliance.disk.diskPreparation.AS_IS.tooltip": `Option indisponible. Le disque n'a aucun formatage précédent.`,
  "backOffice.appliance.disk.diskPreparation.ERASE": `Effacer`,
  "backOffice.appliance.disk.diskPreparation.ERASE_AND_FORMAT": `Effacer et formater`,
  "backOffice.appliance.disk.diskPreparation.FORMAT": `Formater`,
  "backOffice.appliance.disk.edit.actualDisk.clear": `Le disque de service actuel <strong>{disk}</strong> est indisponible. Il sera retiré de la liste.`,
  "backOffice.appliance.disk.edit.actualDisk.reconfiguration": `Le disque de service actuel <strong>{disk}</strong> sera reconfiguré à l'usage <strong>Stockage</strong> <small><em>(Aucune donnée ne sera supprimée)</em></small>.`,

  "backOffice.appliance.disks.view": `Voir les disques`,
  "backOffice.appliance.disks.currentState": `État courant`,
  "backOffice.appliance.disks.serviceDisk.change": `Le disque utilisé pour les services est actuellement : <strong>{disk}</strong>.`,
  "backOffice.appliance.disks.serviceDisk.change.btn": `Modifier le disque`,
  "backOffice.appliance.disks.serviceDisk.change.btn.disabled": `Aucun autre disque disponible`,
  "backOffice.appliance.disks.serviceDisk.notSelected": `Aucun disque utilisé pour les services.`,
  "backOffice.appliance.disks.serviceDisk.notSelected.btn": `Sélectionner un disque`,
  "backOffice.appliance.disks.serviceDisk.none": `Aucun disque disponible pour les services.`,
  "backOffice.appliance.disks.size": `Taille`,
  "backOffice.appliance.disks.selectDiskUsage": `Choisir l'usage du disque`,
  "backOffice.appliance.disks.diskUsage.assignToService": `Assigner aux Services`,
  "backOffice.appliance.disks.selectFormatType": `Choisir le type de formatage`,
  "backOffice.appliance.disks.lastKnownData": `Dernières valeurs connues`,
  "backOffice.appliance.disks.freeSpace": `Espace libre`,
  "backOffice.appliance.disks.usedSpace": `Espace occupé`,
  "backOffice.appliance.disks.usedSpace.filterBy": `Par espace occupé`,
  "backOffice.appliance.disks.usage": `Usage`,
  "backOffice.appliance.disks.onPartitionOnly": `Sur la partition de données seulement`,
  "backOffice.appliance.disks.modal.clear.content": `Ce disque n'est plus disponible, il va être supprimé de la liste.`,
  "backOffice.appliance.disks.modal.clear.hasServices": `Les services installés seront supprimés.`,
  "backOffice.appliance.disks.message.update.clear": `Disque retiré avec succès`,
  "backOffice.appliance.disks.message.update.format": `Formatage lancé avec succès`,
  "backOffice.appliance.disks.message.update.unmount": `Disque démonté avec succès`,

  "backOffice.appliance.info": `Configuration et statut global`,
  "backOffice.appliance.info.connectionStatus": `Statut de connexion`,
  "backOffice.appliance.info.setup": `Configuration équipement`,
  "backOffice.appliance.info.heartBeat": `Pouls`,
  "backOffice.appliance.info.vpnStatus": `Statut VPN`,
  "backOffice.appliance.info.vpnStatus.UP": `La connexion VPN automatisée est fonctionnelle.`,
  "backOffice.appliance.info.vpnStatus.DOWN": `L'équipement ne récupère pas sa configuration via le mécanisme de pouls régulier. La connexion VPN automatisée ne peut être établie.`,
  "backOffice.appliance.info.vpnStatus.CONFIGURING": `En attente de l'établissement automatique de la connexion VPN.`,
  "backOffice.appliance.info.vpnStatus.DISABLED": `Le statut d'inventaire de l'équipement ne requiert pas une connexion VPN.`,
  "backOffice.appliance.info.internetConnectivity": `Connectivité internet`,
  "backOffice.appliance.info.temperature": `Température`,
  "backOffice.appliance.info.memory": `Mémoire`,
  "backOffice.appliance.info.storage": `Stockage`,
  "backOffice.appliance.info.metrics": `Capteurs équipement`,
  "backOffice.appliance.info.noMetrics": `Métriques indisponibles`,
  "backOffice.appliance.info.currentRelease": `Version actuelle`,
  "backOffice.appliance.info.targetRelease": `Version cible`,
  "backOffice.appliance.info.localIpAddress": `Adresse IP locale`,
  "backOffice.appliance.info.publicIpAddress": `Adresse IP publique`,
  "backOffice.appliance.info.internetGateway": `Passerelle internet`,
  get "backOffice.appliance.info.apiEndpoint"() {return this["generic.apiEndpoint"]},

  "backOffice.appliance.wifis.broadcastingAppliances": `Équipement(s) émetteurs`,
  "backOffice.appliance.wifis.noLocation": `Vous devez ajouter l'équipement à un emplacement afin de pouvoir ajouter un réseau Wi-Fi.`,
  "backOffice.appliance.radios.activation": `Activation`,
  "backOffice.appliance.radios.activeConnections": `Nombre de connexions actives sur ce Wi-Fi pour l'équipement en cours, {linebreak}entre parenthèses le nombre de connexions actives sur tous les équipements émetteurs en ligne.`,
  "backOffice.appliance.radios.actualChannel": `Canal actuel`,
  "backOffice.appliance.radios.actualChannel.error": `Canal non appliqué - Veuillez patienter ou sélectionner manuellement un autre canal`,
  "backOffice.appliance.radios.actualTx": `Puissance Tx actuelle`,
  "backOffice.appliance.radios.radioInactivated": `Radio inactivée par l'équipement car n'émettant aucun Wi-Fi.`,
  "backOffice.appliance.radios.channelSettings": `Paramètre canal`,
  "backOffice.appliance.radios.bands": `Bandes`,
  "backOffice.appliance.radios.bandType": `Type de bande`,
  "backOffice.appliance.radios.bandType.SINGLE_BAND": `Simple bande`,
  "backOffice.appliance.radios.bandType.DUAL_BAND": `Double bande`,
  "backOffice.appliance.radios.maxWifi": `Max. Wi-Fi`,
  "backOffice.appliance.radios.driver": `Pilote`,
  "backOffice.appliance.radios.interface": `Interface`,
  "backOffice.appliance.radios.maxSsids": `Maximum Wi-Fi`,
  "backOffice.appliance.radios.maxVTHBandwidth": `Largeur de bande max.`,
  "backOffice.appliance.radios.noRadioEnabled": `Cet appareil ne possède pas de radio activée pour émettre.`,
  "backOffice.appliance.radios.offlineDevice": `L'équipement est hors-ligne, les dernières données connues sont affichées mais pourraient ne pas être exactes.`,
  "backOffice.appliance.radios.psk": `PSK`,
  "backOffice.appliance.radios.ssid": `SSID`,
  "backOffice.appliance.radios.station": `Station`,
  "backOffice.appliance.radios.stationMode": `Mode station`,
  "backOffice.appliance.radios.stationMode.popup": `Cette option peut seulement être activée par une configuration locale de l'équipement. Lorsqu'activé cela signifie que la radio agit comme un client Wi-Fi qui se connecte à un SSID spécifique.`,
  "backOffice.appliance.radios.txPower": `Puissance Tx`,
  "backOffice.appliance.radios.txPowerSetting": `Paramètre puissance Tx`,
  "backOffice.appliance.radios.txPowerSetting.auto": `Afin d’être conforme à la règlementation, cette valeur pourrait être modifiée suite à la confirmation.`,
  "backOffice.appliance.radios.update.success": `Les radios ont été mises à jour avec succès.`,
  "backOffice.appliance.ports.doc.customPorts": `Comment utiliser un port custom`,
  "backOffice.appliance.ports.doc.Qos": `Comprendre le QoS`,
  "backOffice.appliance.ports.configurePorts": `Modifier les ports`,
  "backOffice.appliance.ports.configurePorts.default": `Choisir une configuration`,
  "backOffice.appliance.ports.editCustomConfig": `Saisir ou modifier la configuration de ce port`,
  "backOffice.appliance.ports.editPortsConfig": `Modifier la configuration des ports`,
  "backOffice.appliance.ports.configureInternetPort": `Configurer le QoS`,
  "backOffice.appliance.ports.presetConfig": `Préconfigurations`,
  "backOffice.appliance.ports.presetConfig.desc": `En cliquant sur "Appliquer" vous écraserez la configuration actuelle du port.`,
  "backOffice.appliance.ports.portType": `Type/Zone`,
  "backOffice.appliance.ports.applianceDevice": `Équipements/Appareils`,
  get "backOffice.appliance.ports.ipAddress"() {return this["location.ip"]},
  "backOffice.appliance.macAddress": `Adresse MAC équipement`,
  "backOffice.appliance.ports.macAddress": `Adresse MAC du port`,
  "backOffice.appliance.ports.maxSpeed.subtitle": `Selon votre contrat internet`,
  "backOffice.appliance.ports.guarantedDownloadSpeed": `Vitesse garantie de téléchargement`,
  "backOffice.appliance.ports.guarantedUploadSpeed": `Vitesse garantie de téléversement`,
  "backOffice.appliance.ports.maxDownloadSpeed": `Vitesse max. de téléchargement`,
  "backOffice.appliance.ports.maximumDownloadSpeed": `Vitesse de <strong>téléchargement</strong> maximum`,
  "backOffice.appliance.ports.maximumDownloadSpeed.subtitle": `La vitesse de téléchargement maximum autorisée est de <strong>{value} Mbps</strong>`,
  "backOffice.appliance.ports.maximumSpeed.info": `Mettre la valeur à 0 causera une bande passante extrêment lente (proche de zéro). Un champ vide désactivera le maximum.`,
  "backOffice.appliance.ports.guaranteedDownloadSpeed": `Vitesse de <strong>téléchargement</strong> garantie`,
  "backOffice.appliance.ports.guaranteedDownloadSpeed.subtitle": `Vitesse de téléchargement minimum garantie/réservée`,
  "backOffice.appliance.ports.maxUploadSpeed": `Vitesse max. de téléversement`,
  "backOffice.appliance.ports.maximumUploadSpeed": `Vitesse de <strong>téléversement</strong> maximum`,
  "backOffice.appliance.ports.maximumUploadSpeed.subtitle": `La vitesse de téléversement maximum autorisée est de <strong>{value} Mbps</strong>`,
  "backOffice.appliance.ports.guaranteedUploadSpeed": `Vitesse de <strong>téléversement</strong> garantie`,
  "backOffice.appliance.ports.guaranteedUploadSpeed.subtitle": `Vitesse de téléversement minimum garantie/réservée`,
  "backOffice.appliance.ports.guaranteedSpeed.info": `Mettre le champ à 0 ou vide désactivera le QoS en téléversement ou téléchargement pour cette zone/vlan`,
  "backOffice.appliance.ports.totalGuaranteed": `Vitesses totales garanties`,
  "backOffice.appliance.ports.qosEnabled": `Le QoS est actuellement activé.`,
  "backOffice.appliance.ports.qosDisabled": `Le QoS est actuellement désactivé.`,
  "backOffice.appliance.ports.qosDesc": `Si activé, défini les seuils de vitesses (maximum et minimum).`,
  "backOffice.appliance.ports.manageQoS": `Gestion du QoS`,
  "backOffice.appliance.ports.manageQoS.deactivated": `Le QoS requiert un équipement en mode routeur.`,
  "backOffice.appliance.ports.internetConfiguration": `Configuration du port internet`,
  "backOffice.appliance.ports.internetConfiguration.message": `En configurant au moins un de ces deux paramètres, vous activerez la configuration QoS des zones/vlans.{linebreak}Les données saisies doivent correspondre exactement avec les vitesses de bande passante de votre fournisseur internet, autrement cela pourrait affecter négativement les performances de votre réseau.{linebreak}Veuillez noter que réduire ces vitesses pourrait affecter le QoS de chacune des zones/vlans sur lesquelles il s'applique.`,
  "backOffice.appliance.ports.qosConfiguration": `Configuration du QoS pour les Zones/Vlans`,
  "backOffice.appliance.ports.qosConfiguration.subtitle": `Vous pouvez également configurer les vitesses minimales et garanties de téléchargement et téléversement dépendamment des paramètres configurés ci-dessus (port internet).`,
  "backOffice.appliance.ports.qosActivation": `Activation du QoS (Quality of Service)`,
  "backOffice.appliance.ports.qosActivation.subtitle": `Pour activer le QoS, vous devez sélectionner Activé dans les actions ci-dessous. Autrement, les valeurs saisies seront sauvegardées mais non appliquées. La validation des données saisies sera effective seulement si le QoS est activé.`,

  "backOffice.appliance.radios.editParams": `Modifier les paramètres des radios`,
  "backOffice.appliance.radios.modal.title": `Modifier les paramètres des radios - {device}`,

  "backOffice.appliances.history": `Historique des équipements`,
  "backOffice.appliances.bulk.message.update.REBOOT": `{count, plural, =0 {Aucun équipement redémarré} one {Un équipement redémarré} other{{count} équipements redémarrés}}.`,
  "backOffice.appliances.bulk.message.update.WIFI_SURVEY": `{count, plural, =0 {Aucun surveillance de Wi-Fi} one {Surveillance de Wi-Fi démarrée sur un équipement} other{Surveillance de Wi-Fi démarrée sur {count} équipements}}.`,
  "backOffice.appliances.bulk.message.update.FACTORY_RESET": `{count, plural, =0 {Aucun équipement remis à l'état d'usine} one {Un équipement remis à l'état d'usine} other{{count} équipementst remis à l'état d'usine}}.`,
  
  "backOffice.applianceHistory.title": `Historique des changements des équipements`,
  "backOffice.applianceInventoryHistory.title": `Historique des changements des équipements en inventaire`,

  
  "backOffice.inventory": `Inventaire`,
  "backOffice.inventory.title": `Gérer l'inventaire des équipements`, 
  "backOffice.inventory.statuses": `Comprendre les statuts de l'inventaire`,
  "backOffice.inventory.actions": `Comprendre les actions possibles sur les équipements de l'inventaire`,
  "backOffice.inventory.message": `Vous devez d'abord configurer les adresses IP légitimes depuis lesquelles un équipement peut se connecter à votre serveur et apparaître comme Nouveau dans l'inventaire. Cliquez sur Paramètres de l'inventaire pour ce faire.`,
  "backOffice.inventory.settings": `Paramètres de l'inventaire`,
  "backOffice.inventory.settings.update.success": `Paramètres modifiés avec succès.`,
  "backOffice.inventory.enterIPAddresses": `Saisir une ou plusieurs adresses IP autorisées à se connecter à l'inventaire (une par ligne)`,
  "backOffice.inventory.enterIPAddresses.subtitle": `Les nouveaux SentinelC ou les équipements retournés devront se connecter au travers de ces adresses pour la première fois afin d'être ajouté au compte Inventaire.`,
  "backOffice.inventory.transferTo.message": `Vous êtes sur le point de modifier l'emplacement {count, plural,
    =0 {de 0 équipement}
    one {d'un équipement}
    other {de {count} équipements}
  } {list}.`,
  "backOffice.inventory.transferTo.location": `Sélectionner un emplacement dans le compte INVENTORY`,

  "backOffice.incident.DEVICE_CONFIGURATION": `Configuration de l'équipement`,
  "backOffice.incident.DEVICE_NAME": `Nom de l'équipement`,
  "backOffice.incident.DEVICE_LOCATION": `Emplacement de l'équipement`,
  "backOffice.incident.DEVICE_OPERATING_MODE": `Mode d'opération de l'équipement`,
  "backOffice.incident.DEVICE_FIRMWARE_VERSION": `Version du micrologiciel`,
  "backOffice.incident.DEVICE_FIRMWARE_UP_TO_DATE": `Micrologiciel à jour`,
  "backOffice.incident.DISK_NAME": `Nom du disque`,
  "backOffice.incident.DISK_USAGE": `Usage du disque`,
  "backOffice.incident.DISK_SPACE_LEVEL": `Niveau de remplissage du disque`,
  "backOffice.incident.DISK_USED_PCT": `Pourcentage du disque utilisé`,
  "backOffice.incident.PODMAN_MESSAGE": `Message du système de services`,
  "backOffice.incident.POD_NAME": `Nom du service`,
  "backOffice.incident.POD_STATUS": `Statut du service`,
  "backOffice.incident.PODMAN_STATUS": `Statut du système de services`,
  "backOffice.incident.PODMAN_PODS": `Services affectés`,
  "backOffice.incident.ROGUE_SSID": `Wi-Fi malveillant`,
  "backOffice.incident.ROGUE_SSID_DETECTED_BY_RULE": `Wi-Fi malveillant détecté par la règle`,
  "backOffice.incident.ROGUE_BSSID": `Adresse MAC du routeur malveillant`,
  "backOffice.incident.ROGUE_FIRST_SEEN_DATE": `Wi-Fi malveillant vu la première fois le`,
  "backOffice.incident.ROGUE_FREQUENCY": `Fréquence du Wi-Fi malveillant`,
  "backOffice.incident.ROGUE_SIGNAL": `Puissance du Wi-Fi malveillant`,
  "backOffice.incident.ROGUE_BAND": `Fréquence`,
  "backOffice.incident.ROGUE_REPORTED_BY": `Reporté par`,
  "backOffice.incident.NETWORK_LOCATION": `Emplacement réseau`,
  "backOffice.incident.NO_RADIO_SSID_DEVICES": `Équipement qui émettent le réseau Wi-Fi`,
  "backOffice.incident.NO_RADIO_SSID": `Réseau Wi-Fi sans radio`,
  "backOffice.incident.HOST_MAC_ADDRESS": `Adresse MAC de l'appareil`,
  "backOffice.incident.HOST_HOSTNAME": `Nom de l'appareil`,
  "backOffice.incident.SESSION_PORT_LABEL": `No. de port`,
  "backOffice.incident.BAD_CONNECTION_REASON": `Raison de la mauvaise connexion`,
  "backOffice.incident.BAD_CONNECTION_REASON.HOST_NOT_ALLOWED_IN_TRUNK_PORT": `Appareil non autorisé sur un port restreint`,
  "backOffice.incident.BAD_CONNECTION_REASON.DEVICE_NOT_ALLOWED_IN_ACCESS_PORT": `Équipement non autorisé sur un port d'accès`,
  "backOffice.incident.BAD_CONNECTION_REASON.LOOP_INTO_SELF": `Boucle de l'équipement sur lui-même`,
  "backOffice.incident.BAD_CONNECTION_DEVICE_NAME": `Sur l'équipement`,
  "backOffice.incident.BAD_CONNECTION_DEVICE_LOCATION": `À l'emplacement`,
  "backOffice.incident.BAD_CONNECTION_DEVICE_PORT_LABEL": `Sur le port`,
  "backOffice.incident.FAILED_UPDATE_TARGET_VERSION": `Version en échec`,
  "backOffice.incident.VLAN_NAME": `Nom de la zone`,
  "backOffice.incident.VLAN_SUBNET": `Sous-réseau de la zone`,
  "backOffice.incident.WAN_IP_ADDRESS": `Adresse IP de la connexion WAN`,
  "backOffice.incident.FIRST_EVENT": `Première occurrence`,
  "backOffice.incident.LAST_EVENT": `Dernière occurrence`,
  "backOffice.incident.EVENT_COUNT": `Nombre d'événements`,
  "backOffice.incident.EVENT_COUNT.subtitle": `Ce nombre peut grossir tant que cet incident est ouvert`,

  "backOffice.home.downtimeStatus": `Statuts des temps d'arrêt`,
  "backOffice.home.downtime": `Temps d'arrêt`,
  "backOffice.home.downtime.average": `Temps d'arrêt moyen:`,
  "backOffice.home.recentIncidents": `Plus récents incidents`,
  "backOffice.home.openIncidents": `Incidents ouverts`,
  "backOffice.home.recentOpenIncidents": `Incidents récents ouverts`,
  "backOffice.appliance.availability": `Disponibilité des équipements`,

  "backOffice.project.addAppliance": `Ajouter un équipement`,
  "backOffice.project.selectAppliance": `Sélectionnez un équipement afin de voir les détails.`,
  "backOffice.project.specialRemarks": `Remarques particulières`,
  "backOffice.project.close": `Clore le projet`,
  "backOffice.project.projectId": `ID projet`,
  "backOffice.project.projectTitle": `Titre du projet`,
  "backOffice.project.portscan.empty": `Ajoutez votre premier scan de ports`,
  "backOffice.project.portscan.useRouter": `Pour ajouter un scan de port, utilisez le routeur de cet équipement`,
  "backOffice.project.packetCapture.empty": `Ajoutez votre première capture de ports`,
  "backOffice.project.modify": `Modifier le projet`,
  "backOffice.project.modify.message": `Aide à organiser la surveillance des activités sur des équipements spécifiques.`,

  "backOffice.projects.add": `Créer un projet`,
  "backOffice.projects.add.submit": `Créer le projet`,
  "backOffice.projects.close": `Clore des projets`,
  "backOffice.projects.close.info": `
    Vous êtes sur le point de clore {count, plural, =0 {aucun projet} one {un projet} other {{count} projets}}
  `,
  "backOffice.projects.close.message": `Cette action implique que les projets ne seront plus mis à jour et que les services déployés sur les équipements des projets seront désinstallés.{linebreak}
    Cependant, vous serez en mesure de voir les projets et d'avoir accès aux fichiers sauvegardés/générés par certaines activités.`,
  "backOffice.project.message.addProject": `Projet créé avec succès.`,
  "backOffice.project.message.updateProject": `Projet mis à jour avec succès.`,
  "backOffice.project.message.addDevice": `Équipement ajouté avec succès au projet.`,
  "backOffice.project.message.updateDevice": `Équipement mis à jour avec succès.`,
  "backOffice.project.message.removeDevice": `Équipement retiré avec succès.`,

  "backOffice.volume.deletion": `Supprimer le volume`,
  "backOffice.volume.deletion.text": `Êtes-vous sur de vouloir supprimer le volume?`,
  "backOffice.volumes.title": `Volumes sur disques`,
  "backOffice.volumes.view": `Voir les volumes`,
  "backOffice.volumes.title.deletion.title": `Supprimer les volumes`,  
  "backOffice.volumes.deletion.message": `Seuls les volumes dont le service a été désinstallé peuvent être supprimés`,
  "backOffice.volumes.diskPartition": `Disque/partition`,
  "backOffice.volumes.volumeId": `ID du volume`,
  "backOffice.volumes.volumeName": `Nom du volume`,
  "backOffice.volumes.files": `Fichiers du volume`,
  "backOffice.volumes.files.remove": `Êtes-vous sûr de vouloir supprimer cet élément ?`,
  "backOffice.volumes.files.remove.timeout": `Pas de réponse du serveur. Veuillez rafraîchir plus tard la liste des fichiers pour vérifier si l'opération a fonctionné.`,
  "backOffice.volumes.files.error": `Accès aux fichiers non disponible.`,  
  "backOffice.volumes.files.error.INCOMPATIBLE": `Version du micrologiciel incompatible avec cette fonctionnalité.`,  
  "backOffice.volumes.files.error.CONNECTION_FAILED": `Erreur réseau: Accès aux fichiers impossible.`,  
  "backOffice.volumes.files.error.TIMEOUT": `Erreur réseau: Accès aux fichiers impossible.`,  
  "backOffice.volumes.files.error.INTERNAL": `Erreur interne: Accès aux fichiers impossible.`,  
  "backOffice.volumes.files.offlineDevice": `Impossible de vérifier l'état des fichiers, l'équipement est hors-ligne.`,
  "backOffice.volumes.files.desc": `Explorez, téléchargez ou supprimez les fichiers et dossiers du volume.`,
  "backOffice.volumes.files.desc.warning": `Attention le service pourrait ne plus fonctionner si vous supprimez certains fichiers.`,
  "backOffice.volumes.viewFiles": `Voir les fichiers`,
  "backOffice.volumes.viewFiles.empty": `Aucun fichier`,
  "backOffice.volumes.filters.status": `Statut du disque`,
  "backOffice.volumes.filters.status.filterBy": `Par statut du disque`,
  "backOffice.volumes.filters.type": `Type de disque`,
  "backOffice.volumes.filters.type.filterBy": `Par type de disque`,
  "backOffice.volumes.filters.volumeStatus": `Statut du volume`,
  "backOffice.volumes.filters.volumeStatus.filterBy": `Par statut du volume`,
  "backOffice.volumes.filters.volumeStatus.IN_USE": `En utilisation`,
  "backOffice.volumes.filters.volumeStatus.DELETE_PENDING": `Suppression en cours`,
  "backOffice.volumes.filters.volumeStatus.ORPHAN": `Orphelin`,

  "backOffice.volumes.popup.orphan": `Le service a été <strong>désinstallé</strong> sans nettoyer le volume.`,

  "backOffice.incident": `Incident`,
  "backOffice.incidents.title": `Incidents`,
  "backOffice.incidents.user.notPrivileged": `Cet utilisateur ne possède plus les permissions support.`,
  "backOffice.incidents.bulk.message.update.OPEN": `{count, plural, =0 {Aucun incident modifié} one { Un incident réouvert} other{{count} incidents réouverts}}.`,
  "backOffice.incidents.bulk.message.update.CLOSE": `{count, plural, =0 {Aucun incident modifié} one { Un incident fermé} other{{count} incidents fermés}}.`,
  "backOffice.incidents.bulk.message.update.RESOLVE": `{count, plural, =0 {Aucun incident modifié} one { Un incident résolu} other{{count} incidents résolus}}.`,

  "backOffice.account.filtered.noAccount": `Aucun compte ne correspond aux critères sélectionnés.`,
  "backOffice.account.create": `Créer un compte`,
  "backOffice.account.create.success": `Le compte <strong>{name}</strong> a été créé avec succès`, 
  "backOffice.account.update.success": `Le compte <strong>{name}</strong> a été modifié`,
  "backOffice.account.delete.success": `Le compte <strong>{name}</strong> a été supprimé avec succès`, 
  "backOffice.account.details": `Détails du compte`,
  "backOffice.account.edit": `Éditer un compte`,
  "backOffice.account.locationsMap": `Carte des emplacements`, 
  "backOffice.account.totalIncident": `Nb. incidents`,
  "backOffice.account.openIncidents": `Incident(s) ouvert(s)`,
  "backOffice.account.adminUsers": `Administrateur(s) de ce compte ({count})`,
  "backOffice.account.viewTasks": `{count, plural, =0 {Aucune tâche} one {Voir la tâche} other {Voir les tâches}}`,
  "backOffice.account.remove.modal.title": `Supprimer le compte {account}`,
  "backOffice.account.remove.modal.message": `Vous êtes sur le point de supprimer définitivement le compte <strong>{account}</strong>. {linebreak}Sachez que tous les réseaux, configurations et données liées à ce compte seront supprimées définitivement.`,
  "backOffice.account.remove.modal.appliances": `Action requise!`,
  "backOffice.account.remove.modal.appliances.confirmationStep": `Saisir le nom du compte ci-contre pour confirmer sa suppression: <strong>{name}</strong>`,
  "backOffice.account.remove.modal.appliances.confirmationStep.title": `Étape de confirmation`,
  
  "backOffice.account.remove.modal.appliances.RETURN_TO_OPERATOR": `Retour au fournisseur`,
  "backOffice.account.remove.modal.appliances.RETURN_TO_OPERATOR.explanation": `{count, plural, one {Votre équipement sera retourné à l'inventaire SentinelC et vous ne le posséderez plus.} other {Tous vos équipements seront retournés à l'inventaire SentinelC et vous ne les posséderez plus.}}`,
  "backOffice.account.remove.modal.appliances.KEEP": `Conserver`,
  "backOffice.account.remove.modal.appliances.KEEP.explanation": `Cette action permet de <strong>conserver la propriété de {count, plural, one {votre équipement} other {vos équipements}}</strong>.{linebreak} Cependant, cela implique que le compte et {count, plural, one {l'équipement associé} other {les équipements associés}} seront conservés, mais les autres données seront supprimées.`,
  "backOffice.account.remove.modal.appliances.TRANSFER_TO": `Transférer vers...`,
  "backOffice.account.remove.modal.appliances.TRANSFER_TO.explanation": `Cette action vous permet de transferer {count, plural, one {votre équipement} other {tous vos équipements}} dans un autre de vos comptes.`,

  "backOffice.accounts.listOf": `Liste des comptes`,
  "backOffice.locations.listOf": `Liste des emplacements`,
  "backOffice.devices.listOf": `Liste des équipements`,
  "backOffice.devices.btn": `Voir la liste des équipements`,
  "backOffice.devices.txt.up": `{count, plural, =0 {Aucun équipement en ligne} one {Un équipement en ligne} other {{count} équipements en ligne}}`,
  "backOffice.devices.txt.down": `{count, plural, =0 {Aucun équipement hors ligne} one {Un équipement hors ligne} other {{count} équipements hors ligne}}`,
  "backOffice.devices.txt.notIsUse": `{count, plural, =0 {Aucun équipement libre} one {Un équipement libre} other {{count} équipements libres}}`,
  "backOffice.stats.selectOfStatus": `Veuillez sélectionner un filtre en cliquant sur l'une des tranches de graphiques ci-dessus.`,

  "backOffice.hosts.noHost": `Aucun appareil`,
  "backOffice.hosts.filtered.noHost": `Aucun appareil ne correspond aux critères sélectionnés.`,
  "backOffice.hosts.details.viewAllConnectionsFrom": `Voir toutes les connexions de cet appareil sur`,
  "backOffice.hosts.details.viewAllConnections": `{count, plural, =0 {Aucune connexion} one {Voir la connexion} other{Voir les {count} connexions}}`,

  "backOffice.hosts.startTime": `Heure de début de connexion`,
  "backOffice.hosts.endTime": `Heure de fin de connexion`,
  "backOffice.hosts.triggers": `Créer une tâche pour cet appareil`,
  "backOffice.hosts.openPorts.neverScanned": `Jamais scanné`,
  "backOffice.hosts.openPorts.notResponding": `Ne répond pas`,
  "backOffice.hosts.openPorts.notResponding.explain": `N'a pas répondu au dernier scan de ports.`,
  "backOffice.hosts.openPorts.notResponding.explain.setlast": `Les résultats sont ceux du dernier scan valide.`,
  "backOffice.hosts.openPorts.notResponding.view": `Voir le scan`,

  "backOffice.host.deviceDetails": `Détails de l'appareil`,
  "backOffice.host.deviceDetails.service": `À noter qu'il s'agit de l'interface réseau virtuelle d'un service embarqué. Pour plus de détails`,
  "backOffice.host.deviceDetails.service.view": `voir le service`,
  "backOffice.host.deviceDetails.uninstalledService": `À noter qu'il s'agit de l'interface réseau virtuelle d'un service embarqué désinstallé`,
  "backOffice.host.lastConnectionDetails": `Détails de la dernière connexion`,
  "backOffice.host.actualStatus": `Statut actuel`,
  "backOffice.host.connections": `Connexions depuis le début`,
  "backOffice.host.edit": `Editer l'appareil`,
  "backOffice.host.surveillance": `Activités de surveillance de cet appareil`,
  "backOffice.host.incidents": `Incidents de cet appareil`,
  "backOffice.host.ipAddress": `Adresse IP de la cible`,
  "backOffice.host.ipAddress.by": `Par adresse IP`,

  "backOffice.host.connections.title": `Dernière connexion par emplacement`,
  "backOffice.host.otherconnections.title": `Connexions dans d'autres comptes`,
  "backOffice.host.otherconnections.none": `Appareil jamais vu dans un autre compte.`,

  "backOffice.host.permissions.title": `Permissions sur les zones/vlans restreints`,
  "backOffice.host.permissions.none": `Aucune permission`,
  "backOffice.host.permissions.view": `{count, plural, =0 {Aucune permission} one {Voir la permission} other {Voir les {count} permissions}}`,
  
  "backOffice.host.lastPortscan.title": `Scan de ports le plus récent par emplacement`,
  "backOffice.host.lastPortscan.viewAll": `Voir tous les scans pour cet emplacement`,
  "backOffice.host.lastOsDetection.title": `Détection de l'OS le plus récent par emplacement`,
  "backOffice.host.lastOsDetection.viewAll": `Voir toutes les détections pour cet emplacement`,
  "backOffice.host.dns.title": `Top des requêtes DNS`,
  "backOffice.host.activities": `Activités de surveillance sur cet appareil`,
  "backOffice.host.permissions": `Permissions`,

  "backOffice.users.title": `Gestion des administrateurs`,
  "backOffice.user.automaticInventoryAccess": `Automatiquement ajouté lorsque la permission Privilégiée est donnée.`,
  "backOffice.users.privileged": `Privilégié`,
  "backOffice.users.systemAdministrator": `Administrateur système`,
  "backOffice.users.portalPermission": `Permissions`,
  "backOffice.users.portalPermission.ENABLED": `Privilégié`,
  "backOffice.users.portalPermission.DISABLED": `Administrateur`,
  "backOffice.users.portalPermission.AUDITOR": `Auditeur`,
  "backOffice.users.portalPermission.NORMAL": `Administrateur`,
  "backOffice.users.portalPermission.PRIVILEGED": `Privilégié`,
  "backOffice.users.portalPermission.filterBy": `Par permission support`,
  "backOffice.users.localAdminOnAccount": `Admin local des comptes`,
  "backOffice.users.addNew": `Créer un nouvel administrateur`,
  "backOffice.users.addToAccount": `Ajouter un profil d'accès`,
  "backOffice.users.addToAccount.disabled": `Vous devez remplir les champs nom et prénom avant de pouvoir ajouter des accès à ce profil.`,
  "backOffice.users.editAccount": `Modifier le profil de compte`,
  "backOffice.users.btn.updateProfile": `Mettre à jour le profil`,
  "backOffice.users.btn.createProfile": `Créer le profil`,
  "backOffice.users.remove.pending": `Supprimer`,
  "backOffice.users.remove.enabled": `Révoquer`,
  "backOffice.users.remove.updated": `Mis à jour`,
  "backOffice.users.inactive": `Utilisateur inactif`,
  "backOffice.users.regular": `Mode domestique`,
  "backOffice.users.regular.desc": `Parfait pour une installation de maison.`,
  "backOffice.users.expert": `Mode administrateur réseaux`,
  "backOffice.users.expert.desc": `Idéal pour la gestion de moyens et grands réseaux.`,
  "backOffice.users.expandAccess": `Voir les accès`,
  "backOffice.users.collapseAccess": `Réduire les accès`,
  "backOffice.users.expandNotifs": `Modifier les paramètres de notification`,
  "backOffice.users.collapseNotifs": `Réduire les paramètres de notification`,
  "backOffice.users.descNotifs": `Cette section se met à jour automatiquement lorsque vous modifiez un paramètre.`,

  "backOffice.user": `Administrateur`,
  "backOffice.user.creation.title": `Créer un nouveau profil administrateur`,
  "backOffice.user.inactive": `Cet utilisateur est inactif. Pour être en mesure d'éditer ce profil, vous devez d'abord lui ajouter des profils d'accès.  Après coup, vous serez en mesure d'éditer le bloc Information utilisateur.`,
  "backOffice.user.edition.title": `Modifier le profil administrateur`,
  "backOffice.user.privileged": `Privilégié`,
  "backOffice.user.userInfo": `Info utilisateur`,
  "backOffice.user.revokeAccess.disabled": `L'utilisateur doit posséder l'accès à au moins un compte.`,
  "backOffice.user.message.addUser": `Administrateur créé avec succès`,
  "backOffice.user.message.updateUser": `Administrateur mis à jour avec succès`,
  "backOffice.user.message.revokeUser": `L'administrateur {email} a été supprimé avec succès`,
  "backOffice.user.revokeAllAccess": `Supprimer tous les accès`,
  "backOffice.user.authorizedAccessToAccounts": `Accès autorisé aux comptes`,
  "backOffice.user.created.modal.title": `Confirmation de la création du profil utilisateur`,
  "backOffice.user.created.modal.content.existing": `Veuillez noter que ce profil utilisateur a été réactivé.{linebreak}Il ne recevra donc pas de courriel l'invitant à se connecter, vous devrez donc l'avertir.`,
  "backOffice.user.created.modal.content.message": `Le profil utilisateur pour {email} a été créé avec succès.`,
  "backOffice.user.reenabled.modal.title": `Confirmation de la réactivation du profil utilisateur`,
  "backOffice.user.reenabled.modal.content.message": `Le profil utilisateur pour {email} a été réactivé avec succès.`,
  "backOffice.user.revoke.modal.title": `Supprimer tous les accès`,
  "backOffice.user.revoke.modal.content": `Êtes-vous sûr de vouloir supprimer tous les accès de {email}?`,
  "backOffice.user.access.accountProfile": `Profil du compte`,
  "backOffice.user.access.accountProfile.explanation": `
    Ce système permet d'avoir des informations de profil spécifiques au compte auquel vous êtes connecté.
    {linebreak}
    Ce qui signifie que pour une même adresse courriel il est possible d'avoir différents détails de contact dépendamment du compte.
  `,
  "backOffice.user.access.accessStatus": `Statut de l'accès`,
  "backOffice.user.access.accountContactName": `Nom défini sur le compte`,

  "backOffice.userHistory.title": `Historique des comptes d'administrateur`,
  "backOffice.userHistory.viewAll": `Voir tout l'historique des changements`,

  // /BACKOFFICE ======================================
  
  "homepage.pref.title": `Bienvenue sur SentinelC`,
  "homepage.pref.desc": `Veuillez choisir votre préférence de page d’accueil. Vous pourrez la remodifier via l'option disponible dans le menu utilisateur.`,


  // DASHBOARD =======================================

  // Home page
  "homepage.about.title": `A propos`,
  "homepage.content": `Vous avez cliqué sur <strong>Démarrer l’installation</strong> qui vous permet de créer un <strong>nouveau compte</strong> et d’y ajouter un premier équipement SentinelC.`,
  "homepage.message.connected": `Vous êtes connecté à l’application SentinelC!`,
  "homepage.connected.logoutOut": `<p>Si vous souhaitez vraiment créer un nouveau compte, déconnectez-vous d’abord et cliquez de nouveau sur le bouton <strong>Démarrer l’installation</strong>.</p>`,
  "homepage.connected.gotoDashboard": `<p>Si vous souhaitez plutôt ajouter un équipement SentinelC dans un nouvel emplacement d’un de vos comptes existants, vous devrez d’abord sélectionner le compte de votre choix et ensuite cliquer sur <strong>Ajouter une SentinelC</strong> à partir du menu du compte.</p>`,
  "homepage.notConnected.connect": `
    <p>Si vous possédez déjà un ou plusieurs comptes SentinelC, vous  voulez peut-être ajouter un nouvel équipement SentinelC à un nouvel emplacement d’un de vos comptes.</p>
    <p>Pour ce faire, vous devrez d’abord vous connectez et ensuite sélectionner le compte de votre choix. À partir du menu du compte sélectionné, cliquez sur <strong>Ajouter une SentinelC</strong>.</p>
  `,
  "homepage.notConnected.gotoOnboarding": `<p>Si vous ne possédez pas de compte SentinelC ou que vous souhaitez vraiment créer un nouveau compte, cliquer sur Continuer.</p>`,
  "homepage.bloc1.title": `Réseaux Wi-Fi`,

  // Organization
  "dashboard.addOrganization": `Ajouter un compte SentinelC`,
  "dashboard.createOrganization": `Créer un compte SentinelC`,
  "dashboard.selectOrganization.content": `Sélectionnez un compte SentinelC dans la liste ci-dessous`,
  "dashboard.selectOrganization.warning": `Votre profil ne dispose d'aucun droit d'accès.`,
  "dashboard.selectOrganization.nocontent": `<p>Si vous pensez que cette situation n'est pas normale, veuillez contacter l'administrateur de votre compte SentinelC.</p>`,

  // Device
  "dashboard.device.state.active": `{count, plural,
    =0 {Aucun équipement}
    one {Votre équipement est <strong>actif</strong>}
    other {Tous les équipements sont <strong>actifs</strong>}
  }`,
  "dashboard.ssid.state.pause": `{count, plural,
    =0 {Aucun Wi-Fi}
   one {1 Wi-Fi est <strong>indisponible</strong>}
   other {{count} Wi-Fi sont <strong>indisponibles</strong>}
  }`,
  "dashboard.ssid.state.active": `{count, plural,
    =0 {Aucun réseau}
    one {Votre réseau est <strong>actif</strong>}
    other {Tous vos réseaux sont <strong>actifs</strong>}
  }`,

  // Info
  "dashboard.info.notifications.generic": `{count, plural, =0 {Aucune nouvelle notification} one {1 nouvelle notification} other {{count} nouvelles notifications}}`,
  "dashboard.info.notifications.title": `Notifications`,
  "dashboard.info.notifications.none":  `Vous n'avez pas de notification`,
  "dashboard.info.notifications.none.period":  `Vous n'avez pas de notification pour cette période`,
  "dashboard.info.notifications.none.contentUnread":  `Consulter l'onglet <strong>Archivées</strong> ci-dessus pour voir les notifications passées.`,
  "dashboard.info.notifications.atLocation": `à l'emplacement <strong>{location}</strong>`,
  "dashboard.info.notifications.isOnActualSettings": `Cette notification est désactivée dans vos paramètres.`,
  "dashboard.info.notifications.recentsExplanation": `Cette section montre uniquement les notifications récentes basées sur vos paramètres. Vous pouvez consulter l'onglet <strong>Archivées</strong> pour afficher toutes les notifications passées.`,
  "dashboard.info.notifications.achived.checkbox.uncomplete": `Montrer toutes les notifications générées sans tenir compte de mes `,
  "dashboard.info.notifications.unread": `Non-lue`,
  "dashboard.info.notifications.settings.content": `
    <p>
      En activant une catégorie, vous serez automatiquement notifié dans l'application SentinelC (icone "cloche" dans la barre de menu).{linebreak}
      Vous pouvez également être notifié par email suivant vos préférences.
    </p>
  `,
  "dashboard.info.notifications.settings.actionRequired": `Action requise`,
  "dashboard.info.notifications.settings.actionRequired.subtitle": `Ces événements sont activés par défaut.`,
  get "dashboard.info.notifications.settings.information"() {return this["generic.information"]},

  "dashboard.info.notifications.settings.type.NEW_HOST_RESTRICTED_VLAN.title": `Permission requise pour un appareil`,
  "dashboard.info.notifications.settings.type.NEW_HOST_RESTRICTED_VLAN.sublabel": `Lorsqu'un nouvel appareil se connecte à une zone restreinte`,

  "dashboard.info.notifications.settings.type.DOWNTIME.title": `Équipement SentinelC hors-ligne`,
  "dashboard.info.notifications.settings.type.DOWNTIME.sublabel": `Inclus une notification lors du retour en ligne`,

  "dashboard.info.notifications.settings.type.FW_UPDATE.title": `Mise à jour équipement`,
  "dashboard.info.notifications.settings.type.FW_UPDATE.sublabel": `Inclus une notification à la fin de celle-ci`,

  "dashboard.info.notifications.settings.type.NEW_HOST_PRIVATE_VLAN.title": `Nouvelle connexion dans une zone privée`,
  "dashboard.info.notifications.settings.type.NEW_HOST_PRIVATE_VLAN.sublabel": `Lorsqu'un un appareil se connecte pour la première fois dans une zone privée`,

  "dashboard.info.notifications.settings.type.NEW_HOST_OTHER_VLAN.title": `Nouvelle connexion dans une zone publique`,
  "dashboard.info.notifications.settings.type.NEW_HOST_OTHER_VLAN.sublabel": `Lorsqu'un un appareil se connecte pour la première fois dans une zone publique`,

  "dashboard.info.notifications.settings.type.ROGUE_SSID.title": `Détection de Wi-Fi «Rogue»`,
  "dashboard.info.notifications.settings.type.ROGUE_SSID.sublabel": `Lorsqu'un Wi-Fi portant le même nom que l'un de vos Wi-Fis est détecté`,

  "dashboard.info.notifications.settings.type.BAD_WIRED_CONNECTION.title": `Mauvais branchement filaire`,
  "dashboard.info.notifications.settings.type.BAD_WIRED_CONNECTION.sublabel": `Lorsqu'un appareil ou équipement n'est pas connecté dans un port adéquat`,

  "dashboard.info.notifications.settings.type.SCHEDULED_IMPORTANT.title": `Avis importants`,
  "dashboard.info.notifications.settings.type.SCHEDULED_IMPORTANT.sublabel": `Alertes et messages importants de l'équipe de support`,

  "dashboard.info.notifications.settings.type.SCHEDULED_GENERAL.title": `Nouvelles et informations générales`,
  "dashboard.info.notifications.settings.type.SCHEDULED_GENERAL.sublabel": `Avis d'intérêt général de la part de l'équipe SentinelC`,

  "dashboard.info.notifications.settings.type.HOST_AUTO_BLOCKED.title": `Blocage automatique d'un appareil`,
  "dashboard.info.notifications.settings.type.HOST_AUTO_BLOCKED.sublabel": `Lorsqu'un appareil est bloqué automatiquement dû à un mauvais comportement`,

  "dashboard.info.notifications.settings.type.CONFIGURATION_PROBLEM.title": `Problèmes de configuration`,
  "dashboard.info.notifications.settings.type.CONFIGURATION_PROBLEM.sublabel": `Lorsque des problèmes/erreurs de configuration sont détectés`,
  "dashboard.info.update.remove": `IGNORER`,
  "dashboard.info.organization.profile": `Compte créé le {date} par {name}`,

  // 404 Page
  "dashboard.404.title": `404 - Page non trouvée`,
  "dashboard.404.content": `
    <p>
      La page demandée n'existe pas ou vous n'avez pas accès à cette page.
    </p>
    <p>
      Cliquez sur le bouton de retour en haut de page pour revenir à {count, plural, =0 {l'accueil} =1 {votre compte SentinelC} other { vos comptes SentinelC}}.
    </p>
  `,

  // /DASHBOARD ======================================


  // PORTAL ==========================================

  "portal.header": `Réseau Wi-Fi Portail public`,
  "portal.footer": `Réseau sécurisé par SentinelC`,

  //Home
  "portal.home.guest.title": `Invité`,
  "portal.home.request.error": `Une erreur s'est produite, veuillez réessayer`,
  "portal.home.guest.content": `
    <p>Notre réseau Invité est sécurisé, mais pour assurer la sécurité de vos données une clé personnelle est requise pour se connecter.</p>
    <p>Avec cette clé limitée dans le temps vous serez en mesure de vous connecter à notre réseau Invité sécurisé.</p>
  `,
  "portal.home.employees.content": `
    <p>Si vous êtes un employé enregistré, cliquez sur le lien suivant:
      {linebreak}<a href={href}>Se connecter</a></p>
  `,

  // Get a key
  "portal.getAKey.title": `Obtenir une clé temporaire`,
  "portal.getAKey.content": `
    <p>Veuillez entrer un email ou un numéro de téléphone valide, nous vous enverrons un jeton pour vérifier votre identité.</p>
  `,
  "portal.getAKey.tokenform.content": `
    <p>Entrez le jeton que vous avez reçu pour générer votre clé privée.</p>
    <p><em>Cette clé sera disponible uniquement pour cet appareil, vous devez le faire pour chacun de ceux que vous souhaitez connecter.</em></p>
  `,

  // Key
  "portal.key.title": `Clé privée`,
  "portal.key.yourPersonnalKeyIs": `Votre clé personnelle est`,
  "portal.key.content": `
    <p>Copiez la clé et reconnectez-vous au réseau Wi-Fi public. {linebreak}
    Utilisez la clé lorsque votre appareil vous demandera.</p>
  `,
  "portal.key.infos": `
    <p>Cette clé sera disponible uniquement pour cet appareil, vous devez le faire pour chacun de ceux que vous souhaitez connecter.</p>
    <p>Il est également valable pour une connexion de X heures. Après ce délai, demander une nouvelle clé sur ce réseau Portail.</p>
  `,

  // /PORTAL =========================================


}
